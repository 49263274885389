.d-pagination>li>a {
  background-color: rgb(221, 221, 221);
  border: none;
}

.d-pagination>li.active>a {
  background-color: #28ABE3;
}

.full-width {
  width: 100%;
}

.text-overflow-ellipsis {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  width: 100%;
  max-width: 100%;
  max-height: 100px;
  line-height: 1.3;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.ellipsis-text3 {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: inline-block;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 32px;
  font-size: 13px;
  width: 198px;
}

.table-column-5 {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: inline-block;
  -webkit-line-clamp: 5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 32px;
  font-size: 13px;
  width: 250px;
}
.table-column-2 {
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  display: block;
  display: inline-block;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  max-height: 32px;
  font-size: 13px;
  width: 100px;
}

.mat-mdc-header-cell, .mat-mdc-cell {
  padding: 5px !important;
  font-size: 13px;
  line-height: 1.2;
  height: 60px;
}

mat-header-cell, mat-cell {
  padding: 5px !important;
}

.delete-button mat-icon{
  display: flex;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
}

tr:hover .delete-button mat-icon, mat-row:hover .delete-button mat-icon{
  opacity: 1;
  transition: opacity 0.5s;
  color: #00aeef;
}

tr:hover mat-cell:hover .delete-button mat-icon, mat-row:hover mat-cell:hover .delete-button mat-icon{
  opacity: 1;
  transition: opacity 0.5s;
  color: black;
}

.table-overview--no-element-message {
  position: relative;
  top: 25px;
}

.overview-footer {
  min-height: 65px;
}

a.td-link {
  width: 100%;
  display: block;
}

a.td-link-disabled {
  width: 100%;
  display: block;
  color: #3c3c3c;
}

.overview-margin-bottom-space {
  margin-bottom: 20px;
}
