/*notifications*/

.d-notifications-time
  float: right
  font-size: 11px
  color: grey
  margin-right: 5px

.d-notifications > ul
  margin-top: 30px
  max-height: 200px
  overflow-y: auto
  overflow-x: hidden
  margin-left: -35px
