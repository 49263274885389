/*  Changelog section  */
.changelog-container {
  margin-top: 20px;
}

.changelog-row {
  background-color: #ffffff;
  margin-bottom: 5px;
  padding: 10px;
  min-height: 30px;
  display: flex;
  align-items: center;
  text-align: left;
}

.text-lightgrey {
  color: #7b7b7b;
  text-shadow: 1px 1px white;
}

.activity-selection {
  border: 0;
  color: #000;
  font-weight: bold;
}
