app-logs
  .mat-expansion-panel
    min-width: 100% !important
    border-radius: 0px !important

  .mat-expansion-panel-header-description, .mat-expansion-panel-header-title
    display: block

  .mat-expansion-panel-header
    font-family: inherit !important
    font-size: inherit !important

  .popper-dialog
    min-width: 300px

app-logs-overview
  .dropzone
    text-align: center
    padding-top: 10px
    color: #999

  .dropzone.dz-drag-hover
    background: aliceblue
    border: dashed 1px

  .dropzone i
    font-size: 24px
    color: #28ABE3

  .dz-wrapper
    width: 100% !important

  .dz-message
    min-height: 37px !important
    max-height: 37px !important
    border-radius: 3px
    margin: 0px !important
    border: 1px dashed #aaa !important

  .button-container
    margin-top: 0px !important

  .mat-mdc-form-field
    font-family: inherit !important

  .mat-mdc-form-field-underline
    display: none !important

  .mat-mdc-form-field-appearance-fill
    .mat-mdc-form-field-flex
      background-color: #fff !important
      border-radius: 15px !important
      padding-top: 3px !important

  .mat-mdc-form-field-appearance-fill
    .mat-mdc-form-field-infix
      padding-bottom: 3px !important

  app-generic-multiselect-checkbox
    .mat-mdc-form-field-infix
      border-radius: 3px
      border: solid #ffffff
      background-color: #ffffff
      color: #000000
      padding: 5px

.log-edit
  .mat-mdc-tab-label
    min-width: 30px !important
    padding: 0px !important
    min-height: 68px !important

  .mat-mdc-tab-label-content
    display: block !important

  .ql-editor
    min-height: calc(100vh - 235px) !important
    max-height: calc(100vh - 235px) !important
    overflow-y: scroll

app-complaints-detail
  .log-relations-div
    width: 100% !important
    display: block !important
  .log-edit-buttons-div
    width: 100% !important

app-employee-edit
  .log-relations-div
    width: 100% !important
    display: block !important
  .log-edit-buttons-div
    width: 100% !important

app-email-log-dropzone
  .dz-text
    position: absolute !important
    top: 50% !important
