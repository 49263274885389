.customized-dialog-container
  .mat-mdc-dialog-container
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1)
    --mdc-dialog-container-shape: 20px
    .mdc-dialog__surface
      padding: 24px
      border: 1px solid #DDDDDD

.mat-mdc-dialog-container
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1)
    --mdc-dialog-container-shape: 20px !important
    .mdc-dialog__surface
      padding: 24px
      border: 1px solid #DDDDDD

.mdc-dialog__actions
    margin-bottom: -24px !important


.mat-mdc-dialog-container
  .mdc-dialog__title
    padding: 0 0px 9px

.widget-panel
    background-color: #fff
    border-radius: 20px
    border: 1px solid #DDDDDD
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1)
    padding-top: 15px
    padding-bottom: 15px
    margin-bottom: 15px

.unicon-icon
  font-size: 20px !important
  text-align: center
  margin-bottom: 7px
  vertical-align: middle
  padding-right: 5px

.main_menu_side
  .unicon-icon
    font-size: 25px !important
    text-align: center
    margin-bottom: 0px
    vertical-align: middle
    padding-right: 5px

.contactmoment-notes-container
  .tox-tinymce
    border-radius: 25px !important
    border: 1px solid #D6D6D6 !important
    padding: 10px
    width: 100% !important

.highcharts-axis-labels text
  font-family: 'work_sans_regular' !important
.highcharts-axis-title
  font-family: 'work_sans_regular' !important
  font-size: 12px !important
.highcharts-plot-line-label
  font-size: 12px !important
.highcharts-label
  text
    font-family: 'work_sans_regular' !important
    font-size: 12px !important
.highcharts-root
  text
    font-family: 'work_sans_regular' !important
    font-size: 12px !important

.highcharts-legend-item
  text
    font-family: 'work_sans_semibold' !important
    font-size: 12px !important

.mat-datepicker-content
  border-radius: 25px !important
.mat-calendar-header
  .mat-calendar-controls
    .mat-button:hover
      border-radius: 30px !important

.mat-mdc-autocomplete-panel, .mat-mdc-select-panel
  border-radius: 20px !important
  min-width: 200px !important
  padding: 0 !important

.mat-pseudo-checkbox
  opacity: 0

.mat-mdc-option .mdc-list-item__primary-text
  font-size: 14px !important
  letter-spacing: normal !important

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text
  color: #000 !important

.mat-form-field-appearance-outline .mat-form-field-outline
  border-radius: 20px
  color: #2800D2
.mat-mdc-option
  font-family: 'work_sans_regular' !important

.mdc-label
  font-weight: 400
  font-family: 'work_sans_regular'
  font-size: 15px
  padding-top: 5px

.mat-mdc-menu-panel
  border-radius: 25px !important

button, input, select, textarea
  font-family: 'work_sans_regular' !important

input.mat-mdc-chip-input
  margin-left: 0px !important

.mdc-evolution-chip__text-label
  display: flex
  align-items: center
  font-size: 14px !important
  font-family: 'work_sans_regular' !important

.mdc-form-field > label
  padding-left: 0px !important
  padding-bottom: 5px !important

.mat-mdc-option
  padding-left: 12px !important

.mdc-list-item--with-leading-checkbox .mdc-list-item__start
  margin-left: 0px !important
  margin-right: 0px !important

.mdc-list-item__primary-text
  font-size: 13px !important
  font-family: 'work_sans_regular' !important

.text-capitalize
  text-transform: capitalize !important

.mat-step-label
  font-size: 14px !important
  font-family: 'work_sans_regular'

.mat-mdc-menu-content
  .mat-mdc-option .mdc-list-item__primary-text
    display: flex
    margin-top: 5px !important

@media only screen and (max-width: 991px)
  .unicon-icon
    padding-right: 0px
