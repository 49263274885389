

.table-header-rotated td{
  
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  vertical-align: middle;
  text-align: center;
}

.table-header-rotated th.rotate-45{
  height: 120px;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  position: relative;
  vertical-align: top;
  padding: 0px;
  font-size: 12px;
  line-height: 1.0;
    font-weight: 800;
}

.table-header-rotated th.rotate-45 > div{
  position: relative;
  top: 0px;
  left: 60px; /* 80 * tan(45) / 2 = 40 where 80 is the height on the cell and 45 is the transform angle*/
  height: 100%;
  -ms-transform:skew(-45deg,0deg);
  -moz-transform:skew(-45deg,0deg);
  -webkit-transform:skew(-45deg,0deg);
  -o-transform:skew(-45deg,0deg);
  transform:skew(-45deg,0deg);
  overflow: hidden;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-top: 0px solid #dddddd;
}

.table-header-rotated th.rotate-45 span {
  -ms-transform:skew(45deg,0deg) rotate(315deg);
  -moz-transform:skew(45deg,0deg) rotate(315deg);
  -webkit-transform:skew(45deg,0deg) rotate(315deg);
  -o-transform:skew(45deg,0deg) rotate(315deg);
  transform:skew(45deg,0deg) rotate(315deg);
  position: absolute;
  bottom: 30px; /* 40 cos(45) = 28 with an additional 2px margin*/
  left: -15px; /*Because it looked good, but there is probably a mathematical link here as well*/
  display: inline-block;
  width: 85px; /* 80 / cos(45) - 40 cos (45) = 85 where 80 is the height of the cell, 40 the width of the cell and 45 the transform angle*/
  text-align: left;
}

/*fixed left column*/
.DTFC_Cloned{
  background: white;
  border-right: 1px solid #f7f7f7;
  overflow:hidden;
}
.DTFC_Cloned .sorting_asc{
  display: none;
}

/*hide sort icon on fixed row*/
.DTFC_Cloned  > thead > tr > th[class*="sort"]:before{
  content: "" !important;
  display: none;
}
table.dataTable{
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.DTFC_LeftBodyWrapper{
  
/*  -webkit-box-shadow: 6px 0px 5px -3px rgba(0,0,0,0.19);
  -moz-box-shadow: 6px 0px 5px -3px rgba(0,0,0,0.19);
  box-shadow: 6px 0px 5px -3px rgba(0,0,0,0.19);*/
}

/* Ensure that the demo table scrolls */
#interestsMatrix_wrapper th,
#interestsMatrix_wrappertd {
    white-space: nowrap;
}

#interestsMatrix_wrapper.dataTables_wrapper {
    width: 100%;
    margin: 0 auto;
}
#interestsMatrix_wrapper .row-header{
  text-align: left !important;
  vertical-align: middle;  
  word-wrap: break-word;
  line-height: 40px !important;
  overflow:hidden;
}
#interestsMatrix_wrapper .row-cell{
  width: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  word-wrap: break-word;
}
/*buttons*/
.btn.btn-matrix{
    height: 40px;
    width: 40px;
    -webkit-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.3);
    -moz-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.3);
    box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.3);
}

.d-btn-white{
  background: transparent;
  color: white;
  -webkit-box-shadow: 0px;
  -moz-box-shadow: 0px;
  box-shadow: 0px;

}
.d-btn-yellow{
  background: #ffeead;
  color: white;
}
.d-btn-orange{
  background: #ffcc5c;
  color: white;
}
.d-btn-red{
  background: #ff6f69;
  color: white;
}
.d-matrix-dropdown{
  min-width: 0px;
}
.d-matrix-dropdown li>a{
  padding: 10px;
}

.d-yellow{
  color: #ffeead;
}
.d-orange{
  color: #ffcc5c;
}
.d-red{
  color: #ff6f69;
}
.d-green{
  color: #1ABB9C;
}
