.snackbar-background-red
  --mdc-snackbar-container-color: $gd-response-red !important
  --mdc-snackbar-supporting-text-color: #fff !important
  --mat-snack-bar-button-color: #fff
  background-color: $gd-response-red

.snackbar-background-green
  --mdc-snackbar-container-color: $gd-response-green !important
  --mdc-snackbar-supporting-text-color: #fff !important
  --mat-snack-bar-button-color: #fff
  background-color: $gd-response-green

.mat-simple-snackbar-action
  color: rgba(255, 255, 255, 0.7)


.mat-mdc-snack-bar-container .mdc-snackbar__label
  font-size: 13px !important
  font-family: work_sans_regular !important
  font-weight: 400 !important
  line-height: 1.471 !important
