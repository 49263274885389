app-interests-matrix, app-tab-issue-detail-general, app-tab-stakeholder-detail-houding
  .popper-dialog
    background-color: #EFF0F6
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    -moz-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    z-index: 1500
    border-radius: 25px

  .issue-interest
    div
      display: inline-block
      width: 30px
      height: 30px
      margin: 3px
      cursor: pointer
      border: 1px solid #dadada
      line-height: 30px
      position: relative
    .color-x
      background-color: #EFF0F6
    .color-0
      background-color: #EFF0F6
    .color-1
      background-color: #FFFFFF
    .color-2
      background-color: #F7F050
    .color-3
      background-color: #FF9F95
    .color-4
      background-color: #FF7669

  .popper
    .issue-interest
      padding-left: 8px
      cursor: pointer !important
    .issue-interest:hover
      background-color: #C8C9CF

  .matrix-popper-dialog
    background-color: #FFFFFF
    -webkit-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    -moz-box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    box-shadow: 0px 0px 8px 3px rgba(0,0,0,0.3)
    z-index: 1500
    border-radius: 25px
    position: absolute
    border: none
    padding: 0px !important
  .ngxp__container > .ngxp__arrow
    opacity: 0
  

  .issue-interest-new
    div
      display: inline-block
      width: 30px
      height: 30px
      border-radius: 50%
      cursor: pointer
      padding-right: 10px
      line-height: 30px
      position: relative
    .color-x
      border: 1px solid #dadada
      background-color: #ffffff
    .colorInterest-x
      border: 1px solid #dadada
      background-color: #ffffff
    .colorInterest-null
      border: 1px solid #dadada
      background-color: #ffffff
    .colorInterest-0
      background-color: #e0e0e0
    .colorInterest-1
      background-color: #98dbea
    .colorInterest-2
      background-color: #58b5db
    .colorInterest-3
      background-color: #178ecc
    .colorInterest-4
      background-color: #004f90
    .colorTension-x
      border: 1px solid #dadada
      background-color: #ffffff
    .colorTension--1
      border: 1px solid #dadada
      background-color: #ffffff
    .colorTension-null
      border: 1px solid #dadada
      background-color: #ffffff
    .colorTension-0
      background-color: #92e8A9
    .colorTension-1
      background-color: #ffdad3
    .colorTension-2
      background-color: #ffb8aa
    .colorTension-3
      background-color: #fe8f80
    .colorTension-4
      background-color: #ff4331
    .colorInfluence-x
      border: 1px solid #dadada
      background-color: #ffffff
    .colorInfluence-null
      border: 1px solid #dadada
      background-color: #ffffff
    .colorInfluence-0
      background-color: #e6e6fa
    .colorInfluence-1
      background-color: #c8a2c8
    .colorInfluence-2
      background-color: #9370db
    .colorInfluence-3
      background-color: #6a0dad
    .colorInfluence-4
      background-color: #4b0082
    .colorTrust-x
      border: 1px solid #dadada
      background-color: #ffffff
    .colorTrust-null
      border: 1px solid #dadada
      background-color: #ffffff
    .colorTrust-0
      background-color: #feecd3
    .colorTrust-1
      background-color: #ffdda6
    .colorTrust-2
      background-color: #ffce7a
    .colorTrust-3
      background-color: #ffbf4d
    .colorTrust-4
      background-color: #fdaf00


app-tab-stakeholder-detail-houding
  .stakeholder-matrix-suggestion-line
    opacity: 0.7
.d-block
  .mat-list-item-content
    display: block !important
owl-date-time-container
  width: 300px !important
owl-date-time-calendar
  height: auto !important
owl-date-time-container
  .owl-dt-calendar-cell-content
    font-size: 12px !important
  .owl-dt-calendar-control-content
    font-size: 12px !important
  .owl-dt-control-button-content
    text-transform: capitalize !important
    font-size: 14px !important
owl-date-time-month-view
  table
    thead
      tr
        .owl-dt-weekday
          font-size: 12px !important
          color: #000000
          font-weight: bold
owl-date-time-month-view
  table
    thead
      tbody
        tr
          td
            .owl-dt-calendar-cell-selected
              background-color: #35a9f3 !important

owl-date-time-container
  .owl-dt-container-inner
    .owl-dt-container-info
      font-size: 14px
      color: #000000
    .owl-dt-container-buttons
      font-size: 14px
      color: #000000

.owl-dt-calendar-table
  .owl-dt-calendar-cell-selected
    background-color: #35a9f3 !important

owl-date-time-timer
  height: auto

.transparent_bg
  mat-dialog-container
    background: transparent !important

.custome-tiny-style
  .tox-tinymce
    border-bottom: 1px solid #949494 !important
    border-top: none !important
    border-right: none !important
    border-left: none !important
    height: 80px !important
    border-radius: 0px !important
  .tox-edit-area::before
    border-bottom: 1px solid #949494 !important
    border-top: none !important
    border-right: none !important
    border-left: none !important
.layers-accordion
  .mat-expansion-panel-header
    padding: 0px 13px !important
    .mat-content
      overflow: visible !important
  .mat-expansion-panel-body
    padding: 0 10px 5px !important
  mat-panel-title
    font-size: 13px !important
    font-family: 'work_sans_regular' !important
    font-weight: 700
  .mat-expansion-panel-header-description
    display: block !important
.items-layers
  .mat-expansion-panel-header-title
    margin-right: 10px !important
.small-spinner
  width: 50px
.mat-menu-content
  .mat-accent .mat-slider-track-fill
    background-color: #0189f3 !important
  .mat-slider-thumb-label
    background-color: #0189f3 !important
  .mat-slider-thumb
    background-color: #0189f3 !important
  .mat-slider-disabled .mat-slider-thumb
    background-color: #bfbfbf !important

.load-openlayers
  mat-spinner
    width: 40px !important
    height: 40px !important
    svg
      width: 40px !important
      height: 40px !important
      circle
        width: 40px !important
        height: 40px !important
.custome-padding
  .mat-mdc-form-field-infix
    padding-top: 6px !important
    padding-bottom: 10px !important
  .mat-mdc-form-field-label
      top: 20px !important
.custome-padding-select
  .mat-mdc-form-field-flex
    padding-bottom: 2px !important
.radio-button-custome
  .mat-radio-label
    display: flex !important
    justify-content: center !important
    margin-left: 5px !important
.spinner-animation
    animation: spin 2s linear infinite
app-batch-overview-setting
  .mat-mdc-form-field-wrapper
    .mat-mdc-form-field-flex
      .mat-mdc-form-field-infix
        width: 130px
