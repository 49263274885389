.gd-resident-content
  width: 100%
  display: flex
  justify-content: space-between
  flex-direction: column

  mat-button,
  .gd-button-migrate
    height: 10vh
    width: 100%
    left: 0
    right: 0
    color: rgba(255, 255, 255, 1)
    background-color: rgba(26, 115, 232, 1)
    letter-spacing: 2px
    text-transform: uppercase

.gd-modal
  width: 40vw !important
  font-family: 'work_sans_regular' !important

  h1
    font-family: 'work_sans_light' !important
    font-weight: bold

  .primary-color
    color: #3F51B5 !important

  .background-primary-color
    color: #3F51B5 !important

  .mat-dialog-content
    min-height: 323px !important

  button
    background-color: #1c7cd5
    color: #ffffff !important
    padding: 5px
    border-radius: 5px

.gd-notification
  min-height: 50px
  width: auto
  display: flex
  justify-content: space-between
  align-items: center
  flex-direction: column

.gd-migrate-form
  min-height: 20px
  width: auto
  display: flex
  justify-content: space-between
  flex-direction: column
  padding: 5px
  border: 1px solid lighten(rgba(65, 74, 76, 1), 5%)
  border-radius: 3px
.resident-phone
  button
    margin-bottom: 0px !important
