/* Coolors Exported Palette - coolors.co*/
/* Color RGBA */
$color1: rgba(30, 159, 242, 1)
$color2: rgba(193, 41, 46, 1)
$color3: rgba(255, 255, 255, 1)
/* Text
$color4: rgba(65, 74, 76, 1)
/* Background
$color5: rgba(2, 1, 0, 1)

/* Fonts
$gd-text-primary: ''
$gd-text-secondary: ''
$gd-text-tertiary: ''
$gd-text-header: 'work_sans_regular'
$gd-text-subheader: ''

$color4-lg: lighten($color4, 5%)
$color4-ln: lighten($color4, 10%)
$color4-ls: lighten($color4, 15%)
$color4-dk: darken($color4, 5%)
$color4-dn: darken($color4, 10%)
$color4-ds: darken($color4, 15%)
/** default white*/
$color5: rgba(255, 255, 255, 1)

$color5-dk: darken($color5, 5%)
$color5-dn: darken($color5, 10%)
$color5-ds: darken($color5, 15%)

$gd-response-green: rgba(38, 185, 154, 0.8)
$gd-response-red: rgba(231, 76, 60, 0.8)
