/* override datatables bootstrap icon */
table.dataTable thead .sorting:after
  opacity: 0.2 !important
  content: "\25bc \25b2" !important
  margin-bottom: 3px

table.dataTable thead th.sorting:empty:after
  content: none !important

table.dataTable thead .sorting_asc:after
  content: "\25b2"
  margin-bottom: 3px

table.dataTable thead .sorting_desc:after
  content: "\25bc"
  margin-bottom: 3px

table.dataTable thead>tr>th.sorting_asc, table.dataTable thead>tr>th.sorting_desc, table.dataTable thead>tr>th.sorting, table.dataTable thead>tr>td.sorting_asc, table.dataTable thead>tr>td.sorting_desc, table.dataTable thead>tr>td.sorting
  padding-right: 45px !important

/* Only show delete button on hover, or on mobile */
/* Set height of tr to same when icon is shown */
tr.delete-on-hover-tr
  height: 58px

/* Set width of table cell to same when icon is shown */
.delete-on-hover-th
  min-width: 70px

.delete-on-hover
  visibility: hidden

/* No hover effect on mobile, so just show delete button on none active list item */
@media only screen and (max-width : 991px)
  .delete-on-hover-tr .delete-on-hover
    visibility: visible

/* Show icon on hovered list items */
.delete-on-hover-tr:hover .delete-on-hover
  visibility: visible

app-dashboard-my-issues
  tr.mat-mdc-row:last-child
    td.mat-mdc-cell
      border-bottom: 0px
app-analysis
  td.mat-mdc-cell
    border-right-width: 1px !important
    border-right-style: solid
    border-right-color: rgba(0,0,0,0.12)
  th.mat-mdc-header-cell
    border-right-width: 1px !important
    border-right-style: solid
    border-right-color: rgba(0,0,0,0.12)
  td.mat-mdc-cell.action, th.mat-mdc-header-cell.action
    border-left-width: 1px !important
    border-left-style: solid
    border-left-color: rgba(0,0,0,0.12)

app-dashboard-my-actions
  tr:last-child
    td
      border-bottom: 0px

.top-btns-overview
  margin-top: -59px
  font-family: work_sans_600
.overview-tabs
  max-width: 420px
  z-index: 1
  margin-bottom: -41px
  .mat-mdc-tab-link
    font-family: work_sans_regular
    padding: 0px
    font-size: 13px
    min-width: 115px
    letter-spacing: normal
.list-view-paginator
  margin-top: -12px
  .mat-mdc-paginator-range-label
    margin: 0px
.search-overview
  margin-left: 40%
  width: 24%
  display: flex
  height: 40px
  float: left
  padding: 0
  .search-holder
    width: 100%
    border: solid 1px #E0E1E3
    border-radius: 15px
    margin-top: -2px
    mat-icon
      color: gray
      cursor: pointer
    .uil-icon
      font-size: 19px
.filter-btn-mobile
  display: none !important
  height: 35px
  padding: 0

.search-create-item-overlay
  position: absolute
  top: 100%
  left: 0
  width: 100%
  background: #fff
  border: 1px solid #E0E1E3
  border-radius: 15px
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1)
  padding: 8px
  text-align: center
  font-size: 14px
  z-index: 999
  width: 24%
  margin-left: 40%
  cursor: pointer

@media only screen and (max-width : 900px)
  .top-btns-overview
    margin-top: -100px
    font-family: work_sans_600
  .overview-tabs
    width: auto
    margin-bottom: 0px
  .search-overview
    width: 80%
    float: none !important
    margin-left: 0
    margin-top: 5px
  .filter-btn-mobile
    display: block !important
.app-filter-sort.seting-left
  .filter-dropdown
    left: -40px
.app-filter-sort.logssetting
  .filter-dropdown
    left: -20px
.app-filter-sort.Klanteis
  .filter-dropdown
    left: -45px
.app-filter-sort.typeset
  .filter-dropdown
    left: -200px
.app-filter-sort.right
  .filter-dropdown
    left: -80px
.app-filter-sort.analysis
  .filter-dropdown
    left: -230px
    top: 55px
.app-filter-sort.permit
  .filter-dropdown
    left: -52px
    top: 55px
.th.active
  color: #F7F050
mat-icon.relation-count
  font-size: 16px
  height: 25px
  width: 25px
  color: gray
  border: solid 2px
  padding-top: 2px
  padding-left: 3px
  border-radius: 50%
  .mat-badge-content
    background-color: #FF7669

.text-center
  text-align: center !important
.th-border
  width: 100%
  height: 1px
  display: block
  top: 60px
  background: lightgray
  position: sticky


// Override overflow so overview tables can have rounded corners
.mat-mdc-table
  overflow: hidden
  border-radius: 4px
.th-border
  height: 0px !important
.pagination-top-border
  border-top-width: 1px
  border-top-style: solid
  border-top-color: #e0e0e0
  position: sticky !important
  left: 0
.overview-panel
  tbody
    & > tr
      &:last-child
        & > td
          border-bottom-width: 0px !important
          border-bottom-style: solid

.mdc-data-table__row:last-child .mat-mdc-cell
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important
