app-all-search-select, .app-all-search-select
  position: relative
  display: inline-block

  .fast-right-spinner
    -webkit-animation: glyphicon-spin-r 1s infinite linear
    animation: glyphicon-spin-r 1s infinite linear
    color: $color1
    padding-top: 1px !important

  @-webkit-keyframes glyphicon-spin-r
    0%
      -webkit-transform: rotate(0deg)
      transform: rotate(0deg)

    100%
      -webkit-transform: rotate(359deg)
      transform: rotate(359deg)

  @keyframes glyphicon-spin-r
    0%
      -webkit-transform: rotate(0deg)
      transform: rotate(0deg)

    100%
      -webkit-transform: rotate(359deg)
      transform: rotate(359deg)

  input
    border: 1px solid $color5-dk
    border-radius: 5px

  span
    padding-top: 0px !important

  .gd-not-found
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 10vh

  .gd-search-content
    display: none
    max-height: 90vh !important
    overflow-y: auto
    overflow-x: hidden

  .gd-search-content
    display: block
    border-bottom: 1px solid $color5-dn

    .gd-search-panel
      border: 1px solid $color5-dn
      background-color: #ffffff

      .gd-search-header
        border-top: 1px solid $color5-dn

        div
          padding: 0 5px 0 5px

        a
          color: $color4-ds
          display: inline-block !important

          a:hover
            color: $color1

          span
            padding-top: 3px
            display: inline-block !important
            vertical-align: middle !important

      .gd-search-section

        div
          padding: 0 10px 0 5px

        i,
        p
          color: $color4-ds

      .gd-search-section:hover
        background-color: $color5-dk
        i,
        p
          color: $color1

      .gd-title
        white-space: nowrap
        overflow: hidden
        text-overflow: ellipsis
        max-width: 200px
        font-weight: bold

  i
    background: #e5e5e5
    border-radius: 50%
    display: inline-block
    height: 40px
    width: 40px
    padding-left: 8px
    padding-top: 8px

  .gd-flex
    min-height: 2vh
    display: flex
    flex-direction: row
    justify-content: flex-start

  .panel
    margin: 0 !important
    padding: 0 !important

    i:active
      transform: rotate(180deg)

    ul
      list-style-type: none
      li:hover
        background-color: #e5e5e5

  .gd-title-container
    color: $color5-ds
    min-height: 10vh
    display: flex
    flex-direction: column
    justify-content: center
    align-items: flex-start

  p
    font-size: 1em
    padding: 0 !important
    margin: 0 !important
    color: $color4-dk

  .mat-mdc-form-field
    input.mat-mdc-input-element
      padding-bottom: 3px

@media(min-width: 200px) and (max-width: 767px)
  app-all-search-select, .app-all-search-select
    position: relative
    display: inline-block

    .gd-search-content
      display: none
      max-height: 90vh !important

    .gd-search-content
      display: block

      .gd-search-panel
        width: 60vw !important

        .gd-search-header
          width: 60vw !important
          min-height: 5vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-header-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

        .gd-search-section
          width: 60vw !important
          min-height: 12vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-section-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

@media(min-width: 768px) and (max-width: 991px)
  app-all-search-select, .app-all-search-select
    position: relative
    display: inline-block

    .gd-search-content
      display: none
      max-height: 90vh !important

    .gd-search-content
      display: block

      .gd-search-panel
        width: 35vw !important

        .gd-search-header
          width: 35vw !important
          min-height: 5vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-header-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

        .gd-search-section
          width: 35vw !important
          min-height: 12vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-section-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: flex-start

@media(min-width: 992px) and (max-width: 1199px)
  app-all-search-select, .app-all-search-select
    position: relative
    display: inline-block

    .gd-search-content
      display: none
      max-height: 90vh !important

    .gd-search-content
      display: block

      .gd-search-panel
        width: 40vw !important

        .gd-search-header
          width: 40vw !important
          min-height: 5vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-header-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

        .gd-search-section
          width: 40vw !important
          min-height: 12vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-section-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: flex-start

@media(min-width: 1200px)
  app-all-search-select, .app-all-search-select
    position: relative
    display: inline-block

    .gd-search-content
      display: none

    .gd-search-content
      display: block

      .gd-search-panel

        .gd-search-header
          min-height: 5vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-header-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: space-between

        .gd-search-section
          min-height: 12vh
          display: flex
          flex-direction: row
          justify-content: flex-start
          align-items: center

          .gd-section-content
            width: 100%
            display: flex
            flex-direction: row
            justify-content: flex-start

