.stakeholder-log
  margin-top: 12px !important

.stakeholder-log_search--input
  margin-bottom: 8px

.stakeholder-log_stakeholder--name
  font-weight: bold !important
  text-overflow: ellipsis
  line-height: 30px
  max-height: 30px
  overflow: hidden

.stakeholder-log_timestamp
  color: gray !important
  font-size: 10px !important

.stakeholder-log_pagination
  margin-bottom: 5px

.stakeholder-log_notice
  margin-bottom: 30px

.stakeholder-log-stakeholders_select
  margin-bottom: 30px

mat-list-option
  height: 73px !important

::ng-deep .mat-pseudo-checkbox-checked
  background: #2800D2 !important

.font
  font-family: 'work_sans_regular'
  font-size: 13px !important

.fontbold
  font-family: 'work_sans_semibold'
  font-size: 13px !important

.modal-newlog-stakeholders_multiselect
  width: 100%

.new-stakeholder-info
  position: absolute
  top: -18px
  left: 112px

// Modal  Dialog Klantwensen
.modal-dialog

  .stakeholder-log
    margin-top: 12px !important

  .stakeholder-log_search--input
    margin-bottom: 8px

  .stakeholder-log_stakeholder--name
    font-weight: bold !important
    text-overflow: ellipsis
    line-height: 30px
    max-height: 30px
    overflow: hidden

  .stakeholder-log_timestamp
    color: gray !important
    font-size: 10px !important

  .stakeholder-log_notice
    margin-bottom: 30px

  .stakeholder-log-stakeholders_select
    margin-bottom: 30px

  mat-list-option
    height: 73px !important

  ::ng-deep .mat-pseudo-checkbox-checked
    background: #2800D2 !important

  .font
    font-family: 'work_sans_regular'
    font-size: 13px !important

  .fontbold
    font-family: 'work_sans_semibold'
    font-size: 13px !important

.gd-modal-report
  div
    button
      span#gd-modal-report--close
        font-size: 35px

  form
    mat-dialog-content
      mat-mdc-form-field
        input#gd-logo-path
          margin-left: 15px
      img
        width: 300px

  mat-dialog-actions
    button
      margin-left: 13px

.sidemodal
  width: 75vw !important

@media only screen and (max-width: 960px)
  .sidemodal
    width: 90vw !important

.gd-modal-create
  .gd-input-validation
    margin-top: -18px

    .gd-validation-color
      color: #EA6153

  .panel-default
    margin-top: 3vh

    .gd-label
      font-size: 12px
      color: black

    .gd-chips-container
      display: inline

      .gd-chips
        margin: 5px
        padding: 5px

.gd-full-screen-modal .mat-mdc-dialog-container
  max-width: none

.popup-container
    .mat-icon.delete
      color: gray
    .popup-body
      color: inherit
      overflow: auto
      height: calc(100vh - 116px)
      margin-bottom: 0
      margin-top: 3px
    .z-depth-2
      box-shadow: none


.popup-header-title
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap


.popup-header.scrolled
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.12)

.popup-header
  h1
    display: inline
    font-size: 25px
  .close
    padding-top: 4px
  .delete
    padding-top: 4px

.panel-border
  border-radius: 20px
  border: 1px solid #D6D6D6
  box-shadow: none
  padding: 10px

.panel-border-relation-component
  // overflow: hidden

@media (max-width: 767px)
  .popup-header-title
    h1
      strong
        display: block

.modal-backdrop
  z-index: 0
.popup-header
  .delete
    margin-right: 14px


.mdc-dialog .mdc-dialog__content
  // padding: 0px !important
  margin: 0 -24px !important
  padding: 0 24px !important


.mdc-dialog__surface
  overflow: unset !important
