.d-bg-grey{
    background-color: #f9f9f9;
} 

.d-bg-full{
  background-image: url(../images/login-bg-min.png);
  background-repeat: no-repeat;
  background-attachment:scroll;
  background-position: center; 
  width: 100%;
  height: 100vh;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;   
}

/* responsive login container */
.login-container {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
  
@media (max-width: 991px) {
 .login-container {
    top:10%;
  } 
}
  
@media (max-width: 1399px) {
 .login-container {
    top:20%;
  } 
}

@media (min-width: 1400px) {
 .login-container {
    top:30%;
 } 
}

/* end of responsive login container */

.d-header-full{
    width: 100%;
    text-align: center;
    min-height: 100px;
    color: white;
    font-size: 30pt;
    text-shadow: 1px 1px 1px #000;
    margin-bottom: 30px;
}
.d-header-full.visible-xs{
    margin-top: 50px;
    font-size: 20pt;
}

.d-loginform{
    position: relative;
    max-width: 300px;
    height: auto;
    margin: 0 auto;
    -webkit-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.75);
    background-color:#f9f9f9;
    padding: 10px;
}
.d-img-project{
    max-height: 100px;
    width: auto;
}

.d-item-project{
    padding: 10px;
}
.d-item-project:hover{
    background: #f9f9f9;
}
.btn-login,.btn-login:hover{
    color: white;
    background: #1E9FF2;
}
