app-subproject-selector
  .mat-mdc-form-field-wrapper
    .mat-mdc-form-field-flex
      .mat-mdc-form-field-infix
        background-color: #ffffff
        border: 1px solid #f2f2f2
        border-radius: 5px
        padding-left: 10px
    .mat-form-field-underline
      display: none
.sub-project-avatar
  .avatar-content
    border-width: 3px !important
