/* ---- isotope ---- */

.grid {
  width: 100%;
}

/* clear fix */
.grid:after {
  content: '';
  display: inline-block;
  clear: both;
}

/* ---- .grid-item ---- */
.grid-item {
  position: relative;
}

@media (max-width: 991px) {
  .grid-sizer,
  .grid-item {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1599px) {
  .grid-sizer,
  .grid-item {
    width: calc(50% - 5px);
  }

  .grid
  .grid-sizer,
  .grid-item {
    margin-left: 0%;
    margin-right: 0%;
  }
  
}

@media (min-width: 1600px) {
  .grid-sizer,
  .grid-item {
    width: calc(33.333% - 7px);
  }

  .grid
  .grid-sizer,
  .grid-item {
    margin-left: 0%;
    margin-right: 0%;
  }
}

.grid-item .number {
  display: inherit;
}

div.dashboard-monitor-issues {
  height: 219px;
}

div.dashboard-my-issues {
  min-height: 330px;
}
