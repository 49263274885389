$max-size: 100

// Generate commonly used paddings and margins
@for $i from 0 through $max-size
  .mt-#{$i}
    margin-top: #{$i}px !important
  .mr-#{$i}
    margin-right: #{$i}px !important
  .mb-#{$i}
    margin-bottom: #{$i}px !important
  .ml-#{$i}
    margin-left: #{$i}px !important
  .pt-#{$i}
    padding-top: #{$i}px !important
  .pr-#{$i}
    padding-right: #{$i}px !important
  .pb-#{$i}
    padding-bottom: #{$i}px !important
  .pl-#{$i}
    padding-left: #{$i}px !important
  .mg-#{$i}
    margin: #{$i}px !important
  .pd-#{$i}
    padding: #{$i}px !important

.no-padding
  padding: 0 !important

.no-padding-right
  padding-right: 0px !important  

.no-margin
  margin: 0 !important

.no-border
  border: 0 !important

.border-top-0
  border-top: 0 !important

.overflow-hidden
  overflow: hidden

.initial-cursor
  cursor: initial

.d-cursor
  cursor: default !important

.d-cursor-hand
  cursor: pointer !important

.d-cursor-text
  cursor: text !important