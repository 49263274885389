.gd-navbar
  background-color: #F2F3F8
  border-bottom: none

  .gd-navbar-top
    display: flex
    width: 100%
    flex-direction: row
    justify-content: space-between
    background-color: #F2F3F8
    border-bottom: none

    .pull-right
      padding-right: 0px !important
      margin-right: 0px !important

      .personalized-logo
        margin-top: 15px !important
        margin-right: 15px !important

      i
        margin-top: 7px

      ul
        li:hover
          a:hover
            background-color: transparent

    .gd-navbar-center
      background-color: transparent

    .gd-navbar-left
      display: flex
    .gd-navbar-right
      background-color: transparent
      display: flex
      flex: 3
      flex-direction: column
      align-items: flex-end

      ul
        li
          a
            background-color: transparent

      ul
        li:hover

          a:hover
            background-color: transparent

  /** Navbar Collapse */


  .gd-collapse
    overflow: scroll
    overflow-x: hidden

    .gd-notification
      font-size: 12px

      .gd-notification-content
        padding: 0px 20px 0px 20px

        span
          display: block

    span,
    i,
    .gd-label
      padding-left: 20px
      padding-right: 20px

  ::-webkit-scrollbar
    width: 0px
    background: transparent

  ::-webkit-scrollbar-thumb
    background: #FF0000

@media(min-width: 200px) and (max-width: 767px)
  .gd-navbar
    .gd-navbar-top
      display: flex
      width: 100%
      flex-direction: row
      justify-content: space-between

    .pull-right
      padding-right: 0px !important
      margin-right: 0px !important

    .gd-navbar-left
      display: flex
      width: 5vw
      flex-direction: row
      justify-content: flex-start

      .personalized-logo
        margin-top: 25px !important
        margin-left: 20px !important
        margin-right: 15px !important

    .gd-navbar-center
      .form-control
        width: 60vw !important
        margin-left: 10vw

    form
      border-top: none !important
      border-bottom: none !important

    .gd-desktop-screen
      display: none

@media(min-width: 768px) and (max-width: 991px)
  .gd-navbar
    .gd-navbar-top
      display: flex
      width: 100%
      flex-direction: row
      justify-content: space-between


  .gd-phone-screen
    display: none


@media(min-width: 992px) and (max-width: 1199px)
  .gd-navbar
    .gd-navbar-top
      display: flex
      width: 100%
      flex-direction: row
      justify-content: space-between

  .gd-phone-screen
    display: none

@media(min-width: 1200px)
  .gd-navbar
    .gd-navbar-top
      display: flex
      width: 100%
      flex-direction: row
      justify-content: space-between

    .gd-navbar-left
      display: flex
      width: 15vw
      flex: 1
      flex-direction: row
      justify-content: flex-start

      .navbar-nav>li>a:hover
        background-color: transparent

    .gd-navbar-center
      .form-control
        margin-top: 0px !important

  .gd-phone-screen
    display: none

.nav.navbar-nav>li>a
  color: #434344 !important  

@media only screen and (max-width: 1000px)
  .gd-navbar
   .gd-navbar-center
     .form-control
      width: 160px
  .selector-dropdown
    min-width: 170px !important

@media only screen and (max-width: 960px)
  .gd-navbar
   .gd-navbar-center
     .form-control
      width: 80px
  .selector-dropdown
    min-width: 170px !important

@media only screen and (max-width: 767px)
  .personalized-logo
    height: 35px
    width: 200px
    margin-top: 11px
    margin-right: 15px !important
    background-repeat: no-repeat
    background-position: center left
    background-size: contain
  

@media only screen and (min-width: 768px)
  .personalized-logo
    height: 35px
    width: 200px
    margin-top: 11px
    margin-right: 15px !important
    background-repeat: no-repeat
    background-position: center left
    background-size: contain


  .dropdown-menu
    font-size: 13px

@media only screen and (min-width: 1200px) and (max-width: 1450px)
  .custom-width-768
    width: 3vw !important