app-tab-issue-detail-general

  app-urgency-status
    position: relative
    top: 6px

    > p
      float: right
      text-transform: capitalize

  .interests
    .mat-list-item-content
      height: 50px !important
      padding-left: 0 !important
    mat-list-option
      height: 50px !important
