international-phone-number
  .country-name
    position: absolute
    right: 17px
  .dial-code
    margin-left: 11px
  div
    span
      div
        button.dropbtn
          margin: 0px
        div.dropdown-content
          min-width: 0px !important
          div.scrollable-menu
            width: 52px

international-phone-number.ng-invalid
  div
    input
      border-color: #f44336
