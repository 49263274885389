.sidenav-container
  .mat-drawer-inner-container
    background-color: #f2f3f8
  .nav.side-menu>li>a 
    margin-bottom: 0px !important
    padding: 10px 15px !important
    color: #434344
  .nav.side-menu>li>a:hover
    color: #2800D2 !important
  .nav.side-menu>li>a.active
    color: #2800D2
    background: #00000009 !important  

.mdc-list
  padding-top: 0px !important

.mat-drawer-inner-container::-webkit-scrollbar 
  display: none

.mat-drawer-inner-container 
  -ms-overflow-style: none
  scrollbar-width: none
  min-width: 200px

  .site_title  
    background-color: #518ba7
    line-height: 55px !important
    height: 59px
    position: fixed
    top: 0
    left: 0
    z-index: 999
    width: 200px
    color: #FFFFFF !important

  .side_menu
    padding-top: 67px
