@font-face 
  font-family: 'work_sans_600'
  src: url('../../fonts/WorkSans-SemiBold.ttf')

@font-face 
  font-family: 'work_sans_light'
  src: url('../../fonts/WorkSans-Light.ttf')

@font-face 
  font-family: 'work_sans_semibold'
  src: url('../../fonts/WorkSans-SemiBold.ttf')

@font-face 
  font-family: 'work_sans_regular'
  src: url('../../fonts/WorkSans-Regular.ttf')
@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg') format('svg')
  font-weight: 100
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.svg') format('svg')
  font-weight: 100
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.svg') format('svg')
  font-weight: 200
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.svg') format('svg')
  font-weight: 200
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300-.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300-.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.svg') format('svg')
  font-weight: 300
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.svg') format('svg')
  font-weight: 300
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.svg') format('svg')
  font-weight: 400
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.svg') format('svg')
  font-weight: 400
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.svg') format('svg')
  font-weight: 500
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.svg') format('svg')
  font-weight: 500
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.svg') format('svg')
  font-weight: 600
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.svg') format('svg')
  font-weight: 600
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.svg') format('svg')
  font-weight: 700
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.svg') format('svg')
  font-weight: 700
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.svg') format('svg')
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.svg') format('svg')
  font-weight: 800
  font-style: italic

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.svg') format('svg')
  font-weight: 900
  font-style: normal

@font-face
  font-family: 'rawline'
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.eot')
  src: url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.eot?#iefix') format('embedded-opentype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.woff2') format('woff2'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.woff') format('woff'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.ttf') format('truetype'), url('https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.svg') format('svg')
  font-weight: 900
  font-style: italic

label.material-style-label
  left: 0
  font: inherit
  pointer-events: none
  width: 100%
  white-space: nowrap
  text-overflow: ellipsis
  overflow: hidden
  transform-origin: 0 0
  transition: transform .4s cubic-bezier(.25,.8,.25,1),color .4s cubic-bezier(.25,.8,.25,1),width .4s cubic-bezier(.25,.8,.25,1)

body 
  font-family: 'work_sans_regular'
.ashgrey
  color: #b4b4b5

.link
  color: #2800D2
a
  color: #2800D2
  
.count-animated, .date
  font-family: 'work_sans_regular'

.bold
  font-weight: bold

.italic
  font-style: italic

h1
  font-family: 'work_sans_light'
  font-size: 32px
  color: #2800D2

h2
  font-family: 'work_sans_semibold'
  font-size: 18px
  font-weight: 600

h3
  font-family: 'work_sans_semibold'
  font-size: 15px
  font-weight: 400


h4
  font-family: 'work_sans_regular'
  font-size: 15px

h5
  font-family: 'work_sans_semibold' !important
  font-size: 14px !important
  color: #2800D2 !important
  font-weight: 600px !important

h6
  font-family: 'work_sans_semibold'
  font-size: 13px
  font-weight: 600px

@media only screen and (max-width: 576px)
  h1
    font-size: 22px 