.pac-container
  z-index: 100000

.gd-verantwoordelijken
  ngx-select
    .ngx-select__selected-plural
      width: 100% !important
      background-color: $color5-dk
      height: 40px

      span
        width: 100% !important
        text-align: left
        height: 40px
        padding: 10px
        display: flex
        flex-direction: column
        justify-content: center

      .ngx-select__clear
        text-align: right
        padding: 10px
        i
          margin-bottom: 7px
.stakeholder-interest-table thead
  display: none
.Samenwerken
  background-color: #FF7669
  color:#FFFFFF
.Uncategorised
  color:#000000 !important
.Informeren
  background-color: #F7F050
  color: #000000
.Informeren-strategie
  background-color: #E0E1E3
  color: #000000
.Monitoren
  background-color: #E0E1E3
  color:#000000
.Tevreden.houden
  background-color: #FF9F95  
  color:#000000
.Meebeslissen
  background-color: #FF7669
  color:#000000
.Coproduceren
  background-color: #FF9f95
  color:#000000
.Adviseren
  background-color: #F7B050
  color:#000000
.Raadplegen
  background-color: #F7F050
  color:#000000
.Reactief
  background-color: #FFFFFF
  color:#000000
  border: 1px solid #E0E1E3

app-interests-powerfield-matrix,app-powerfield-matrix
  form
    margin-top: 40px