.mt-0
    margin-top: 0px
.p-0
    padding: 0px
.m-0
    margin: 0px
@media screen and (max-width: 767px) and (min-width: 320px)
  .item-width-style
    width: 100%

@media screen and (max-width: 1900px) and (min-width: 768px)
  .item-width-style
    width: 49.5%

@media (min-width: 1901px)
  .item-width-style
    width: 32.9%

.set-expansion-style
  border-radius: 4px !important
  margin-bottom: 2px !important
  border: 2px solid #e9eaef !important
  box-shadow: none !important
  mat-expansion-panel-header
    font-size: 13px !important
    height: 50px !important
  mat-panel-description
    height: 35px
    margin-top: 16px
    width: 200px
    font-size: 12px
  mat-panel-title
    height: 35px
    margin-top: 16px
    width: 100px
    font-size: 14px

.text-gray
  color: #C8C8C8 !important

.empty-state-image
  width: 125px

.empty-state-image-small
  width: 75px

.empty-oc-height-set
  height: 300px
.empty-padding
  display: flex
  align-items: center
  justify-content: center
.empty-oc-height-set
  h2
    font-family: 'work_sans_semibold'
    font-size: 18px
    font-weight: 600
  p
    font-size: 12px

flx-text-input-highlight
  display: none !important

.disable-anchor-tag-button
  pointer-events: none !important
  cursor: not-allowed !important
  color: #c4c4c4 !important
.iti__country-list
  position: fixed !important
  overflow: auto !important
.w-100
  width: 100%
.dragplaceholder-box
  width: 200px
  height: 200px
  padding: 10px
  box-sizing: border-box
  border: solid 1px #ccc
  color: rgba(0, 0, 0, 0.87)
  display: flex
  justify-content: center
  align-items: center
  text-align: center
  background: #fff
  border-radius: 4px
  position: relative
  z-index: 1
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1)
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12)

  &:active
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)

.dragplaceholder-handle
  position: absolute
  top: 10px
  right: 10px
  color: #ccc
  cursor: move
  width: 24px
  height: 24px

.calender-owl-icon-set
    position: absolute
    font-size: 13px
    right: -10px
    color: #757575
    bottom: -6px
.ellipsis-2
  display: -webkit-box
  max-height: 60px
  margin: 0 auto
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
  overflow: hidden
  text-overflow: ellipsis
  word-break: break-word
.d-flex-end
  display: flex
  justify-content: end
.wp-350
  width: 350px

.table-pagination-set
  margin:-12px 0px !important
  background-color: white !important

.table-scrolling-set
  overflow: auto !important
  height: 70vh !important
  border: 1px solid #DDDDDD
  border-radius: 20px
.p-relative
  position: relative

.badge.status
  position: absolute
  top: -7px
  padding: 5px !important

.l-20
  left: 20px !important

.r-0
  right: 0px !important

.badge.status.on
  background-color: #FF7669 !important

.badge.status.off
  background-color: #D3D3D3 !important

.text-black
  color: #000 !important
.vertical-line-intrest
  position: absolute
  font-size: 10px
  top: 50%
  transform: translate(-50%, -50%)
  border: 1px solid #ada9a9
  height: 100%
.vertical-line-intrest-header
  border: 1px solid #ada9a9
  height: 100%
