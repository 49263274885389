//If you're doubting where to put the styling code, put it here

ul.msg_list
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important
  -moz-box-shadow: rgba(0,0,0,0.3) 0 1px 3px !important
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important
  max-height: calc(100vh - 50px)
  overflow: auto

ul.msg_list li
  padding: 5px
  background-color: #FFFFFF

ul.msg_list li a .message
  display: inline-block !important

ul.msg_list li.d-notifications-header
  background: white
  padding: 5px

ul.msg_list a
  color: #2800D2


ul.msg_list i
  color: #515356

/*ul li fix by gentelella*/

ul.msg_list li,
ul.msg_list li:last-child,
ul.msg_list span,
ul.msg_list span + label
  padding-left: 5px

ul.msg_list span + label
  top: 4px

.inline-block
  display: inline-block

.dropzone-small
  .dropzone-container
    width: 100% !important

.dropzone-small 
  .dz-message
    margin: 0px !important
    min-height: 25px !important
    border-radius: 5px !important

.dropzone-small 
  .dropzone.dz-wrapper 
    .dz-message 
      .dz-text
        padding: 8px 5px
        text-align: left

.dropzone-small 
  .dz-message 
    .mat-icon
      position: absolute
      padding-left: 5px
      right: 10px

.map-container 
  .md-drppicker
    top: 53px!important
    font-family: 'work_sans_regular' !important


.map-container
  .md-drppicker 
    .btn
      background: #2800D2!important

.md-drppicker
  td.active
    background: #2800D2!important
  td.in-range
    background: #2800D2!important

.map-container 
  .md-drppicker 
    .btn-default
      background: transparent!important
      box-shadow: none

.map-container 
  .md-drppicker
    .calendar.right
      display: none


.map-container
  .popover
    width: 200px


.map-container 
  .popover-content 
    overflow: hidden !important
    text-overflow: ellipsis !important
    text-align: center

.custom-expand
  .mat-expansion-indicator::after
    padding: 5px !important

.label-color-DD758F
  color: #000000
.label-color-4A9ED6
  color: #000000
.label-color-FDB6C8
  color: #000000
.label-color-FF9629
  color: #000000
.label-color-F1C40F
  color: #000000
.label-color-4CBDB3
  color: #000000

.label-color-753EA2
  color: #FFFFFF !important
.label-color-003367
  color: #FFFFFF !important
.label-color-1A5C8F
  color: #FFFFFF !important
.label-color-99382D
  color: #FFFFFF !important
.label-color-136175
  color: #FFFFFF !important
.label-color-525252
  color: #FFFFFF !important

