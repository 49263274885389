.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle
  border-color: #2800D2 !important

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle
  border-color: #2800D2 !important
  background-color: #2800D2 !important

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary
  background-color: #3C8CAA

.mat-row
  color: #36373A
  font-family: 'work_sans_regular' !important
  font-variant-numeric: lining-nums
  font-size: 13px
  font-weight: 400
  height: auto
  position: relative

.mat-mdc-cell
  padding: 12px !important

.mat-mdc-header-cell
  padding: 12px !important

mat-row:hover
  background-color: #f5f5f5

.mat-mdc-row-link
  position: absolute
  width: 100%
  height: 100%
  left: 0
  top: 0

.mat-mdc-row
  font-family: 'work_sans_regular' !important

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar
  background-color: rgba(30, 159, 242, .5)

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb
  background-color: #2800D2

.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate
  background-color: #2800D2

.mat-mdc-tab-body-content
  overflow: hidden !important

.mat-mdc-progress-bar
  --mdc-linear-progress-active-indicator-color: #2800D2 !important

.mat-radio-label-content
  color: #36373A
  font-family: 'work_sans_regular'
  font-variant-numeric: lining-nums
  font-size: 13px
  font-weight: 400

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate
  background: #2800D2

.mat-progress-spinner circle, .mat-spinner circle
  stroke: #2800D2

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple
  background-color: #2800D2

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label
  color: #2800D2

.mat-primary .mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled)
  color: #2800D2
.mat-tab-group.mat-primary, .mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab-link-container .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline
  width: 80% !important
  border-top-width: thin !important

.mat-mdc-table
  width: 100%

.mat-table tbody tr:hover
  cursor: pointer
  background: #f4f4f4

.mat-mdc-table, .mat-mdc-paginator
  font-family: 'work_sans_regular' !important
.mat-mdc-header-row
  font-family: 'work_sans_regular' !important

app-interests-matrix
  .mat-mdc-paginator
    margin-right: 30px !important

.mat-mdc-header-cell
  font-weight: 700 !important
  font-size: 14px
  padding: 12px
  color: black
  line-height: 1.2 !important
  overflow: visible !important

.mat-sort-header-stem, .mat-sort-header-pointer-middle
  background: none
  display: none !important

.mat-sort-header-indicator
  align-items: center

.mat-mdc-paginator
  background-color: #f2f3f8

.search-holder
  height: 40px
  display: flex
  align-items: center
  padding-inline-start: 12px

.search-box
  width: 100%
  border: none
  vertical-align: middle
  display: table-cell
  padding: 6px 6px 6px 12px
  font-size: 13px

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit
  background-color: #2800D2

.mat-stepper-horizontal, .mat-stepper-vertical
  font-family: 'work_sans_regular'
  font-size: 14px

.mat-horizontal-content-container
  padding: 0 !important

.mat-icon
  height: 24px !important
  width: 24px !important
  overflow: visible !important

.arrow_back
  display: inline-flex !important
  align-items: center !important


.mat-icon-disabled
  opacity: 0.28

.mat-pseudo-checkbox-checked
  background: #2800D2 !important

.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background
  background: #2800D2 !important

.mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-checkbox-ripple .mat-ripple-element
  background: #2800D2 !important
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background
  background: #2800D2 !important
  border-color: #2800D2 !important

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark
  color: #fff !important

.mat-badge-content
  background-color: #28ABE3

.cdk-global-scrollblock
  overflow-y: inherit

.cdk-overlay-container
  z-index: 2000

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label
  color: #f44336

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-suffix mat-datepicker-toggle
  color: #f44336

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline
  background-color: #f44336

.mat-mdc-form-field-underline
  height: 1px
  background-color: #D6D6D6 !important

.mat-horizontal-stepper-header
  pointer-events: none

app-issue-new .mat-button-toggle-appearance-standard .mat-button-toggle-label-content
  line-height: 28px !important
  padding: 0 10px !important
  font-size: 12px !important

app-project-config-instellingen .mat-button-toggle-appearance-standard .mat-button-toggle-label-content, app-add-open-data-layers .mat-button-toggle-appearance-standard .mat-button-toggle-label-content
  line-height: 32px !important 
  font-size: 13px !important

app-issue-new .mat-button-toggle-group
  width: 100%
  margin-bottom: 10px

app-issue-new .map-toggles-set.mat-button-toggle-group
  width: auto
  bottom: 35px

app-issue-new .mat-button-toggle
  width: 100%

.custom-dialog-container .mat-mdc-dialog-container
  padding: 15px

.mdc-dialog__title::before
  display: block !important
  height: 0px !important

.custom-tabs .mat-ink-bar
  border: 1px solid black

.mat-mdc-form-field
  font-family: 'work_sans_regular'
  font-size: inherit
  font-weight: 400
  line-height: 1.125
  letter-spacing: normal

.custom-expand .mat-expansion-panel-body, .custom-expand-map .mat-expansion-panel-body
  padding: 0 16px 16px
  font-family: 'work_sans_regular'

app-stakeholders-tabs .mat-mdc-tab-nav-bar, app-issues-detail .mat-mdc-tab-nav-bar, app-actions .mat-mdc-tab-nav-bar, app-analysis .mat-mdc-tab-nav-bar, app-new-workflow .mat-mdc-tab-nav-bar
  max-width: 422px

app-stakeholders-tabs .overview-tabs, app-issues-detail .overview-tabs, app-actions .overview-tabs, app-analysis .overview-tabs, app-new-workflow .overview-tabs
  max-width: 422px

app-stakeholders-tabs .mat-mdc-tab-list, app-issues-detail .mat-mdc-tab-list, app-actions .mat-mdc-tab-list, app-analysis .mat-mdc-tab-list, app-new-workflows .mat-mdc-tab-list
  border: 1px solid rgba(0,0,0,.12)
  border-radius: 4px
  overflow: visible

app-stakeholders-tabs .mat-mdc-tab-link, app-issues-detail .mat-mdc-tab-link, app-actions .mat-mdc-tab-link, app-analysis .mat-mdc-tab-link, app-new-workflow .mat-mdc-tab-link
  height: 37px !important
  //max-width: 115px
  min-width: 115px

app-stakeholders-tabs .mat-mdc-tab-nav-bar, app-issues-detail .mat-mdc-tab-nav-bar, app-actions .mat-mdc-tab-nav-bar, app-analysis .mat-mdc-tab-nav-bar, app-new-workflow .mat-mdc-tab-nav-bar
  border-bottom: 0px


app-stakeholder .mat-paginator-container, app-issues-detail .mat-paginator-container, app-klanwensten-list .mat-paginator-container, app-logs-overview .mat-paginator-container, app-complaints-list .mat-paginator-container, app-stakeholders-employees .mat-paginator-container, app-documents .mat-paginator-container, app-import-email-modal .mat-paginator-container, app-project-config-logs .mat-paginator-container
  padding: 0px
  width: auto !important
  position: sticky !important
  left: 0

app-stakeholder .mat-paginator-outer-container, app-issues-detail .mat-paginator-outer-container, app-klanwensten-list .mat-paginator-outer-container, app-logs-overview .mat-paginator-outer-container, app-complaints-list .mat-paginator-outer-container, app-stakeholders-employees .mat-paginator-outer-container, app-documents .mat-paginator-outer-container, app-import-email-modal .mat-paginator-outer-container, app-project-config-logs .mat-paginator-outer-container
  padding-top: -3px

app-stakeholder .mat-paginator-page-size-select, app-issues-detail .mat-paginator-page-size-select, app-klanwensten-list .mat-paginator-page-size-select, app-logs-overview .mat-paginator-page-size-select, app-complaints-list .mat-paginator-page-size-select, app-stakeholders-employees .mat-paginator-page-size-select, app-documents .mat-paginator-page-size-select, app-import-email-modal .mat-paginator-page-size-select, app-project-config-logs .mat-paginator-page-size-select
  margin: 12px 4px 0 4px

app-stakeholder .mat-paginator-range-actions, app-issues-detail .mat-paginator-range-actions, app-klanwensten-list .mat-paginator-range-actions, app-logs-overview .mat-paginator-range-actions, app-complaints-list .mat-paginator-range-actions, app-stakeholders-employees .mat-paginator-range-actions, app-documents .mat-paginator-range-actions, app-import-email-modal .mat-paginator-range-actions, app-project-config-logs .mat-paginator-range-actions
  padding-bottom: 3px

app-stakeholder .mat-paginator-navigation-previous, app-issues-detail .mat-paginator-navigation-previous, app-klanwensten-list .mat-paginator-navigation-previous, app-logs-overview .mat-paginator-navigation-previous, app-complaints-list .mat-paginator-navigation-previous, app-stakeholders-employees .mat-paginator-navigation-previous, app-documents .mat-paginator-navigation-previous, app-import-email-modal .mat-paginator-navigation-previous, app-project-config-logs .mat-paginator-previous
app-stakeholder .mat-paginator-navigation-next, app-issues-detail .mat-paginator-navigation-next, app-klanwensten-list .mat-paginator-navigation-next, app-logs-overview .mat-paginator-navigation-next, app-complaints-list .mat-paginator-navigation-next, app-stakeholders-employees .mat-paginator-navigation-next, app-documents .mat-paginator-navigation-next, app-import-email-modal .mat-paginator-navigation-next, app-project-config-logs .mat-paginator-navigation-next
  height: 37px
  border: 1px solid rgba(0,0,0,.12)
  border-radius: 4px
  overflow: visible

app-stakeholder .mat-paginator-navigation-next, app-issues-detail .mat-paginator-navigation-next, app-klanwensten-list .mat-paginator-navigation-next, app-logs-overview .mat-paginator-navigation-next, app-complaints-list .mat-paginator-navigation-next, app-stakeholders-employees .mat-paginator-navigation-next, app-documents .mat-paginator-navigation-next, app-import-email-modal .mat-paginator-navigation-next, app-project-config-logs .mat-paginator-navigation-next
  margin-left: 4px
  color: #2800D2

app-stakeholder .mat-paginator-navigation-previous, app-issues-detail .mat-paginator-navigation-previous, app-klanwensten-list .mat-paginator-navigation-previous, app-logs-overview .mat-paginator-navigation-previous, app-complaints-list .mat-paginator-navigation-previous, app-stakeholders-employees .mat-paginator-navigation-previous, app-documents .mat-paginator-navigation-previous, app-import-email-modal .mat-paginator-navigation-previous, app-project-config-logs .mat-paginator-navigation-previous
  margin-left: 8px
  color: #2800D2

app-stakeholder .mat-paginator-icon, app-issues-detail .mat-paginator-icon, app-klanwensten-list .mat-paginator-icon, app-logs-overview .mat-paginator-icon, app-complaints-list .mat-paginator-icon, app-stakeholders-employees .mat-paginator-icon, app-documents .mat-paginator-icon, app-import-email-modal .mat-paginator-icon, app-project-config-logs .mat-paginator-icon
  padding-bottom: 5px

app-dashboard-pie-chart .mat-button-toggle-label-content
  line-height: 38px !important

.custome-padding .mat-mdc-form-field-infix
  padding-top: 6px !important
  padding-bottom: 10px !important

.custome-padding .mat-mdc-form-field-label
  top: 20px !important

.custome-padding-select .mat-mdc-form-field-flex
  padding-bottom: 2px !important

.mdc-text-field--filled:not(.mdc-text-field--disabled)
  background-color: transparent !important

.mat-mdc-form-field-focus-overlay
  background-color: transparent !important

@media all
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder
    color: #000


.mat-mdc-form-field
  font-size: inherit
  font-weight: 400
  line-height: 1.125
  font-family: Roboto,Helvetica Neue,sans-serif !important
  letter-spacing: normal

.mat-mdc-form-field-wrapper
  position: relative !important

.mat-mdc-form-field-infix
  display: block !important
  position: relative !important
  flex: auto !important
  min-width: 0 !important
  width: 180px !important

.mdc-text-field
  padding: 0px !important

.mat-mdc-input-element
  caret-color: #673ab7 !important

.mat-mdc-form-field .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label
  color: #3f51b5 !important
  font-size: 16px !important
  transform-origin: top left !important
  transition: transform 0.2s, color 0.2s !important

.mat-mdc-form-field .mat-mdc-input-element:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
.mat-mdc-form-field .mat-mdc-input-element.ng-not-empty + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label
  transform: translateY(-1.25em) scale(0.75) !important
  color: #3f51b5 !important

.mdc-text-field--filled.mdc-text-field--disabled
  background-color: #fff !important

.mat-mdc-radio-button .mdc-radio
  padding: 0px !important

.mat-mdc-select
  font-size: inherit !important

.mat-mdc-select-value-text
  font-size: 13px !important
  font-family: 'work_sans_regular' !important


// Override default Angular styling for overview paginator
app-stakeholder, app-issues-detail, app-klanwensten-list, app-logs-overview, app-permit-overview, app-complaints-list, app-stakeholders-employees, app-documents, app-import-email-modal, app-interests-matrix, app-recycle-bin, app-project-config-logs
  .mat-mdc-paginator-container
    padding: 0px
    width: auto !important
    position: sticky !important
    left: 0
  .mat-mdc-paginator-outer-container
    padding-top: -3px
  .mat-mdc-paginator-page-size-label
    color: rgba(0,0,0,.54)
  .mat-mdc-paginator-page-size-select
    margin: 0px 4px 0 4px
    width: 56px !important
    .mat-mdc-text-field-wrapper
      width: 100% !important
    .mat-mdc-form-field-infix
      height: 37px
      padding: 3px 10px 10px 10px !important
      border: 1px solid
      border-color: rgba(0,0,0,.12)
      border-radius: 4px
      overflow: visible
      .mat-mdc-select-arrow
        display: block !important

  .mat-form-field-underline
    height: 0px !important
  .mat-mdc-paginator-range-actions
    padding-bottom: 0px
    .mat-mdc-paginator-range-label
      color: rgba(0,0,0,.54)
  .mat-mdc-paginator-navigation-previous, .mat-mdc-paginator-navigation-next
    height: 37px !important
    padding: 9px !important
    border: 1px solid !important
    border-color: rgba(0,0,0,.12) !important
    border-radius: 4px
    overflow: visible
  .mat-mdc-paginator-navigation-next
    margin-left: 2px
    color: #2800D2 !important
    fill: #2800D2 !important
  .mat-mdc-paginator-navigation-previous
    margin-left: 8px
    color: #2800D2 !important
    fill: #2800D2 !important
  .mat-mdc-paginator-icon
    padding-bottom: 5px !important


.mdc-data-table__cell
  overflow: visible !important

app-dashboard-pie-chart
  .mat-button-toggle-label-content
    line-height: 38px !important
.custome-padding
  .mat-mdc-form-field-infix
    padding-top: 6px !important
    padding-bottom: 10px !important
  .mat-form-field-label
      top: 20px !important
.custome-padding-select
  .mat-form-field-flex
    padding-bottom: 2px !important
.mdc-text-field--filled
  height: auto !important

.mat-mdc-form-field-infix
  padding: .4375em 0 !important
  min-height: 26px !important
  input
    top: 7px !important
    position: inherit !important

.mat-mdc-form-field-infix
  .mat-date-range-input-wrapper
    input
      top: -1px !important
      position: absolute !important

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control
  font-size: 13px !important

.mdc-line-ripple::before
  border-bottom-color: #d6d6d6 !important

.mat-mdc-form-field .mat-form-field-should-float
  padding: 10px !important
  box-sizing: border-box !important
.mdc-floating-label
  font-size: 13px !important
  font-family: work_sans_regular !important
  letter-spacing: normal !important
  padding: 0 !important
  top: 23px !important
.mdc-floating-label.mdc-floating-label--float-above
  top: 18px !important

.mdc-radio
  margin-right: 10px !important

.mat-mdc-radio-button .mdc-radio--disabled + label
  color: rgba(0,0,0,.38) !important

/* Style for mat-label */
.mat-mdc-form-field-label
  font-size: inherit
  font-weight: 400
  line-height: 1.125
  color: #000 !important
  -webkit-text-fill-color: #000 !important
  font-family: Roboto,Helvetica Neue,sans-serif !important

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label
  cursor: inherit
  font-size: 13px !important

//mat-slide-toggle

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow
  background-color: #2800d2

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after
  background-color: rgba(30,159,242,.5) !important

.mat-mdc-slide-toggle:not(.mat-mdc-slide-toggle-checked):not(.mat-disabled) .mdc-switch__shadow
  background-color: #ffffff

.mdc-switch__icons
  color:  #2800d2 !important

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon
  fill:  #2800d2 !important

.mdc-switch.mdc-switch--selected .mdc-switch__icon
  width: 0px !important
  height: 0px !important

.mdc-label
  user-select: none
  cursor: pointer
  display: inline-flex
  align-items: center
  white-space: nowrap
  vertical-align: middle
  width: 100%
  max-width: 100%
  margin-bottom: 0px !important
  font-size: 13px
  font-weight: 400
  line-height: 1.47


.mat-mdc-dialog-container .mdc-dialog__content
  color: var(--mdc-dialog-supporting-text-color, rgba(0, 0, 0, 0)) !important

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label
  color: #000 !important

.mat-mdc-unelevated-button > .mat-icon
  font-size: 24px !important
  margin-left: 0px !important
  margin-right: 5px !important


.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after
  border-bottom-color: #2800d2 !important


.country-selector
  opacity: 1 !important
  bottom: 8px !important
  width: 50px
  display: flex
  align-items: center
  justify-content: center
  border-right: 1px solid #ccc
  background-color: #f5f5f5
  border-radius: 4px 0 0 4px
  height: 100%
  position: absolute !important
  top: 30px !important
  left: -18px !important
  cursor: pointer


.mat-mdc-tooltip
  --mdc-plain-tooltip-supporting-text-size: 10px !important
  --mdc-plain-tooltip-supporting-text-weight: 400 !important
  --mdc-tooltip-word-break: normal !important
  background-color: #ccc !important
  color: #fff !important
  font-family: work_sans_regular !important

.mat-mdc-tooltip-text
  font-size: var(--mdc-plain-tooltip-supporting-text-size)
  font-weight: var(--mdc-plain-tooltip-supporting-text-weight)
  word-break: var(--mdc-tooltip-word-break)
  line-height: 1.4 !important

.mdc-tooltip__surface
  background: rgba(0, 0, 0, 0.9) !important
  border-radius: 4px !important
  color: white !important
  padding: 8px 8px !important
  opacity: 0.7 !important
  white-space: nowrap !important


.mat-mdc-select-arrow
  display: none !important

label
  font-size: 13px !important
  line-height: 19px !important
  font-family: work_sans_regular !important
  color: rgba(0,0,0,.87) !important

.mdc-line-ripple::before, .mdc-line-ripple::after
  border-bottom-style: solid !important


.highcharts-menu-item
  font-size: 11px !important
  font-family: work_sans_regular !important
  padding: 0.5em 1em !important
  color: #303030 !important


.highcharts-menu-item:hover
  background-color: rgb(51, 92, 173) !important /* Blue background on hover */
  color: rgb(255 , 255, 255) !important /* White text on hover */

.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple
  opacity: 0.9 !important
  background-color: #fff !important


.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple)
  background-color: transparent !important

.mdc-data-table__row:last-child .mat-mdc-cell
  border-bottom: 1px solid #ccc !important


.mat-mdc-cell
  line-height: initial !important

.mat-mdc-tooltip .mdc-tooltip__surface
  max-width: unset !important

.mdc-dialog__surface
  overflow-y: unset !important


/* Custom Scrollbar */
::-webkit-scrollbar
  width: 3px !important


::-webkit-scrollbar-track
  background: #f1f1f1 !important


::-webkit-scrollbar-thumb
  background-color: #888 !important
  border-radius: 3px !important

::-webkit-scrollbar-thumb:hover
  background: #555 !important

.mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox
  display: none !important
  