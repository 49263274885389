.d-stats h2
  font-family: 'work_sans_semibold'
  font-size: 18px
  font-weight: 600
  vertical-align: bottom

.d-stats p 
  font-size: 12px
  color: #080708
  text-transform: capitalize

