app-complaints-list
  popper-content

    div
      min-width: 40vw


      div.ngxp__inner.ng-star-inserted
        mat-card
          border: 1px solid #E0E0E0

          div
            mat-icon
              padding-left: 5px !important
              padding-right: 30px !important
              vertical-align: middle
              font-size: 20px

            .gd-span
              padding-left: 5px
              padding-right: 5px

app-complaints-detail
  .sidenav-map
    width: 100% !important

  .mat-slide-toggle-content
    font-family: inherit
    font-weight: normal

app-complaint-new, app-complaints-detail
  .mat-mdc-tab-header, .mat-mdc-tab-nav-bar
    border-bottom: 0px
