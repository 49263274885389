@import url("dialog-login.css");
@import url("dialog-sidebars.css");
@import url("dialog-document.css");
@import url("dialog-matrix.css");
@import url("dialog-modals.css");
@import url("dialog-charts.css");
@import url("dialog-table.css");
@import url("dialog-dashboard.css");
@import url("dialog-changelog.css");
@import url("dialog-dropzone.css");

/*sidebar navigation*/

body {
    color: #080708;
    background: #F2F3F8 !important;
    font-family: raleway;
    font-variant-numeric: lining-nums;
    -moz-font-feature-settings: 'lnum';
    -webkit-font-feature-settings: "lnum"1;
    -ms-font-feature-settings: "lnum"1;
    font-feature-settings: 'lnum'on;
}

.body-font-color {
    color: #36373A;
}

body .container.body .right_col {
    background: #F2F3F8 !important;
}

a {
    color: #1E9FF2;
}

h1 {
    margin-bottom: 20px;
}

@media (max-width: 991px) {
    h1 {
        font-size: 26px;
    }
}

h3 {
    margin-top: 15px;
    margin-bottom: 15px;
}

@media (max-width: 991px) {
    h5 {
        font-size: 12px;
    }
}

.full-width {
    width: 100% !important;
}

.width-80 {
    width: 80%;
}

.width-50 {
    width: 50%;
}

.text-sml {
    font-size: 11px;
    line-height: 12px;
}

.placeholder-color {
    color: gray;
}


/*material styling*/

.md-inactive {
    color: rgba(0, 0, 0, 0.26);
    cursor: not-allowed;
}

.z-depth-2 {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding-top: 15px;
    padding-bottom: 15px;
    margin-bottom: 25px;
}


/* Fix border on responsive table in Bootstrap */

.borderless {
    border: none;
}

.no-background {
    background-color: transparent;
}


/*tooltip position*/

.tooltip {
    z-index: 10000;
}

a.non-link,
a.no-style,
a.no-style:hover {
    color: #3c3c3c;
    text-decoration: none;
}

a.non-link:hover {
    color: #3c3c3c;
    cursor: default;
    text-decoration: none;
}


/*topbar icons*/

.top_nav li a i {
    font-size: 24px;
}

.toggle a i {
    color: #515356;
}


/* Sidebar nav-sm width to 100px for better UI */

.nav-sm .container.body .col-md-3.left_col {
    width: 100px;
}

.nav-sm .navbar.nav_title {
    width: 100px;
}

.nav-sm .nav.side-menu li a i {
    margin-bottom: 0px;
}

@media (min-width: 1200px) {
    .nav-sm .nav.side-menu li a .nav-link-icon {
        width: 100%;
    }
}


/* End of Sidebar nav-sm width to 100px for better UI */


/* Override Gentelella 70% width for client logo */

.top_nav .navbar-right {
    width: 55%;
}


/* End Override Gentelella 70% width for client logo */

.d-sidebar .left_col,
.d-sidebar.left_col,
.d-sidebar .nav_title,
.nav-sm ul.nav.child_menu,
.main_container {
    background: #3C8CAA;
    z-index: 1041 !important;
}


/* Mobile view */

@media (max-width: 991px) {
    /* Show the expanded menu instead of hidden */
    .nav-md .container.body .col-md-3.left_col {
        display: inherit;
        overflow: scroll;
        height: 100vh;
    }
    /* Hide the minimze menu on mobile view */
    .nav-sm .container.body .col-md-3.left_col {
        display: none;
    }
}


/* Disable body scroll when overlay sidebar is shown */

body[class=nav-md] {
    overflow: hidden;
}


/* Overrule Gentelella for fixed communication timeline header */

.nav-md .container.body .right_col {
    padding: 0px 20px;
    z-index: 2;
    -webkit-box-shadow: 1px 0px 20px #3c3c3c;
    -moz-box-shadow: 1px 0px 20px #3c3c3c;
    box-shadow: 1px 0px 20px #3c3c3c;
    overflow-y: scroll !important;
}


/* Fix margin-left when nav-md shows in mobile view */

.nav-md .container.body .col-md-3.left_col {
    -webkit-box-shadow: 1px 0px 20px #3c3c3c;
    -moz-box-shadow: 1px 0px 20px #3c3c3c;
    box-shadow: 1px 0px 20px #3c3c3c;
}

.nav.side-menu>li {
    font-size: 12px;
}

.nav.side-menu>li.active>a {
    background: rgba(255, 255, 255, .04) !important;
    text-shadow: none;
    box-shadow: none;
}

.nav.side-menu>li.active,
.nav.side-menu>li.current-page {
    border-right: 2px solid white;
    background: rgba(255, 255, 255, .04) !important;
}

.nav.side-menu>li>a {
    color: white;
    font-size: 13px;
}

.nav.child_menu>li>a {
    color: white;
    font-size: 14px;
}

.nav.side-menu>li>a:hover,
.nav.child_menu>li>a:hover {
    color: white !important;
    background: rgba(255, 255, 255, .09) !important;
}


/*sidebar group headers*/

.nav.side-menu>li.d-menu-section,
.nav.child_menu>li.d-menu-section {
    cursor: default;
}

.nav.side-menu>li.d-menu-section>a,
.nav.child_menu>li.d-menu-section>a {
    color: white;
    font-size: 11px;
    cursor: default;
}

.nav.side-menu>li.d-menu-section>a:hover,
.nav.child_menu>li.d-menu-section>a:hover,
.nav.side-menu>li.d-menu-section:hover,
.nav.side-menu>li.d-menu-section:hover {
    background: transparent !important;
}

.nav.side-menu>li:after,
.nav.child_menu>li:after,
.nav.side-menu>li:before,
.nav.child_menu>li:before {
    border: none !important;
    background: none !important;
}


/*Overrule Gentelella green border for active sidebar li */

.nav.side-menu>li.current-page,
.nav.side-menu>li.active {
    border: none !important;
}


/*top navigation*/

.nav_menu {
    background: #F2F3F8;
}


/*increased z index for fixed top nav bar oppose to gentelella*/

.top_nav {
    z-index: 1000 !important;
}


/*Width fix for daterangepicker by gentelella*/

.daterangepicker .calendar.single .calendar-table {
    width: 100% !important;
    padding: 10px !important;
}

.daterangepicker td {
    text-align: center !important;
}


/*common*/

.d-border-shadow {
    -moz-box-shadow: 0 0 4px #ccc;
    -webkit-box-shadow: 0 0 4px #ccc;
    box-shadow: 0 0 4px #ccc;
}

.breakword {
    word-break: break-all;
}

a.site_title>img {
    height: 60px;
}

a.site_title.menu_collapsed {
    display: none;
}

span.page-title {
    display: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
    color: darkgrey;
    background: inherit;
}


/*logo collapsed menu*/

.nav-sm a.site_title {
    padding-left: 0px;
    display: none;
}

.nav-sm a.site_title.menu_collapsed {
    display: block;
    margin-left: -5px !important;
}


/*overlay*/


/* The Overlay (background) */

.overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: auto;
    width: 0;
    position: absolute;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    top: 55px;
    background-color: rgb(249, 249, 249);
    /* Black fallback color */
    background-color: rgba(249, 249, 249, 1);
    /* Black w/opacity */
    overflow-x: hidden;
    /* Disable horizontal scroll */
    overflow-y: auto;
    transition: 0.5s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
}

.d-text-middle {
    vertical-align: middle;
}

.text-align-center {
    text-align: center;
}


/*cursors*/

.d-hr {
    margin-top: 0px;
}

.d-well {
    padding: 5px;
}

.d-chart {
    width: 100%;
    min-height: 250px;
}

.d-chart-lg {
    width: 100%;
    min-height: 500px;
}

.d-lh-normal {
    line-height: 1.471;
}


/*loader*/

.loader {
    border: 2px solid #f3f3f3;
    /* Light grey */
    border-top: 2px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 13px;
    height: 13px;
    animation: spin 2s linear infinite;
    display: inline-block;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


/* overwrite padding bootrstrap alerts*/

.d-error {
    color: #a94442;
}

@media (max-width: 576px) {
    .d-xs-mb15 {
        margin-bottom: 15px;
    }
}


/*donut chart*/

#trust-chart {
    width: 100%;
}

#trust-chart .legend {
    width: 100%;
    height: 30px;
}

#trust-chart .legend g {
    display: inline;
}

#trust-chart .center-txt.value {
    font-size: 30px;
}


/*bar graph*/

.highcharts-background,
.highcharts-credits {
    display: none;
}

.highcharts-axis-labels text {
    transform: none;
    font-family: 'raleway' !important;
    margin: 0 auto;
}

.highchart-tooltip ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.chart-filter {
    min-width: 250px;
    max-width: 250px;
    margin-left: -85px;
}


/* input group with google icons*/

.input-group-addon {
    padding: 0px 12px;
}


/*top nav bar*/

.d-topnavbar {
    background: white;
    margin: -20px -20px 0px -20px;
    padding: 5px;
    border-bottom: 1px solid #ddd;
}


/*tables */

.table-interests p,
.table-interests a {
    margin: 0px;
}

.table>tbody>tr>td {
    vertical-align: middle;
}


/*logs table*/

.table.table-log {
    z-index: 2;
}

.table.table-log>tbody>tr>td {
    border: none;
}

.log_type-timestamp {
    display: block;
    color: gray;
    font-size: 11px;
    margin-bottom: 0px;
}

.log_type-description {
    line-height: 20px;
    padding-top: 5px;
}

.log_icon-remove {
    margin-left: 20px;
}


/*logs docs*/

.log-docs-thumb {
    background-color: rgba(158, 158, 158, .2) !important;
    height: auto;
    width: auto;
    max-width: 50%;
    word-wrap: break-word;
    overflow: hidden;
    padding: 5px;
    margin: 5px;
    float: left;
    box-sizing: border-box;
    border-radius: 5px;
    vertical-align: middle;
    display: block;
}

@media screen and (max-width: 1570px) {
    .log-docs-thumb {
        max-width: 60%;
    }
}

@media screen and (max-width: 1259px) {
    .log-docs-thumb {
        max-width: 100%;
    }
}

.log-docs-thumb .log-docs-icon {
    font-size: 20px !important;
    vertical-align: middle;
}

.log-docs-thumb .log-docs-name {
    font-size: 10px;
    vertical-align: middle;
}

.log-docs-thumb .log-delete-doc {
    font-size: 16px !important;
    vertical-align: middle;
    padding-left: 10px;
}

.log-docs-thumb .log-docs-filesize {
    font-size: 12px;
    margin-top: 0px;
    color: gray;
    position: absolute;
    left: 10px;
    top: 18px;
}

.log-top-icon {
    border-right: 1px solid lightgray;
    padding-right: 5px;
}

.log-top-icon-container {
    border-bottom: 1px solid lightgray;
}

.log-top-links {
    padding-top: 5px;
}

.d-icon-badge {
    position: absolute;
    padding: 2px;
    margin-left: -20px;
    font-size: 8px;
    min-width: 15px;
}


/*popover used for checklists*/

.popover-big {
    width: 200%;
    font-family: inherit !important;
}

.popover-big {
    font-weight: 100;
}

.list-intable {
    list-style: none;
    padding-left: 0px;
}

.list-intable li>p {
    font-size: 11px;
    margin: 0px;
}


/*Dialog customize ui-select bootstrap theme matches*/

.ui-select-match-item {
    width: 100%;
    padding: 5px;
    text-align: left;
}

.ui-select-choices-row-inner {
    font-size: 14px;
}

.ui-select-multiple input.ui-select-search {
    width: 100% !important;
}


/*fix*/

.ui-select-bootstrap .ui-select-toggle>.caret {
    padding-right: 0px;
}

tr.clickable-row td {
    cursor: pointer !important;
}

li.list-group-item>i.material-icons {
    vertical-align: middle;
}


/*date-picker and slider map*/

.slider-container {
    padding: 20px 20px 0px 20px;
    margin-left: 0px;
    float: left;
}

.date-select-title {
    padding: 8px 10px 14px 0px;
    margin: 0;
    float: left;
}

.slider-time,
.slider-time2 {
    padding: 5px;
    background-color: #28ABE3;
    color: #fff;
    display: inline-block;
}

.date-select-row {
    margin-bottom: 20px;
}

@media screen and (max-width:800px) {
    .slider-container {
        width: 100%;
    }
}


/*graph complaints styling */

.graph-complaint-title-container {
    padding: 0px 0px 5px 35px;
}

.graph-new-complaints-thisweek,
.graph-open-complaints {
    padding: 0 5px 5px 5px;
    display: block;
    width: 50%;
    text-align: center;
}


/*top menu new item*/

#menu2 li a i {
    vertical-align: middle;
    padding-right: 5px;
}

.nav>li>a {
    padding: 13px 12px 12px !important;
}

.nav-sm .nav.side-menu li a {
    font-size: 10px !important;
    padding: 12px 0px 12px !important;
}


/*modal rapportage*/

.modal-down-rapportage {
    padding: 20px 50px 20px 50px;
}

.rapport_dropdown_button {
    border-radius: 3px !important;
    height: 36px !important;
    margin-left: 5px !important;
}


/* middel detail */

form[name=middelForm] .panel {
    padding-top: 30px;
}

.button-container {
    margin-top: 20px;
}


/*verzonden toggle button */


/* The switch - the box around the slider */

.switch {
    position: relative;
    display: inline-block;
    width: 130px;
    height: 34px;
}


/* Hide default HTML checkbox */

.switch input {
    display: none;
}


/* The slider */

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    color: #ccc;
    width: 125px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #089908;
    color: #fff;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(89px);
    -ms-transform: translateX(89px);
    transform: translateX(89px);
}


/* smaller switch */

.switch-small {
    position: relative;
    vertical-align: middle;
    display: inline-block;
    width: 30px !important;
    height: 17px;
    margin-left: 0px !important;
    margin-right: 10px;
}


/* Hide default HTML checkbox */

.switch-small input {
    display: none;
}


/* The slider */

.slider-small {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 30px !important;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider-small:before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px !important;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider-small {
    background-color: #EF7700;
}

input:focus+.slider-small {
    box-shadow: 0 0 1px #ccc;
}

input:checked+.slider-small:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
}


/* Rounded sliders */

.slider-small.round {
    border-radius: 17px;
}

.slider-small.round:before {
    border-radius: 50%;
}


/* end smaller switch */

.sent-txt {
    margin-left: 13px;
    margin-top: 7px;
    vertical-align: middle;
    height: 26px;
}

.to-be-sent-txt {
    margin-left: 35px;
    margin-top: 7px;
    vertical-align: middle;
    height: 26px;
    color: #ffffff;
}

input:checked+.slider div.to-be-sent-txt {
    display: none;
}

input:not(:checked)+.slider div.sent-txt {
    display: none;
}


/* Map UI */

.map-button-left {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;
}


/* Centered filter button container */

.map-buttoncontainer-center {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    padding: 0;
    text-align: center;
    margin: 0 auto;
    width: 300px;
}

.btn-map {
    opacity: 0.9;
}

.map-text {
    font-size: 14px !important;
}

.slider-container {
    min-width: 250px !important;
    margin-left: -80px !important;
    padding: 5px !important;
}


/* Override angular directive for full-screen map*/

.angular-openlayers-map:not(-moz-full-screen) {
    height: 100%;
}

.angular-openlayers-map:not(-webkit-full-screen) {
    height: 100%;
}

.angular-openlayers-map:not(full-screen) {
    height: 100%;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    background-color: #28ABE3;
    border-color: #28ABE3;
}

.edit-on-hover {
    display: none;
    z-index: 10;
    padding: 0;
    color: white;
}


/* No hover effect on mobile, so just show white edit button on active list item */

@media only screen and (max-width: 991px) {
    .edit-on-hover-li.active .edit-on-hover {
        display: block;
        z-index: 10;
        padding: 0;
        color: white;
    }
}


/* No hover effect on mobile, so just show grey edit button on none active list item */

@media only screen and (max-width: 991px) {
    .edit-on-hover-li .edit-on-hover {
        display: block;
        z-index: 10;
        padding: 0;
        color: grey;
    }
}

.edit-on-hover:hover .btn-link:focus,
.btn-link:hover {
    text-decoration: none;
}


/* Show white icon on active list items */

.edit-on-hover-li.active:hover .edit-on-hover {
    display: block;
    color: white;
}


/* Show grey icon on active list items */

.edit-on-hover-li:hover .edit-on-hover {
    display: block;
    color: grey;
}

.page-map_main-container {
    padding: 0 0 0 0 !important;
}

.decision-overview--warning-icon {
    margin-left: 18px;
}

:-webkit-full-screen .overview-height-chart {
    height: 90% !important
}

:-ms-fullscreen .overview-height-chart {
    height: 90% !important
}

:fullscreen .overview-height-chart {
    height: 90% !important
}

:-webkit-full-screen .display-flex-filters {
    top: 20px;
}

:-ms-fullscreen .display-flex-filters {
    top: 20px;
}

:fullscreen .display-flex-filters {
    top: 20px;
}

:-webkit-full-screen .show-loader-chart {
    display: block !important;
}

:-ms-fullscreen .show-loader-chart {
    display: block !important;
}

:fullscreen .show-loader-chart {
    display: block !important;
}