full-calendar.tinycalendar
  div.fc-header-toolbar
    margin-top: 47px
    margin-bottom: 0px
    div.fc-left
      h2
        font-family: 'work_sans_semibold'
        font-size: 18px
        font-weight: 600

    div.fc-right
      button
        border: 0px
        font-size: 10px
  .fc-view-container
    margin-right: 10px
    background-color: inherit
    box-shadow: none
    font-size: 10px
    div
      table
        thead
          tr
            td
              border: 0px
              div
                table
                  thead
                    tr
                      th.fc-day-header.fc-widget-header
                        vertical-align: middle
                        height: 30px
                        border: 0px
                        text-align: center
                      th.fc-day-header.fc-widget-header.fc-sat
                        background-color: inherit
                      th.fc-day-header.fc-widget-header.fc-sun
                        background-color: inherit
        tbody
          tr
            td
              border: 0px
              div
                div
                  div
                    div
                      table
                        tbody
                          tr
                            td
                              border: 0px
                            td.fc-sat
                              background-color: inherit
                            td.fc-sun
                              background-color: inherit
                  div.fc-widget-content
                    min-height: 3em
                    div.fc-content-skeleton
                      table
                        thead
                          tr
                            td
                              text-align: center
                              height: 46px
                              vertical-align: middle
                              span.fc-day-number
                                float: none
                              span.fc-day-number:hover
                                cursor: pointer
                                color: #2800D2
                                font-weight: bold
                        tbody
                          tr
                            td.fc-event-container
                              a.fc-event
                                display: none

    .fc-today
      background-color: inherit !important

    .fc-bgevent
      background-color: #d4d5d9 !important

full-calendar
  .fc-view-harness
    background-color: white
  .fc-scroller
    overflow-y: auto !important

  .app-calendar
    margin-top: 40px

  .fc-view-container
    background-color: white
    //box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)

    .fc-listMonth-view
      .fc-widget-header
        font-weight: bold
        padding: 5px
        .fc-list-heading-alt
          float: left
          margin-right: 4px

      tr.fc-list-item
        border: 1px
        td.fc-widget-content
          padding: 5px
          border: 0
          background-color: white !important

      .fc-list-item-time, .fc-list-item-marker
        display: none

  div.fc-view-container
    div
      div
        table
          tbody
            tr
              td
                a
                  cursor: pointer

  div.fc-view-container
    div
      table
        thead
          tr
            td
              div
                table
                  thead
                    tr

                      th.fc-day-header.fc-widget-header
                        height: 50px
                        span
                          padding-top: 15px
                          vertical-align: middle
                          display: inline-block

                      th.fc-today
                        span
                          background-color: #2800D2 !important
                          width: 32px !important
                          border-radius: 18px !important
                          padding: 2px !important
                          margin-top: 6px !important
                          line-height: 32px !important
                          color: white !important

                      th.fc-week-number.fc-widget-header
                        span
                          padding-top: 15px
                          vertical-align: middle
                          display: inline-block

        tbody
          tr
            td
              div
                div
                  div
                    div.fc-content-skeleton
                      table
                        thead
                          tr
                            td.fc-day-top.fc-today
                              text-align: center
                              span
                                color: #2800D2
                                font-weight: bold

                        tbody
                          tr
                            td
                              margin: 1px 0 1px 0

                              a:hover
                                background-color: #717D8B !important
                                border-color: #717D8B !important
                                div
                                  span
                                    cursor: pointer


    .fc-sat
      background-color: #fafafa
    .fc-sun
      background-color: #fafafa
    .fc-today
      background-color: white

    .fc-today
      .fc-day-number
        background-color: #2800D2 !important
        width: 18px !important
        height: 18px !important
        border-radius: 18px !important
        padding: 2px !important
        color: white !important

    a.event-middel-past
      background-color: #E0E1E3
      border-color: #E0E1E3
      color:#000000
    a.event-middel-future, a.event-middel-present
      background-color: #E0E1E3
      border-color: #E0E1E3
      color:#000000
    a.event-activity-past
      background-color: #2800D2
      color:#000000
    a.event-activity-present, a.event-activity-future
      background-color: #2800D2
      color:#000000

// Hide title at screen size
@media screen and (min-width: 360px) and (max-width: 760px)
  full-calendar
    div.fc-toolbar.fc-header-toolbar
      div.fc-center
        display: none


// Modal Create Event
.gd-switch-event
  min-height: 90px
  flex-direction: column
  justify-content: center
  align-items: center

@media screen and (max-width: 760px)
  .gd-switch-event
    min-height: 170px

.gd-create-event-switch
  padding: 10px 15px 0 15px
  color: #949494

.gd-create-event-switch:hover
  color: #2800D2 !important
.location-bouwapp
  background: url(../../../png/integrations/bouwappchat.png) left no-repeat, url(../../../png/integrations/home.png) 15px  no-repeat, url(../../../png/integrations/email.png) 30px no-repeat
  background-repeat: no-repeat
  background-size: contain
  padding-left: 47px
  display: block
.bouwapp
  background: url(../../../png/integrations/bouwappchat.png)
  background-repeat: no-repeat
  background-size: contain
  padding-left: 20px
  display: block
.location
  background: url(../../../png/integrations/home.png) left  no-repeat, url(../../../png/integrations/email.png) 17px  no-repeat
  background-repeat: no-repeat
  background-size: contain
  padding-left: 35px
  display: block
.tinycalendar
  .fc-view-harness
    background-color: transparent !important
full-calendar
  a
    color: #000000 !important
full-calendar.tinycalendar
  a
    font-size: 10px !important

full-calendar
  .fc-listMonth-view
    td
      border: 0
    .location-bouwapp
      background: url(../../../png/integrations/bouwappchat.png) left no-repeat, url(../../../png/integrations/home.png) 30px  no-repeat, url(../../../png/integrations/email.png) 60px no-repeat !important
      padding-left: 90px !important
      display: block ruby !important
      background-size: 24px !important
    .bouwapp
      background: url(../../../png/integrations/bouwappchat.png) left no-repeat !important
      padding-left: 30px !important
      display: block ruby !important
      background-size: 24px !important
    .location
      background: url(../../../png/integrations/home.png) left  no-repeat, url(../../../png/integrations/email.png) 30px  no-repeat !important
      padding-left: 68px !important
      display: block ruby !important
      background-size: 24px !important
  .fc-dayGridWeek-view
    .fc-daygrid-day-top
      a
        display: none
  .fc-button-group
    button.fc-listMonth-button
      border-top-right-radius: .25em !important
      border-bottom-right-radius: .25em !important
    button.fc-dayGridWeek-button
      border-top-right-radius: .25em !important
      border-bottom-right-radius: .25em !important
    button.fc--button
      display: none
  .fc-day-today
    background: transparent !important
    .fc-daygrid-day-number
      background-color: #2800D2 !important
      width: 25px !important
      height: 25px !important
      border-radius: 18px !important
      padding: 3px 4px !important
      color: #fff !important
      text-align: center
full-calendar.tinycalendar
  .fc-day-today
    background: transparent !important
    .fc-daygrid-day-number
      background-color: #2800D2 !important
      width: 21px !important
      height: 21px !important
      border-radius: 18px !important
      padding: 3px 5px !important
      color: #fff !important
      text-align: center
full-calendar.tinycalendar
  button.fc-next-button
    border: 0
  .fc-button-group
    button
      border: 0
    button.fc--button
      display: none
  th
    border: 0
  td
    border: 0
  table
    border: 0 !important
    tbody
      table
        tbody
            td
              .fc-daygrid-day-frame
                display: flex
                justify-content: center
              .fc-daygrid-day-bg
                .fc-event-title
                  display: none
              .fc-daygrid-day-top
                display: flex
                position: absolute
                top: 28%
                a.fc-daygrid-day-number
                  cursor: pointer
              .fc-daygrid-event-harness
                a.fc-event
                  display: none


full-calendar.dashboard-calender-set
  table
    border-collapse: separate
    border-spacing: 5px
    .fc-daygrid-day
      cursor: pointer
      border-style: none solid solid none
      border-radius: 10px
      background-color: #F6F6F6 !important
      .fc-daygrid-day-bg
        .fc-event-title
          display: none
    .fc-day-today
      background-color: #2800D2 !important
full-calendar.dashboard-calender-set
  .fc-header-toolbar
    .fc-toolbar-chunk
      .fc-toolbar-title
        text-transform: capitalize
        font-size: 14px
        position: absolute
        top: 22px
        left: 25px
      .fc-button-group
        button
          border: 0
          top: 16px
          right: 50px
      .fc-next-button
        border: 0
        top: 16px
        right: 20px
        position: absolute
  table
    border: 0 !important
    tbody
      th
        border: 0
      td
        border: 0
      table
        thead
          .fc-col-header-cell-cushion
            text-transform: capitalize
            font-size: 14px
          .fc-col-header-cell
            border: 0
        tbody
          td
            .fc-daygrid-day-top
              display: flex
              position: absolute
              top: 22%
              font-weight: bold !important
            .fc-daygrid-day-bg
              .fc-daygrid-bg-harness
                .location-bouwapp
                  padding-left: 0px
                .bouwapp
                  padding-left: 0px
                .location
                  padding-left: 0px
                .fc-bg-event
                  border-radius: 10px
                  opacity: 1 !important
                  cursor: pointer
                .event-middel-past
                  background-color: #D7D7D7 !important
                  border-color: #D7D7D7
                  color: #19283b !important
                  font-weight: bold !important
                .event-middel-future, .event-middel-present
                  background-color: #D7D7D7 !important
                  border-color: #D7D7D7
                  color: #19283b !important
                  font-weight: bold !important
                .event-activity-past
                  background-color: #D7D7D7 !important
                  border-color: #D7D7D7 !important
                  color: #FFFFFF !important
                  font-weight: bold !important
                .event-activity-present, .event-activity-future
                  background-color: #D7D7D7 !important
                  border-color: #D7D7D7 !important
                  color: #FFFFFF !important
                  font-weight: bold !important
full-calendar.dashboard-calender-set
  table
    tbody
      table
        tbody
          td
            .fc-daygrid-day-frame
              display: flex
              justify-content: center
              min-height: 46px
full-calendar.dashboard-maincalender-set
  table
    border: 0 !important
    tbody
      table
        thead
          .fc-col-header-cell-cushion
            text-transform: capitalize
            font-size: 14px
          .fc-col-header-cell
            border: 0
  .fc-header-toolbar
    .fc-toolbar-chunk
      button
        padding: 6px 20px !important
        text-transform: capitalize !important
      h2.fc-toolbar-title
        font-family: 'work_sans_semibold'
        font-size: 18px
        font-weight: 600
        text-transform: capitalize !important
        position: absolute
        left: 250px
        top: 5px


full-calendar.dashboard-maincalender-set
    .fc-daygrid-event-harness
      .label-icons
        background: url(../../../png/integrations/info.png) 5px no-repeat
        padding-left: 28px
        display: block ruby
        background-size: 16px

full-calendar.dashboard-maincalender-set
  table
    today
      .fc-scrollgrid-section
        th
          border: 0
      .fc-scrollgrid-section
        border: 0
full-calendar.dashboard-maincalender-set
  .fc-scrollgrid-section
    td
      border: 0
      .fc-scroller-harness
        td
          border: 1px solid #ddd
    th
      border: 0
