@charset "UTF-8";
@font-face {
  font-family: "work_sans_600";
  src: url("../../fonts/WorkSans-SemiBold.ttf");
}
@font-face {
  font-family: "work_sans_light";
  src: url("../../fonts/WorkSans-Light.ttf");
}
@font-face {
  font-family: "work_sans_semibold";
  src: url("../../fonts/WorkSans-SemiBold.ttf");
}
@font-face {
  font-family: "work_sans_regular";
  src: url("../../fonts/WorkSans-Regular.ttf");
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100.svg") format("svg");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-100i.svg") format("svg");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200.svg") format("svg");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-200i.svg") format("svg");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300-.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300-.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300.svg") format("svg");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-300i.svg") format("svg");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400.svg") format("svg");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-400i.svg") format("svg");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500.svg") format("svg");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-500i.svg") format("svg");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600.svg") format("svg");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-600i.svg") format("svg");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700.svg") format("svg");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-700i.svg") format("svg");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800.svg") format("svg");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-800i.svg") format("svg");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900.svg") format("svg");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "rawline";
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.eot");
  src: url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.eot?#iefix") format("embedded-opentype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.woff2") format("woff2"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.woff") format("woff"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.ttf") format("truetype"), url("https://cdn.rawgit.com/h-ibaldo/Raleway_Fixed_Numerals/master/font/rawline-900i.svg") format("svg");
  font-weight: 900;
  font-style: italic;
}
label.material-style-label {
  left: 0;
  font: inherit;
  pointer-events: none;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  transform-origin: 0 0;
  transition: transform 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), color 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), width 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

body {
  font-family: "work_sans_regular";
}

.ashgrey {
  color: #b4b4b5;
}

.link {
  color: #2800D2;
}

a {
  color: #2800D2;
}

.count-animated, .date {
  font-family: "work_sans_regular";
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

h1 {
  font-family: "work_sans_light";
  font-size: 32px;
  color: #2800D2;
}

h2 {
  font-family: "work_sans_semibold";
  font-size: 18px;
  font-weight: 600;
}

h3 {
  font-family: "work_sans_semibold";
  font-size: 15px;
  font-weight: 400;
}

h4 {
  font-family: "work_sans_regular";
  font-size: 15px;
}

h5 {
  font-family: "work_sans_semibold" !important;
  font-size: 14px !important;
  color: #2800D2 !important;
  font-weight: 600px !important;
}

h6 {
  font-family: "work_sans_semibold";
  font-size: 13px;
  font-weight: 600px;
}

@media only screen and (max-width: 576px) {
  h1 {
    font-size: 22px;
  }
}
/* Coolors Exported Palette - coolors.co*/
/* Color RGBA */
/* Text */
/* Background */
/* Fonts */
/** default white*/
.mt-0 {
  margin-top: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.mg-0 {
  margin: 0px !important;
}

.pd-0 {
  padding: 0px !important;
}

.mt-1 {
  margin-top: 1px !important;
}

.mr-1 {
  margin-right: 1px !important;
}

.mb-1 {
  margin-bottom: 1px !important;
}

.ml-1 {
  margin-left: 1px !important;
}

.pt-1 {
  padding-top: 1px !important;
}

.pr-1 {
  padding-right: 1px !important;
}

.pb-1 {
  padding-bottom: 1px !important;
}

.pl-1 {
  padding-left: 1px !important;
}

.mg-1 {
  margin: 1px !important;
}

.pd-1 {
  padding: 1px !important;
}

.mt-2 {
  margin-top: 2px !important;
}

.mr-2 {
  margin-right: 2px !important;
}

.mb-2 {
  margin-bottom: 2px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.pt-2 {
  padding-top: 2px !important;
}

.pr-2 {
  padding-right: 2px !important;
}

.pb-2 {
  padding-bottom: 2px !important;
}

.pl-2 {
  padding-left: 2px !important;
}

.mg-2 {
  margin: 2px !important;
}

.pd-2 {
  padding: 2px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mr-3 {
  margin-right: 3px !important;
}

.mb-3 {
  margin-bottom: 3px !important;
}

.ml-3 {
  margin-left: 3px !important;
}

.pt-3 {
  padding-top: 3px !important;
}

.pr-3 {
  padding-right: 3px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pl-3 {
  padding-left: 3px !important;
}

.mg-3 {
  margin: 3px !important;
}

.pd-3 {
  padding: 3px !important;
}

.mt-4 {
  margin-top: 4px !important;
}

.mr-4 {
  margin-right: 4px !important;
}

.mb-4 {
  margin-bottom: 4px !important;
}

.ml-4 {
  margin-left: 4px !important;
}

.pt-4 {
  padding-top: 4px !important;
}

.pr-4 {
  padding-right: 4px !important;
}

.pb-4 {
  padding-bottom: 4px !important;
}

.pl-4 {
  padding-left: 4px !important;
}

.mg-4 {
  margin: 4px !important;
}

.pd-4 {
  padding: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-5 {
  margin-right: 5px !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.ml-5 {
  margin-left: 5px !important;
}

.pt-5 {
  padding-top: 5px !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pb-5 {
  padding-bottom: 5px !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.mg-5 {
  margin: 5px !important;
}

.pd-5 {
  padding: 5px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mr-6 {
  margin-right: 6px !important;
}

.mb-6 {
  margin-bottom: 6px !important;
}

.ml-6 {
  margin-left: 6px !important;
}

.pt-6 {
  padding-top: 6px !important;
}

.pr-6 {
  padding-right: 6px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pl-6 {
  padding-left: 6px !important;
}

.mg-6 {
  margin: 6px !important;
}

.pd-6 {
  padding: 6px !important;
}

.mt-7 {
  margin-top: 7px !important;
}

.mr-7 {
  margin-right: 7px !important;
}

.mb-7 {
  margin-bottom: 7px !important;
}

.ml-7 {
  margin-left: 7px !important;
}

.pt-7 {
  padding-top: 7px !important;
}

.pr-7 {
  padding-right: 7px !important;
}

.pb-7 {
  padding-bottom: 7px !important;
}

.pl-7 {
  padding-left: 7px !important;
}

.mg-7 {
  margin: 7px !important;
}

.pd-7 {
  padding: 7px !important;
}

.mt-8 {
  margin-top: 8px !important;
}

.mr-8 {
  margin-right: 8px !important;
}

.mb-8 {
  margin-bottom: 8px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pr-8 {
  padding-right: 8px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.mg-8 {
  margin: 8px !important;
}

.pd-8 {
  padding: 8px !important;
}

.mt-9 {
  margin-top: 9px !important;
}

.mr-9 {
  margin-right: 9px !important;
}

.mb-9 {
  margin-bottom: 9px !important;
}

.ml-9 {
  margin-left: 9px !important;
}

.pt-9 {
  padding-top: 9px !important;
}

.pr-9 {
  padding-right: 9px !important;
}

.pb-9 {
  padding-bottom: 9px !important;
}

.pl-9 {
  padding-left: 9px !important;
}

.mg-9 {
  margin: 9px !important;
}

.pd-9 {
  padding: 9px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mr-10 {
  margin-right: 10px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.ml-10 {
  margin-left: 10px !important;
}

.pt-10 {
  padding-top: 10px !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.mg-10 {
  margin: 10px !important;
}

.pd-10 {
  padding: 10px !important;
}

.mt-11 {
  margin-top: 11px !important;
}

.mr-11 {
  margin-right: 11px !important;
}

.mb-11 {
  margin-bottom: 11px !important;
}

.ml-11 {
  margin-left: 11px !important;
}

.pt-11 {
  padding-top: 11px !important;
}

.pr-11 {
  padding-right: 11px !important;
}

.pb-11 {
  padding-bottom: 11px !important;
}

.pl-11 {
  padding-left: 11px !important;
}

.mg-11 {
  margin: 11px !important;
}

.pd-11 {
  padding: 11px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mr-12 {
  margin-right: 12px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.ml-12 {
  margin-left: 12px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.mg-12 {
  margin: 12px !important;
}

.pd-12 {
  padding: 12px !important;
}

.mt-13 {
  margin-top: 13px !important;
}

.mr-13 {
  margin-right: 13px !important;
}

.mb-13 {
  margin-bottom: 13px !important;
}

.ml-13 {
  margin-left: 13px !important;
}

.pt-13 {
  padding-top: 13px !important;
}

.pr-13 {
  padding-right: 13px !important;
}

.pb-13 {
  padding-bottom: 13px !important;
}

.pl-13 {
  padding-left: 13px !important;
}

.mg-13 {
  margin: 13px !important;
}

.pd-13 {
  padding: 13px !important;
}

.mt-14 {
  margin-top: 14px !important;
}

.mr-14 {
  margin-right: 14px !important;
}

.mb-14 {
  margin-bottom: 14px !important;
}

.ml-14 {
  margin-left: 14px !important;
}

.pt-14 {
  padding-top: 14px !important;
}

.pr-14 {
  padding-right: 14px !important;
}

.pb-14 {
  padding-bottom: 14px !important;
}

.pl-14 {
  padding-left: 14px !important;
}

.mg-14 {
  margin: 14px !important;
}

.pd-14 {
  padding: 14px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pr-15 {
  padding-right: 15px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pl-15 {
  padding-left: 15px !important;
}

.mg-15 {
  margin: 15px !important;
}

.pd-15 {
  padding: 15px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mr-16 {
  margin-right: 16px !important;
}

.mb-16 {
  margin-bottom: 16px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pr-16 {
  padding-right: 16px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pl-16 {
  padding-left: 16px !important;
}

.mg-16 {
  margin: 16px !important;
}

.pd-16 {
  padding: 16px !important;
}

.mt-17 {
  margin-top: 17px !important;
}

.mr-17 {
  margin-right: 17px !important;
}

.mb-17 {
  margin-bottom: 17px !important;
}

.ml-17 {
  margin-left: 17px !important;
}

.pt-17 {
  padding-top: 17px !important;
}

.pr-17 {
  padding-right: 17px !important;
}

.pb-17 {
  padding-bottom: 17px !important;
}

.pl-17 {
  padding-left: 17px !important;
}

.mg-17 {
  margin: 17px !important;
}

.pd-17 {
  padding: 17px !important;
}

.mt-18 {
  margin-top: 18px !important;
}

.mr-18 {
  margin-right: 18px !important;
}

.mb-18 {
  margin-bottom: 18px !important;
}

.ml-18 {
  margin-left: 18px !important;
}

.pt-18 {
  padding-top: 18px !important;
}

.pr-18 {
  padding-right: 18px !important;
}

.pb-18 {
  padding-bottom: 18px !important;
}

.pl-18 {
  padding-left: 18px !important;
}

.mg-18 {
  margin: 18px !important;
}

.pd-18 {
  padding: 18px !important;
}

.mt-19 {
  margin-top: 19px !important;
}

.mr-19 {
  margin-right: 19px !important;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.ml-19 {
  margin-left: 19px !important;
}

.pt-19 {
  padding-top: 19px !important;
}

.pr-19 {
  padding-right: 19px !important;
}

.pb-19 {
  padding-bottom: 19px !important;
}

.pl-19 {
  padding-left: 19px !important;
}

.mg-19 {
  margin: 19px !important;
}

.pd-19 {
  padding: 19px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mr-20 {
  margin-right: 20px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.ml-20 {
  margin-left: 20px !important;
}

.pt-20 {
  padding-top: 20px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.mg-20 {
  margin: 20px !important;
}

.pd-20 {
  padding: 20px !important;
}

.mt-21 {
  margin-top: 21px !important;
}

.mr-21 {
  margin-right: 21px !important;
}

.mb-21 {
  margin-bottom: 21px !important;
}

.ml-21 {
  margin-left: 21px !important;
}

.pt-21 {
  padding-top: 21px !important;
}

.pr-21 {
  padding-right: 21px !important;
}

.pb-21 {
  padding-bottom: 21px !important;
}

.pl-21 {
  padding-left: 21px !important;
}

.mg-21 {
  margin: 21px !important;
}

.pd-21 {
  padding: 21px !important;
}

.mt-22 {
  margin-top: 22px !important;
}

.mr-22 {
  margin-right: 22px !important;
}

.mb-22 {
  margin-bottom: 22px !important;
}

.ml-22 {
  margin-left: 22px !important;
}

.pt-22 {
  padding-top: 22px !important;
}

.pr-22 {
  padding-right: 22px !important;
}

.pb-22 {
  padding-bottom: 22px !important;
}

.pl-22 {
  padding-left: 22px !important;
}

.mg-22 {
  margin: 22px !important;
}

.pd-22 {
  padding: 22px !important;
}

.mt-23 {
  margin-top: 23px !important;
}

.mr-23 {
  margin-right: 23px !important;
}

.mb-23 {
  margin-bottom: 23px !important;
}

.ml-23 {
  margin-left: 23px !important;
}

.pt-23 {
  padding-top: 23px !important;
}

.pr-23 {
  padding-right: 23px !important;
}

.pb-23 {
  padding-bottom: 23px !important;
}

.pl-23 {
  padding-left: 23px !important;
}

.mg-23 {
  margin: 23px !important;
}

.pd-23 {
  padding: 23px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mr-24 {
  margin-right: 24px !important;
}

.mb-24 {
  margin-bottom: 24px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.pt-24 {
  padding-top: 24px !important;
}

.pr-24 {
  padding-right: 24px !important;
}

.pb-24 {
  padding-bottom: 24px !important;
}

.pl-24 {
  padding-left: 24px !important;
}

.mg-24 {
  margin: 24px !important;
}

.pd-24 {
  padding: 24px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.mg-25 {
  margin: 25px !important;
}

.pd-25 {
  padding: 25px !important;
}

.mt-26 {
  margin-top: 26px !important;
}

.mr-26 {
  margin-right: 26px !important;
}

.mb-26 {
  margin-bottom: 26px !important;
}

.ml-26 {
  margin-left: 26px !important;
}

.pt-26 {
  padding-top: 26px !important;
}

.pr-26 {
  padding-right: 26px !important;
}

.pb-26 {
  padding-bottom: 26px !important;
}

.pl-26 {
  padding-left: 26px !important;
}

.mg-26 {
  margin: 26px !important;
}

.pd-26 {
  padding: 26px !important;
}

.mt-27 {
  margin-top: 27px !important;
}

.mr-27 {
  margin-right: 27px !important;
}

.mb-27 {
  margin-bottom: 27px !important;
}

.ml-27 {
  margin-left: 27px !important;
}

.pt-27 {
  padding-top: 27px !important;
}

.pr-27 {
  padding-right: 27px !important;
}

.pb-27 {
  padding-bottom: 27px !important;
}

.pl-27 {
  padding-left: 27px !important;
}

.mg-27 {
  margin: 27px !important;
}

.pd-27 {
  padding: 27px !important;
}

.mt-28 {
  margin-top: 28px !important;
}

.mr-28 {
  margin-right: 28px !important;
}

.mb-28 {
  margin-bottom: 28px !important;
}

.ml-28 {
  margin-left: 28px !important;
}

.pt-28 {
  padding-top: 28px !important;
}

.pr-28 {
  padding-right: 28px !important;
}

.pb-28 {
  padding-bottom: 28px !important;
}

.pl-28 {
  padding-left: 28px !important;
}

.mg-28 {
  margin: 28px !important;
}

.pd-28 {
  padding: 28px !important;
}

.mt-29 {
  margin-top: 29px !important;
}

.mr-29 {
  margin-right: 29px !important;
}

.mb-29 {
  margin-bottom: 29px !important;
}

.ml-29 {
  margin-left: 29px !important;
}

.pt-29 {
  padding-top: 29px !important;
}

.pr-29 {
  padding-right: 29px !important;
}

.pb-29 {
  padding-bottom: 29px !important;
}

.pl-29 {
  padding-left: 29px !important;
}

.mg-29 {
  margin: 29px !important;
}

.pd-29 {
  padding: 29px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mr-30 {
  margin-right: 30px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-30 {
  margin-left: 30px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pr-30 {
  padding-right: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.pl-30 {
  padding-left: 30px !important;
}

.mg-30 {
  margin: 30px !important;
}

.pd-30 {
  padding: 30px !important;
}

.mt-31 {
  margin-top: 31px !important;
}

.mr-31 {
  margin-right: 31px !important;
}

.mb-31 {
  margin-bottom: 31px !important;
}

.ml-31 {
  margin-left: 31px !important;
}

.pt-31 {
  padding-top: 31px !important;
}

.pr-31 {
  padding-right: 31px !important;
}

.pb-31 {
  padding-bottom: 31px !important;
}

.pl-31 {
  padding-left: 31px !important;
}

.mg-31 {
  margin: 31px !important;
}

.pd-31 {
  padding: 31px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mr-32 {
  margin-right: 32px !important;
}

.mb-32 {
  margin-bottom: 32px !important;
}

.ml-32 {
  margin-left: 32px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.pr-32 {
  padding-right: 32px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pl-32 {
  padding-left: 32px !important;
}

.mg-32 {
  margin: 32px !important;
}

.pd-32 {
  padding: 32px !important;
}

.mt-33 {
  margin-top: 33px !important;
}

.mr-33 {
  margin-right: 33px !important;
}

.mb-33 {
  margin-bottom: 33px !important;
}

.ml-33 {
  margin-left: 33px !important;
}

.pt-33 {
  padding-top: 33px !important;
}

.pr-33 {
  padding-right: 33px !important;
}

.pb-33 {
  padding-bottom: 33px !important;
}

.pl-33 {
  padding-left: 33px !important;
}

.mg-33 {
  margin: 33px !important;
}

.pd-33 {
  padding: 33px !important;
}

.mt-34 {
  margin-top: 34px !important;
}

.mr-34 {
  margin-right: 34px !important;
}

.mb-34 {
  margin-bottom: 34px !important;
}

.ml-34 {
  margin-left: 34px !important;
}

.pt-34 {
  padding-top: 34px !important;
}

.pr-34 {
  padding-right: 34px !important;
}

.pb-34 {
  padding-bottom: 34px !important;
}

.pl-34 {
  padding-left: 34px !important;
}

.mg-34 {
  margin: 34px !important;
}

.pd-34 {
  padding: 34px !important;
}

.mt-35 {
  margin-top: 35px !important;
}

.mr-35 {
  margin-right: 35px !important;
}

.mb-35 {
  margin-bottom: 35px !important;
}

.ml-35 {
  margin-left: 35px !important;
}

.pt-35 {
  padding-top: 35px !important;
}

.pr-35 {
  padding-right: 35px !important;
}

.pb-35 {
  padding-bottom: 35px !important;
}

.pl-35 {
  padding-left: 35px !important;
}

.mg-35 {
  margin: 35px !important;
}

.pd-35 {
  padding: 35px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.mr-36 {
  margin-right: 36px !important;
}

.mb-36 {
  margin-bottom: 36px !important;
}

.ml-36 {
  margin-left: 36px !important;
}

.pt-36 {
  padding-top: 36px !important;
}

.pr-36 {
  padding-right: 36px !important;
}

.pb-36 {
  padding-bottom: 36px !important;
}

.pl-36 {
  padding-left: 36px !important;
}

.mg-36 {
  margin: 36px !important;
}

.pd-36 {
  padding: 36px !important;
}

.mt-37 {
  margin-top: 37px !important;
}

.mr-37 {
  margin-right: 37px !important;
}

.mb-37 {
  margin-bottom: 37px !important;
}

.ml-37 {
  margin-left: 37px !important;
}

.pt-37 {
  padding-top: 37px !important;
}

.pr-37 {
  padding-right: 37px !important;
}

.pb-37 {
  padding-bottom: 37px !important;
}

.pl-37 {
  padding-left: 37px !important;
}

.mg-37 {
  margin: 37px !important;
}

.pd-37 {
  padding: 37px !important;
}

.mt-38 {
  margin-top: 38px !important;
}

.mr-38 {
  margin-right: 38px !important;
}

.mb-38 {
  margin-bottom: 38px !important;
}

.ml-38 {
  margin-left: 38px !important;
}

.pt-38 {
  padding-top: 38px !important;
}

.pr-38 {
  padding-right: 38px !important;
}

.pb-38 {
  padding-bottom: 38px !important;
}

.pl-38 {
  padding-left: 38px !important;
}

.mg-38 {
  margin: 38px !important;
}

.pd-38 {
  padding: 38px !important;
}

.mt-39 {
  margin-top: 39px !important;
}

.mr-39 {
  margin-right: 39px !important;
}

.mb-39 {
  margin-bottom: 39px !important;
}

.ml-39 {
  margin-left: 39px !important;
}

.pt-39 {
  padding-top: 39px !important;
}

.pr-39 {
  padding-right: 39px !important;
}

.pb-39 {
  padding-bottom: 39px !important;
}

.pl-39 {
  padding-left: 39px !important;
}

.mg-39 {
  margin: 39px !important;
}

.pd-39 {
  padding: 39px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mr-40 {
  margin-right: 40px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pr-40 {
  padding-right: 40px !important;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pl-40 {
  padding-left: 40px !important;
}

.mg-40 {
  margin: 40px !important;
}

.pd-40 {
  padding: 40px !important;
}

.mt-41 {
  margin-top: 41px !important;
}

.mr-41 {
  margin-right: 41px !important;
}

.mb-41 {
  margin-bottom: 41px !important;
}

.ml-41 {
  margin-left: 41px !important;
}

.pt-41 {
  padding-top: 41px !important;
}

.pr-41 {
  padding-right: 41px !important;
}

.pb-41 {
  padding-bottom: 41px !important;
}

.pl-41 {
  padding-left: 41px !important;
}

.mg-41 {
  margin: 41px !important;
}

.pd-41 {
  padding: 41px !important;
}

.mt-42 {
  margin-top: 42px !important;
}

.mr-42 {
  margin-right: 42px !important;
}

.mb-42 {
  margin-bottom: 42px !important;
}

.ml-42 {
  margin-left: 42px !important;
}

.pt-42 {
  padding-top: 42px !important;
}

.pr-42 {
  padding-right: 42px !important;
}

.pb-42 {
  padding-bottom: 42px !important;
}

.pl-42 {
  padding-left: 42px !important;
}

.mg-42 {
  margin: 42px !important;
}

.pd-42 {
  padding: 42px !important;
}

.mt-43 {
  margin-top: 43px !important;
}

.mr-43 {
  margin-right: 43px !important;
}

.mb-43 {
  margin-bottom: 43px !important;
}

.ml-43 {
  margin-left: 43px !important;
}

.pt-43 {
  padding-top: 43px !important;
}

.pr-43 {
  padding-right: 43px !important;
}

.pb-43 {
  padding-bottom: 43px !important;
}

.pl-43 {
  padding-left: 43px !important;
}

.mg-43 {
  margin: 43px !important;
}

.pd-43 {
  padding: 43px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mr-44 {
  margin-right: 44px !important;
}

.mb-44 {
  margin-bottom: 44px !important;
}

.ml-44 {
  margin-left: 44px !important;
}

.pt-44 {
  padding-top: 44px !important;
}

.pr-44 {
  padding-right: 44px !important;
}

.pb-44 {
  padding-bottom: 44px !important;
}

.pl-44 {
  padding-left: 44px !important;
}

.mg-44 {
  margin: 44px !important;
}

.pd-44 {
  padding: 44px !important;
}

.mt-45 {
  margin-top: 45px !important;
}

.mr-45 {
  margin-right: 45px !important;
}

.mb-45 {
  margin-bottom: 45px !important;
}

.ml-45 {
  margin-left: 45px !important;
}

.pt-45 {
  padding-top: 45px !important;
}

.pr-45 {
  padding-right: 45px !important;
}

.pb-45 {
  padding-bottom: 45px !important;
}

.pl-45 {
  padding-left: 45px !important;
}

.mg-45 {
  margin: 45px !important;
}

.pd-45 {
  padding: 45px !important;
}

.mt-46 {
  margin-top: 46px !important;
}

.mr-46 {
  margin-right: 46px !important;
}

.mb-46 {
  margin-bottom: 46px !important;
}

.ml-46 {
  margin-left: 46px !important;
}

.pt-46 {
  padding-top: 46px !important;
}

.pr-46 {
  padding-right: 46px !important;
}

.pb-46 {
  padding-bottom: 46px !important;
}

.pl-46 {
  padding-left: 46px !important;
}

.mg-46 {
  margin: 46px !important;
}

.pd-46 {
  padding: 46px !important;
}

.mt-47 {
  margin-top: 47px !important;
}

.mr-47 {
  margin-right: 47px !important;
}

.mb-47 {
  margin-bottom: 47px !important;
}

.ml-47 {
  margin-left: 47px !important;
}

.pt-47 {
  padding-top: 47px !important;
}

.pr-47 {
  padding-right: 47px !important;
}

.pb-47 {
  padding-bottom: 47px !important;
}

.pl-47 {
  padding-left: 47px !important;
}

.mg-47 {
  margin: 47px !important;
}

.pd-47 {
  padding: 47px !important;
}

.mt-48 {
  margin-top: 48px !important;
}

.mr-48 {
  margin-right: 48px !important;
}

.mb-48 {
  margin-bottom: 48px !important;
}

.ml-48 {
  margin-left: 48px !important;
}

.pt-48 {
  padding-top: 48px !important;
}

.pr-48 {
  padding-right: 48px !important;
}

.pb-48 {
  padding-bottom: 48px !important;
}

.pl-48 {
  padding-left: 48px !important;
}

.mg-48 {
  margin: 48px !important;
}

.pd-48 {
  padding: 48px !important;
}

.mt-49 {
  margin-top: 49px !important;
}

.mr-49 {
  margin-right: 49px !important;
}

.mb-49 {
  margin-bottom: 49px !important;
}

.ml-49 {
  margin-left: 49px !important;
}

.pt-49 {
  padding-top: 49px !important;
}

.pr-49 {
  padding-right: 49px !important;
}

.pb-49 {
  padding-bottom: 49px !important;
}

.pl-49 {
  padding-left: 49px !important;
}

.mg-49 {
  margin: 49px !important;
}

.pd-49 {
  padding: 49px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mr-50 {
  margin-right: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.ml-50 {
  margin-left: 50px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pr-50 {
  padding-right: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pl-50 {
  padding-left: 50px !important;
}

.mg-50 {
  margin: 50px !important;
}

.pd-50 {
  padding: 50px !important;
}

.mt-51 {
  margin-top: 51px !important;
}

.mr-51 {
  margin-right: 51px !important;
}

.mb-51 {
  margin-bottom: 51px !important;
}

.ml-51 {
  margin-left: 51px !important;
}

.pt-51 {
  padding-top: 51px !important;
}

.pr-51 {
  padding-right: 51px !important;
}

.pb-51 {
  padding-bottom: 51px !important;
}

.pl-51 {
  padding-left: 51px !important;
}

.mg-51 {
  margin: 51px !important;
}

.pd-51 {
  padding: 51px !important;
}

.mt-52 {
  margin-top: 52px !important;
}

.mr-52 {
  margin-right: 52px !important;
}

.mb-52 {
  margin-bottom: 52px !important;
}

.ml-52 {
  margin-left: 52px !important;
}

.pt-52 {
  padding-top: 52px !important;
}

.pr-52 {
  padding-right: 52px !important;
}

.pb-52 {
  padding-bottom: 52px !important;
}

.pl-52 {
  padding-left: 52px !important;
}

.mg-52 {
  margin: 52px !important;
}

.pd-52 {
  padding: 52px !important;
}

.mt-53 {
  margin-top: 53px !important;
}

.mr-53 {
  margin-right: 53px !important;
}

.mb-53 {
  margin-bottom: 53px !important;
}

.ml-53 {
  margin-left: 53px !important;
}

.pt-53 {
  padding-top: 53px !important;
}

.pr-53 {
  padding-right: 53px !important;
}

.pb-53 {
  padding-bottom: 53px !important;
}

.pl-53 {
  padding-left: 53px !important;
}

.mg-53 {
  margin: 53px !important;
}

.pd-53 {
  padding: 53px !important;
}

.mt-54 {
  margin-top: 54px !important;
}

.mr-54 {
  margin-right: 54px !important;
}

.mb-54 {
  margin-bottom: 54px !important;
}

.ml-54 {
  margin-left: 54px !important;
}

.pt-54 {
  padding-top: 54px !important;
}

.pr-54 {
  padding-right: 54px !important;
}

.pb-54 {
  padding-bottom: 54px !important;
}

.pl-54 {
  padding-left: 54px !important;
}

.mg-54 {
  margin: 54px !important;
}

.pd-54 {
  padding: 54px !important;
}

.mt-55 {
  margin-top: 55px !important;
}

.mr-55 {
  margin-right: 55px !important;
}

.mb-55 {
  margin-bottom: 55px !important;
}

.ml-55 {
  margin-left: 55px !important;
}

.pt-55 {
  padding-top: 55px !important;
}

.pr-55 {
  padding-right: 55px !important;
}

.pb-55 {
  padding-bottom: 55px !important;
}

.pl-55 {
  padding-left: 55px !important;
}

.mg-55 {
  margin: 55px !important;
}

.pd-55 {
  padding: 55px !important;
}

.mt-56 {
  margin-top: 56px !important;
}

.mr-56 {
  margin-right: 56px !important;
}

.mb-56 {
  margin-bottom: 56px !important;
}

.ml-56 {
  margin-left: 56px !important;
}

.pt-56 {
  padding-top: 56px !important;
}

.pr-56 {
  padding-right: 56px !important;
}

.pb-56 {
  padding-bottom: 56px !important;
}

.pl-56 {
  padding-left: 56px !important;
}

.mg-56 {
  margin: 56px !important;
}

.pd-56 {
  padding: 56px !important;
}

.mt-57 {
  margin-top: 57px !important;
}

.mr-57 {
  margin-right: 57px !important;
}

.mb-57 {
  margin-bottom: 57px !important;
}

.ml-57 {
  margin-left: 57px !important;
}

.pt-57 {
  padding-top: 57px !important;
}

.pr-57 {
  padding-right: 57px !important;
}

.pb-57 {
  padding-bottom: 57px !important;
}

.pl-57 {
  padding-left: 57px !important;
}

.mg-57 {
  margin: 57px !important;
}

.pd-57 {
  padding: 57px !important;
}

.mt-58 {
  margin-top: 58px !important;
}

.mr-58 {
  margin-right: 58px !important;
}

.mb-58 {
  margin-bottom: 58px !important;
}

.ml-58 {
  margin-left: 58px !important;
}

.pt-58 {
  padding-top: 58px !important;
}

.pr-58 {
  padding-right: 58px !important;
}

.pb-58 {
  padding-bottom: 58px !important;
}

.pl-58 {
  padding-left: 58px !important;
}

.mg-58 {
  margin: 58px !important;
}

.pd-58 {
  padding: 58px !important;
}

.mt-59 {
  margin-top: 59px !important;
}

.mr-59 {
  margin-right: 59px !important;
}

.mb-59 {
  margin-bottom: 59px !important;
}

.ml-59 {
  margin-left: 59px !important;
}

.pt-59 {
  padding-top: 59px !important;
}

.pr-59 {
  padding-right: 59px !important;
}

.pb-59 {
  padding-bottom: 59px !important;
}

.pl-59 {
  padding-left: 59px !important;
}

.mg-59 {
  margin: 59px !important;
}

.pd-59 {
  padding: 59px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mr-60 {
  margin-right: 60px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.ml-60 {
  margin-left: 60px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pr-60 {
  padding-right: 60px !important;
}

.pb-60 {
  padding-bottom: 60px !important;
}

.pl-60 {
  padding-left: 60px !important;
}

.mg-60 {
  margin: 60px !important;
}

.pd-60 {
  padding: 60px !important;
}

.mt-61 {
  margin-top: 61px !important;
}

.mr-61 {
  margin-right: 61px !important;
}

.mb-61 {
  margin-bottom: 61px !important;
}

.ml-61 {
  margin-left: 61px !important;
}

.pt-61 {
  padding-top: 61px !important;
}

.pr-61 {
  padding-right: 61px !important;
}

.pb-61 {
  padding-bottom: 61px !important;
}

.pl-61 {
  padding-left: 61px !important;
}

.mg-61 {
  margin: 61px !important;
}

.pd-61 {
  padding: 61px !important;
}

.mt-62 {
  margin-top: 62px !important;
}

.mr-62 {
  margin-right: 62px !important;
}

.mb-62 {
  margin-bottom: 62px !important;
}

.ml-62 {
  margin-left: 62px !important;
}

.pt-62 {
  padding-top: 62px !important;
}

.pr-62 {
  padding-right: 62px !important;
}

.pb-62 {
  padding-bottom: 62px !important;
}

.pl-62 {
  padding-left: 62px !important;
}

.mg-62 {
  margin: 62px !important;
}

.pd-62 {
  padding: 62px !important;
}

.mt-63 {
  margin-top: 63px !important;
}

.mr-63 {
  margin-right: 63px !important;
}

.mb-63 {
  margin-bottom: 63px !important;
}

.ml-63 {
  margin-left: 63px !important;
}

.pt-63 {
  padding-top: 63px !important;
}

.pr-63 {
  padding-right: 63px !important;
}

.pb-63 {
  padding-bottom: 63px !important;
}

.pl-63 {
  padding-left: 63px !important;
}

.mg-63 {
  margin: 63px !important;
}

.pd-63 {
  padding: 63px !important;
}

.mt-64 {
  margin-top: 64px !important;
}

.mr-64 {
  margin-right: 64px !important;
}

.mb-64 {
  margin-bottom: 64px !important;
}

.ml-64 {
  margin-left: 64px !important;
}

.pt-64 {
  padding-top: 64px !important;
}

.pr-64 {
  padding-right: 64px !important;
}

.pb-64 {
  padding-bottom: 64px !important;
}

.pl-64 {
  padding-left: 64px !important;
}

.mg-64 {
  margin: 64px !important;
}

.pd-64 {
  padding: 64px !important;
}

.mt-65 {
  margin-top: 65px !important;
}

.mr-65 {
  margin-right: 65px !important;
}

.mb-65 {
  margin-bottom: 65px !important;
}

.ml-65 {
  margin-left: 65px !important;
}

.pt-65 {
  padding-top: 65px !important;
}

.pr-65 {
  padding-right: 65px !important;
}

.pb-65 {
  padding-bottom: 65px !important;
}

.pl-65 {
  padding-left: 65px !important;
}

.mg-65 {
  margin: 65px !important;
}

.pd-65 {
  padding: 65px !important;
}

.mt-66 {
  margin-top: 66px !important;
}

.mr-66 {
  margin-right: 66px !important;
}

.mb-66 {
  margin-bottom: 66px !important;
}

.ml-66 {
  margin-left: 66px !important;
}

.pt-66 {
  padding-top: 66px !important;
}

.pr-66 {
  padding-right: 66px !important;
}

.pb-66 {
  padding-bottom: 66px !important;
}

.pl-66 {
  padding-left: 66px !important;
}

.mg-66 {
  margin: 66px !important;
}

.pd-66 {
  padding: 66px !important;
}

.mt-67 {
  margin-top: 67px !important;
}

.mr-67 {
  margin-right: 67px !important;
}

.mb-67 {
  margin-bottom: 67px !important;
}

.ml-67 {
  margin-left: 67px !important;
}

.pt-67 {
  padding-top: 67px !important;
}

.pr-67 {
  padding-right: 67px !important;
}

.pb-67 {
  padding-bottom: 67px !important;
}

.pl-67 {
  padding-left: 67px !important;
}

.mg-67 {
  margin: 67px !important;
}

.pd-67 {
  padding: 67px !important;
}

.mt-68 {
  margin-top: 68px !important;
}

.mr-68 {
  margin-right: 68px !important;
}

.mb-68 {
  margin-bottom: 68px !important;
}

.ml-68 {
  margin-left: 68px !important;
}

.pt-68 {
  padding-top: 68px !important;
}

.pr-68 {
  padding-right: 68px !important;
}

.pb-68 {
  padding-bottom: 68px !important;
}

.pl-68 {
  padding-left: 68px !important;
}

.mg-68 {
  margin: 68px !important;
}

.pd-68 {
  padding: 68px !important;
}

.mt-69 {
  margin-top: 69px !important;
}

.mr-69 {
  margin-right: 69px !important;
}

.mb-69 {
  margin-bottom: 69px !important;
}

.ml-69 {
  margin-left: 69px !important;
}

.pt-69 {
  padding-top: 69px !important;
}

.pr-69 {
  padding-right: 69px !important;
}

.pb-69 {
  padding-bottom: 69px !important;
}

.pl-69 {
  padding-left: 69px !important;
}

.mg-69 {
  margin: 69px !important;
}

.pd-69 {
  padding: 69px !important;
}

.mt-70 {
  margin-top: 70px !important;
}

.mr-70 {
  margin-right: 70px !important;
}

.mb-70 {
  margin-bottom: 70px !important;
}

.ml-70 {
  margin-left: 70px !important;
}

.pt-70 {
  padding-top: 70px !important;
}

.pr-70 {
  padding-right: 70px !important;
}

.pb-70 {
  padding-bottom: 70px !important;
}

.pl-70 {
  padding-left: 70px !important;
}

.mg-70 {
  margin: 70px !important;
}

.pd-70 {
  padding: 70px !important;
}

.mt-71 {
  margin-top: 71px !important;
}

.mr-71 {
  margin-right: 71px !important;
}

.mb-71 {
  margin-bottom: 71px !important;
}

.ml-71 {
  margin-left: 71px !important;
}

.pt-71 {
  padding-top: 71px !important;
}

.pr-71 {
  padding-right: 71px !important;
}

.pb-71 {
  padding-bottom: 71px !important;
}

.pl-71 {
  padding-left: 71px !important;
}

.mg-71 {
  margin: 71px !important;
}

.pd-71 {
  padding: 71px !important;
}

.mt-72 {
  margin-top: 72px !important;
}

.mr-72 {
  margin-right: 72px !important;
}

.mb-72 {
  margin-bottom: 72px !important;
}

.ml-72 {
  margin-left: 72px !important;
}

.pt-72 {
  padding-top: 72px !important;
}

.pr-72 {
  padding-right: 72px !important;
}

.pb-72 {
  padding-bottom: 72px !important;
}

.pl-72 {
  padding-left: 72px !important;
}

.mg-72 {
  margin: 72px !important;
}

.pd-72 {
  padding: 72px !important;
}

.mt-73 {
  margin-top: 73px !important;
}

.mr-73 {
  margin-right: 73px !important;
}

.mb-73 {
  margin-bottom: 73px !important;
}

.ml-73 {
  margin-left: 73px !important;
}

.pt-73 {
  padding-top: 73px !important;
}

.pr-73 {
  padding-right: 73px !important;
}

.pb-73 {
  padding-bottom: 73px !important;
}

.pl-73 {
  padding-left: 73px !important;
}

.mg-73 {
  margin: 73px !important;
}

.pd-73 {
  padding: 73px !important;
}

.mt-74 {
  margin-top: 74px !important;
}

.mr-74 {
  margin-right: 74px !important;
}

.mb-74 {
  margin-bottom: 74px !important;
}

.ml-74 {
  margin-left: 74px !important;
}

.pt-74 {
  padding-top: 74px !important;
}

.pr-74 {
  padding-right: 74px !important;
}

.pb-74 {
  padding-bottom: 74px !important;
}

.pl-74 {
  padding-left: 74px !important;
}

.mg-74 {
  margin: 74px !important;
}

.pd-74 {
  padding: 74px !important;
}

.mt-75 {
  margin-top: 75px !important;
}

.mr-75 {
  margin-right: 75px !important;
}

.mb-75 {
  margin-bottom: 75px !important;
}

.ml-75 {
  margin-left: 75px !important;
}

.pt-75 {
  padding-top: 75px !important;
}

.pr-75 {
  padding-right: 75px !important;
}

.pb-75 {
  padding-bottom: 75px !important;
}

.pl-75 {
  padding-left: 75px !important;
}

.mg-75 {
  margin: 75px !important;
}

.pd-75 {
  padding: 75px !important;
}

.mt-76 {
  margin-top: 76px !important;
}

.mr-76 {
  margin-right: 76px !important;
}

.mb-76 {
  margin-bottom: 76px !important;
}

.ml-76 {
  margin-left: 76px !important;
}

.pt-76 {
  padding-top: 76px !important;
}

.pr-76 {
  padding-right: 76px !important;
}

.pb-76 {
  padding-bottom: 76px !important;
}

.pl-76 {
  padding-left: 76px !important;
}

.mg-76 {
  margin: 76px !important;
}

.pd-76 {
  padding: 76px !important;
}

.mt-77 {
  margin-top: 77px !important;
}

.mr-77 {
  margin-right: 77px !important;
}

.mb-77 {
  margin-bottom: 77px !important;
}

.ml-77 {
  margin-left: 77px !important;
}

.pt-77 {
  padding-top: 77px !important;
}

.pr-77 {
  padding-right: 77px !important;
}

.pb-77 {
  padding-bottom: 77px !important;
}

.pl-77 {
  padding-left: 77px !important;
}

.mg-77 {
  margin: 77px !important;
}

.pd-77 {
  padding: 77px !important;
}

.mt-78 {
  margin-top: 78px !important;
}

.mr-78 {
  margin-right: 78px !important;
}

.mb-78 {
  margin-bottom: 78px !important;
}

.ml-78 {
  margin-left: 78px !important;
}

.pt-78 {
  padding-top: 78px !important;
}

.pr-78 {
  padding-right: 78px !important;
}

.pb-78 {
  padding-bottom: 78px !important;
}

.pl-78 {
  padding-left: 78px !important;
}

.mg-78 {
  margin: 78px !important;
}

.pd-78 {
  padding: 78px !important;
}

.mt-79 {
  margin-top: 79px !important;
}

.mr-79 {
  margin-right: 79px !important;
}

.mb-79 {
  margin-bottom: 79px !important;
}

.ml-79 {
  margin-left: 79px !important;
}

.pt-79 {
  padding-top: 79px !important;
}

.pr-79 {
  padding-right: 79px !important;
}

.pb-79 {
  padding-bottom: 79px !important;
}

.pl-79 {
  padding-left: 79px !important;
}

.mg-79 {
  margin: 79px !important;
}

.pd-79 {
  padding: 79px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mr-80 {
  margin-right: 80px !important;
}

.mb-80 {
  margin-bottom: 80px !important;
}

.ml-80 {
  margin-left: 80px !important;
}

.pt-80 {
  padding-top: 80px !important;
}

.pr-80 {
  padding-right: 80px !important;
}

.pb-80 {
  padding-bottom: 80px !important;
}

.pl-80 {
  padding-left: 80px !important;
}

.mg-80 {
  margin: 80px !important;
}

.pd-80 {
  padding: 80px !important;
}

.mt-81 {
  margin-top: 81px !important;
}

.mr-81 {
  margin-right: 81px !important;
}

.mb-81 {
  margin-bottom: 81px !important;
}

.ml-81 {
  margin-left: 81px !important;
}

.pt-81 {
  padding-top: 81px !important;
}

.pr-81 {
  padding-right: 81px !important;
}

.pb-81 {
  padding-bottom: 81px !important;
}

.pl-81 {
  padding-left: 81px !important;
}

.mg-81 {
  margin: 81px !important;
}

.pd-81 {
  padding: 81px !important;
}

.mt-82 {
  margin-top: 82px !important;
}

.mr-82 {
  margin-right: 82px !important;
}

.mb-82 {
  margin-bottom: 82px !important;
}

.ml-82 {
  margin-left: 82px !important;
}

.pt-82 {
  padding-top: 82px !important;
}

.pr-82 {
  padding-right: 82px !important;
}

.pb-82 {
  padding-bottom: 82px !important;
}

.pl-82 {
  padding-left: 82px !important;
}

.mg-82 {
  margin: 82px !important;
}

.pd-82 {
  padding: 82px !important;
}

.mt-83 {
  margin-top: 83px !important;
}

.mr-83 {
  margin-right: 83px !important;
}

.mb-83 {
  margin-bottom: 83px !important;
}

.ml-83 {
  margin-left: 83px !important;
}

.pt-83 {
  padding-top: 83px !important;
}

.pr-83 {
  padding-right: 83px !important;
}

.pb-83 {
  padding-bottom: 83px !important;
}

.pl-83 {
  padding-left: 83px !important;
}

.mg-83 {
  margin: 83px !important;
}

.pd-83 {
  padding: 83px !important;
}

.mt-84 {
  margin-top: 84px !important;
}

.mr-84 {
  margin-right: 84px !important;
}

.mb-84 {
  margin-bottom: 84px !important;
}

.ml-84 {
  margin-left: 84px !important;
}

.pt-84 {
  padding-top: 84px !important;
}

.pr-84 {
  padding-right: 84px !important;
}

.pb-84 {
  padding-bottom: 84px !important;
}

.pl-84 {
  padding-left: 84px !important;
}

.mg-84 {
  margin: 84px !important;
}

.pd-84 {
  padding: 84px !important;
}

.mt-85 {
  margin-top: 85px !important;
}

.mr-85 {
  margin-right: 85px !important;
}

.mb-85 {
  margin-bottom: 85px !important;
}

.ml-85 {
  margin-left: 85px !important;
}

.pt-85 {
  padding-top: 85px !important;
}

.pr-85 {
  padding-right: 85px !important;
}

.pb-85 {
  padding-bottom: 85px !important;
}

.pl-85 {
  padding-left: 85px !important;
}

.mg-85 {
  margin: 85px !important;
}

.pd-85 {
  padding: 85px !important;
}

.mt-86 {
  margin-top: 86px !important;
}

.mr-86 {
  margin-right: 86px !important;
}

.mb-86 {
  margin-bottom: 86px !important;
}

.ml-86 {
  margin-left: 86px !important;
}

.pt-86 {
  padding-top: 86px !important;
}

.pr-86 {
  padding-right: 86px !important;
}

.pb-86 {
  padding-bottom: 86px !important;
}

.pl-86 {
  padding-left: 86px !important;
}

.mg-86 {
  margin: 86px !important;
}

.pd-86 {
  padding: 86px !important;
}

.mt-87 {
  margin-top: 87px !important;
}

.mr-87 {
  margin-right: 87px !important;
}

.mb-87 {
  margin-bottom: 87px !important;
}

.ml-87 {
  margin-left: 87px !important;
}

.pt-87 {
  padding-top: 87px !important;
}

.pr-87 {
  padding-right: 87px !important;
}

.pb-87 {
  padding-bottom: 87px !important;
}

.pl-87 {
  padding-left: 87px !important;
}

.mg-87 {
  margin: 87px !important;
}

.pd-87 {
  padding: 87px !important;
}

.mt-88 {
  margin-top: 88px !important;
}

.mr-88 {
  margin-right: 88px !important;
}

.mb-88 {
  margin-bottom: 88px !important;
}

.ml-88 {
  margin-left: 88px !important;
}

.pt-88 {
  padding-top: 88px !important;
}

.pr-88 {
  padding-right: 88px !important;
}

.pb-88 {
  padding-bottom: 88px !important;
}

.pl-88 {
  padding-left: 88px !important;
}

.mg-88 {
  margin: 88px !important;
}

.pd-88 {
  padding: 88px !important;
}

.mt-89 {
  margin-top: 89px !important;
}

.mr-89 {
  margin-right: 89px !important;
}

.mb-89 {
  margin-bottom: 89px !important;
}

.ml-89 {
  margin-left: 89px !important;
}

.pt-89 {
  padding-top: 89px !important;
}

.pr-89 {
  padding-right: 89px !important;
}

.pb-89 {
  padding-bottom: 89px !important;
}

.pl-89 {
  padding-left: 89px !important;
}

.mg-89 {
  margin: 89px !important;
}

.pd-89 {
  padding: 89px !important;
}

.mt-90 {
  margin-top: 90px !important;
}

.mr-90 {
  margin-right: 90px !important;
}

.mb-90 {
  margin-bottom: 90px !important;
}

.ml-90 {
  margin-left: 90px !important;
}

.pt-90 {
  padding-top: 90px !important;
}

.pr-90 {
  padding-right: 90px !important;
}

.pb-90 {
  padding-bottom: 90px !important;
}

.pl-90 {
  padding-left: 90px !important;
}

.mg-90 {
  margin: 90px !important;
}

.pd-90 {
  padding: 90px !important;
}

.mt-91 {
  margin-top: 91px !important;
}

.mr-91 {
  margin-right: 91px !important;
}

.mb-91 {
  margin-bottom: 91px !important;
}

.ml-91 {
  margin-left: 91px !important;
}

.pt-91 {
  padding-top: 91px !important;
}

.pr-91 {
  padding-right: 91px !important;
}

.pb-91 {
  padding-bottom: 91px !important;
}

.pl-91 {
  padding-left: 91px !important;
}

.mg-91 {
  margin: 91px !important;
}

.pd-91 {
  padding: 91px !important;
}

.mt-92 {
  margin-top: 92px !important;
}

.mr-92 {
  margin-right: 92px !important;
}

.mb-92 {
  margin-bottom: 92px !important;
}

.ml-92 {
  margin-left: 92px !important;
}

.pt-92 {
  padding-top: 92px !important;
}

.pr-92 {
  padding-right: 92px !important;
}

.pb-92 {
  padding-bottom: 92px !important;
}

.pl-92 {
  padding-left: 92px !important;
}

.mg-92 {
  margin: 92px !important;
}

.pd-92 {
  padding: 92px !important;
}

.mt-93 {
  margin-top: 93px !important;
}

.mr-93 {
  margin-right: 93px !important;
}

.mb-93 {
  margin-bottom: 93px !important;
}

.ml-93 {
  margin-left: 93px !important;
}

.pt-93 {
  padding-top: 93px !important;
}

.pr-93 {
  padding-right: 93px !important;
}

.pb-93 {
  padding-bottom: 93px !important;
}

.pl-93 {
  padding-left: 93px !important;
}

.mg-93 {
  margin: 93px !important;
}

.pd-93 {
  padding: 93px !important;
}

.mt-94 {
  margin-top: 94px !important;
}

.mr-94 {
  margin-right: 94px !important;
}

.mb-94 {
  margin-bottom: 94px !important;
}

.ml-94 {
  margin-left: 94px !important;
}

.pt-94 {
  padding-top: 94px !important;
}

.pr-94 {
  padding-right: 94px !important;
}

.pb-94 {
  padding-bottom: 94px !important;
}

.pl-94 {
  padding-left: 94px !important;
}

.mg-94 {
  margin: 94px !important;
}

.pd-94 {
  padding: 94px !important;
}

.mt-95 {
  margin-top: 95px !important;
}

.mr-95 {
  margin-right: 95px !important;
}

.mb-95 {
  margin-bottom: 95px !important;
}

.ml-95 {
  margin-left: 95px !important;
}

.pt-95 {
  padding-top: 95px !important;
}

.pr-95 {
  padding-right: 95px !important;
}

.pb-95 {
  padding-bottom: 95px !important;
}

.pl-95 {
  padding-left: 95px !important;
}

.mg-95 {
  margin: 95px !important;
}

.pd-95 {
  padding: 95px !important;
}

.mt-96 {
  margin-top: 96px !important;
}

.mr-96 {
  margin-right: 96px !important;
}

.mb-96 {
  margin-bottom: 96px !important;
}

.ml-96 {
  margin-left: 96px !important;
}

.pt-96 {
  padding-top: 96px !important;
}

.pr-96 {
  padding-right: 96px !important;
}

.pb-96 {
  padding-bottom: 96px !important;
}

.pl-96 {
  padding-left: 96px !important;
}

.mg-96 {
  margin: 96px !important;
}

.pd-96 {
  padding: 96px !important;
}

.mt-97 {
  margin-top: 97px !important;
}

.mr-97 {
  margin-right: 97px !important;
}

.mb-97 {
  margin-bottom: 97px !important;
}

.ml-97 {
  margin-left: 97px !important;
}

.pt-97 {
  padding-top: 97px !important;
}

.pr-97 {
  padding-right: 97px !important;
}

.pb-97 {
  padding-bottom: 97px !important;
}

.pl-97 {
  padding-left: 97px !important;
}

.mg-97 {
  margin: 97px !important;
}

.pd-97 {
  padding: 97px !important;
}

.mt-98 {
  margin-top: 98px !important;
}

.mr-98 {
  margin-right: 98px !important;
}

.mb-98 {
  margin-bottom: 98px !important;
}

.ml-98 {
  margin-left: 98px !important;
}

.pt-98 {
  padding-top: 98px !important;
}

.pr-98 {
  padding-right: 98px !important;
}

.pb-98 {
  padding-bottom: 98px !important;
}

.pl-98 {
  padding-left: 98px !important;
}

.mg-98 {
  margin: 98px !important;
}

.pd-98 {
  padding: 98px !important;
}

.mt-99 {
  margin-top: 99px !important;
}

.mr-99 {
  margin-right: 99px !important;
}

.mb-99 {
  margin-bottom: 99px !important;
}

.ml-99 {
  margin-left: 99px !important;
}

.pt-99 {
  padding-top: 99px !important;
}

.pr-99 {
  padding-right: 99px !important;
}

.pb-99 {
  padding-bottom: 99px !important;
}

.pl-99 {
  padding-left: 99px !important;
}

.mg-99 {
  margin: 99px !important;
}

.pd-99 {
  padding: 99px !important;
}

.mt-100 {
  margin-top: 100px !important;
}

.mr-100 {
  margin-right: 100px !important;
}

.mb-100 {
  margin-bottom: 100px !important;
}

.ml-100 {
  margin-left: 100px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pr-100 {
  padding-right: 100px !important;
}

.pb-100 {
  padding-bottom: 100px !important;
}

.pl-100 {
  padding-left: 100px !important;
}

.mg-100 {
  margin: 100px !important;
}

.pd-100 {
  padding: 100px !important;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-right {
  padding-right: 0px !important;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.overflow-hidden {
  overflow: hidden;
}

.initial-cursor {
  cursor: initial;
}

.d-cursor {
  cursor: default !important;
}

.d-cursor-hand {
  cursor: pointer !important;
}

.d-cursor-text {
  cursor: text !important;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.gd-auto-horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.gd-auto-vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.gd-klantwensen-modal-header {
  min-height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: space-between;
  background-color: gray;
}

.gd-klantwensen {
  min-height: 500px;
}
.gd-klantwensen .mat-dialog-content {
  min-height: 300px;
}
.gd-klantwensen .mat-dialog-content .mat-mdc-input-element {
  max-width: 100vw;
}

/** width */
.gd-horizontal {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/** height */
.gd-vertical {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stakeholder-log {
  margin-top: 12px !important;
}

.stakeholder-log_search--input {
  margin-bottom: 8px;
}

.stakeholder-log_stakeholder--name {
  font-weight: bold !important;
  text-overflow: ellipsis;
  line-height: 30px;
  max-height: 30px;
  overflow: hidden;
}

.stakeholder-log_timestamp {
  color: gray !important;
  font-size: 10px !important;
}

.stakeholder-log_pagination {
  margin-bottom: 5px;
}

.stakeholder-log_notice {
  margin-bottom: 30px;
}

.stakeholder-log-stakeholders_select {
  margin-bottom: 30px;
}

mat-list-option {
  height: 73px !important;
}

::ng-deep .mat-pseudo-checkbox-checked {
  background: #2800D2 !important;
}

.font {
  font-family: "work_sans_regular";
  font-size: 13px !important;
}

.fontbold {
  font-family: "work_sans_semibold";
  font-size: 13px !important;
}

.modal-newlog-stakeholders_multiselect {
  width: 100%;
}

.new-stakeholder-info {
  position: absolute;
  top: -18px;
  left: 112px;
}

.modal-dialog .stakeholder-log {
  margin-top: 12px !important;
}
.modal-dialog .stakeholder-log_search--input {
  margin-bottom: 8px;
}
.modal-dialog .stakeholder-log_stakeholder--name {
  font-weight: bold !important;
  text-overflow: ellipsis;
  line-height: 30px;
  max-height: 30px;
  overflow: hidden;
}
.modal-dialog .stakeholder-log_timestamp {
  color: gray !important;
  font-size: 10px !important;
}
.modal-dialog .stakeholder-log_notice {
  margin-bottom: 30px;
}
.modal-dialog .stakeholder-log-stakeholders_select {
  margin-bottom: 30px;
}
.modal-dialog mat-list-option {
  height: 73px !important;
}
.modal-dialog ::ng-deep .mat-pseudo-checkbox-checked {
  background: #2800D2 !important;
}
.modal-dialog .font {
  font-family: "work_sans_regular";
  font-size: 13px !important;
}
.modal-dialog .fontbold {
  font-family: "work_sans_semibold";
  font-size: 13px !important;
}

.gd-modal-report div button span#gd-modal-report--close {
  font-size: 35px;
}
.gd-modal-report form mat-dialog-content mat-mdc-form-field input#gd-logo-path {
  margin-left: 15px;
}
.gd-modal-report form mat-dialog-content img {
  width: 300px;
}
.gd-modal-report mat-dialog-actions button {
  margin-left: 13px;
}

.sidemodal {
  width: 75vw !important;
}

@media only screen and (max-width: 960px) {
  .sidemodal {
    width: 90vw !important;
  }
}
.gd-modal-create .gd-input-validation {
  margin-top: -18px;
}
.gd-modal-create .gd-input-validation .gd-validation-color {
  color: #EA6153;
}
.gd-modal-create .panel-default {
  margin-top: 3vh;
}
.gd-modal-create .panel-default .gd-label {
  font-size: 12px;
  color: black;
}
.gd-modal-create .panel-default .gd-chips-container {
  display: inline;
}
.gd-modal-create .panel-default .gd-chips-container .gd-chips {
  margin: 5px;
  padding: 5px;
}

.gd-full-screen-modal .mat-mdc-dialog-container {
  max-width: none;
}

.popup-container .mat-icon.delete {
  color: gray;
}
.popup-container .popup-body {
  color: inherit;
  overflow: auto;
  height: calc(100vh - 116px);
  margin-bottom: 0;
  margin-top: 3px;
}
.popup-container .z-depth-2 {
  box-shadow: none;
}

.popup-header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.popup-header.scrolled {
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.12);
}

.popup-header h1 {
  display: inline;
  font-size: 25px;
}
.popup-header .close {
  padding-top: 4px;
}
.popup-header .delete {
  padding-top: 4px;
}

.panel-border {
  border-radius: 20px;
  border: 1px solid #D6D6D6;
  box-shadow: none;
  padding: 10px;
}

@media (max-width: 767px) {
  .popup-header-title h1 strong {
    display: block;
  }
}
.modal-backdrop {
  z-index: 0;
}

.popup-header .delete {
  margin-right: 14px;
}

.mdc-dialog .mdc-dialog__content {
  margin: 0 -24px !important;
  padding: 0 24px !important;
}

.mdc-dialog__surface {
  overflow: unset !important;
}

.gd-navbar {
  background-color: #F2F3F8;
  border-bottom: none;
  /** Navbar Collapse */
}
.gd-navbar .gd-navbar-top {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: #F2F3F8;
  border-bottom: none;
}
.gd-navbar .gd-navbar-top .pull-right {
  padding-right: 0px !important;
  margin-right: 0px !important;
}
.gd-navbar .gd-navbar-top .pull-right .personalized-logo {
  margin-top: 15px !important;
  margin-right: 15px !important;
}
.gd-navbar .gd-navbar-top .pull-right i {
  margin-top: 7px;
}
.gd-navbar .gd-navbar-top .pull-right ul li:hover a:hover {
  background-color: transparent;
}
.gd-navbar .gd-navbar-top .gd-navbar-center {
  background-color: transparent;
}
.gd-navbar .gd-navbar-top .gd-navbar-left {
  display: flex;
}
.gd-navbar .gd-navbar-top .gd-navbar-right {
  background-color: transparent;
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: flex-end;
}
.gd-navbar .gd-navbar-top .gd-navbar-right ul li a {
  background-color: transparent;
}
.gd-navbar .gd-navbar-top .gd-navbar-right ul li:hover a:hover {
  background-color: transparent;
}
.gd-navbar .gd-collapse {
  overflow: scroll;
  overflow-x: hidden;
}
.gd-navbar .gd-collapse .gd-notification {
  font-size: 12px;
}
.gd-navbar .gd-collapse .gd-notification .gd-notification-content {
  padding: 0px 20px 0px 20px;
}
.gd-navbar .gd-collapse .gd-notification .gd-notification-content span {
  display: block;
}
.gd-navbar .gd-collapse span,
.gd-navbar .gd-collapse i,
.gd-navbar .gd-collapse .gd-label {
  padding-left: 20px;
  padding-right: 20px;
}
.gd-navbar ::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
.gd-navbar ::-webkit-scrollbar-thumb {
  background: #FF0000;
}

@media (min-width: 200px) and (max-width: 767px) {
  .gd-navbar .gd-navbar-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-navbar .pull-right {
    padding-right: 0px !important;
    margin-right: 0px !important;
  }
  .gd-navbar .gd-navbar-left {
    display: flex;
    width: 5vw;
    flex-direction: row;
    justify-content: flex-start;
  }
  .gd-navbar .gd-navbar-left .personalized-logo {
    margin-top: 25px !important;
    margin-left: 20px !important;
    margin-right: 15px !important;
  }
  .gd-navbar .gd-navbar-center .form-control {
    width: 60vw !important;
    margin-left: 10vw;
  }
  .gd-navbar form {
    border-top: none !important;
    border-bottom: none !important;
  }
  .gd-navbar .gd-desktop-screen {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gd-navbar .gd-navbar-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-phone-screen {
    display: none;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .gd-navbar .gd-navbar-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-phone-screen {
    display: none;
  }
}
@media (min-width: 1200px) {
  .gd-navbar .gd-navbar-top {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-navbar .gd-navbar-left {
    display: flex;
    width: 15vw;
    flex: 1;
    flex-direction: row;
    justify-content: flex-start;
  }
  .gd-navbar .gd-navbar-left .navbar-nav > li > a:hover {
    background-color: transparent;
  }
  .gd-navbar .gd-navbar-center .form-control {
    margin-top: 0px !important;
  }
  .gd-phone-screen {
    display: none;
  }
}
.nav.navbar-nav > li > a {
  color: #434344 !important;
}

@media only screen and (max-width: 1000px) {
  .gd-navbar .gd-navbar-center .form-control {
    width: 160px;
  }
  .selector-dropdown {
    min-width: 170px !important;
  }
}
@media only screen and (max-width: 960px) {
  .gd-navbar .gd-navbar-center .form-control {
    width: 80px;
  }
  .selector-dropdown {
    min-width: 170px !important;
  }
}
@media only screen and (max-width: 767px) {
  .personalized-logo {
    height: 35px;
    width: 200px;
    margin-top: 11px;
    margin-right: 15px !important;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
  }
}
@media only screen and (min-width: 768px) {
  .personalized-logo {
    height: 35px;
    width: 200px;
    margin-top: 11px;
    margin-right: 15px !important;
    background-repeat: no-repeat;
    background-position: center left;
    background-size: contain;
  }
  .dropdown-menu {
    font-size: 13px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1450px) {
  .custom-width-768 {
    width: 3vw !important;
  }
}
.gd-search-form {
  position: relative;
  display: inline-block;
}
.gd-search-form .fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
  color: rgb(30, 159, 242);
  padding-top: 1px !important;
}
@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.gd-search-form input {
  border: solid 1px #E0E1E3 !important;
  border-radius: 5px;
}
.gd-search-form span {
  padding-top: 3px !important;
}
.gd-search-form .gd-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
}
.gd-search-form .gd-search-content {
  display: none;
  position: absolute;
  z-index: 1;
  max-height: 90vh !important;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
}
.gd-search-form .gd-search-content {
  z-index: 102 !important;
  display: block;
  border-bottom: 1px solid #e6e6e6;
}
.gd-search-form .gd-search-content .gd-search-panel {
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-header {
  border-top: 1px solid #e6e6e6;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-header div {
  padding: 0 5px 0 5px;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-header a {
  display: inline-block !important;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-header a a:hover {
  color: rgb(30, 159, 242);
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-header a span {
  padding-top: 3px;
  display: inline-block !important;
  vertical-align: middle !important;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section .mat-icon {
  color: #1e2223;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section div {
  padding: 0 10px 0 5px;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section i,
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section p {
  color: #1e2223;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section:hover {
  background-color: #f2f2f2;
}
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section:hover i,
.gd-search-form .gd-search-content .gd-search-panel .gd-search-section:hover p {
  color: rgb(30, 159, 242);
}
.gd-search-form .gd-search-content .gd-search-panel .gd-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(30vw - 70px);
  font-weight: bold;
}
.gd-search-form i {
  background: #e5e5e5;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 40px;
  padding-left: 5px;
  padding-top: 6px;
}
.gd-search-form .gd-flex {
  min-height: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.gd-search-form .panel {
  margin: 0 !important;
  padding: 0 !important;
}
.gd-search-form .panel i:active {
  transform: rotate(180deg);
}
.gd-search-form .panel ul {
  list-style-type: none;
}
.gd-search-form .panel ul li:hover {
  background-color: #e5e5e5;
}
.gd-search-form .gd-title-container {
  color: #d9d9d9;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.gd-search-form p {
  font-size: 1em;
  padding: 0 !important;
  margin: 0 !important;
  color: #353d3e;
}

@media (min-width: 200px) and (max-width: 767px) {
  .gd-search-form {
    position: relative;
    display: inline-block;
  }
  .gd-search-form .gd-search-content {
    display: none;
    position: absolute;
    z-index: 1;
    max-height: 90vh !important;
  }
  .gd-search-form .gd-search-content {
    display: block;
  }
  .gd-search-form .gd-search-content .gd-search-panel {
    width: 60vw !important;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header {
    width: 60vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section {
    width: 60vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gd-search-form {
    position: relative;
    display: inline-block;
  }
  .gd-search-form .gd-search-content {
    display: none;
    position: absolute;
    z-index: 1;
    max-height: 90vh !important;
  }
  .gd-search-form .gd-search-content {
    display: block;
  }
  .gd-search-form .gd-search-content .gd-search-panel {
    width: 35vw !important;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header {
    width: 35vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section {
    width: 35vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .gd-search-form {
    position: relative;
    display: inline-block;
  }
  .gd-search-form .gd-search-content {
    display: none;
    position: absolute;
    z-index: 1;
    max-height: 90vh !important;
  }
  .gd-search-form .gd-search-content {
    display: block;
  }
  .gd-search-form .gd-search-content .gd-search-panel {
    width: 40vw !important;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header {
    width: 40vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section {
    width: 40vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .gd-search-form {
    position: relative;
    display: inline-block;
  }
  .gd-search-form .gd-search-content {
    display: none;
    position: absolute;
    z-index: 1;
  }
  .gd-search-form .gd-search-content {
    display: block;
    left: -16vw;
  }
  .gd-search-form .gd-search-content .gd-search-panel {
    width: 30vw !important;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header {
    width: 30vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section {
    width: 45vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .gd-search-form .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.universal-search-container {
  margin-top: 8px !important;
  margin-bottom: 0px !important;
}

.universal-search {
  border-radius: 15px !important;
  height: 40px;
  font-size: 13px;
}

.html-content {
  max-height: 20px;
  overflow: hidden;
}

.gd-section-content .html-content {
  font-weight: 600;
}

.snackbar-background-red {
  --mdc-snackbar-container-color: $gd-response-red !important;
  --mdc-snackbar-supporting-text-color: #fff !important;
  --mat-snack-bar-button-color: #fff;
  background-color: rgba(231, 76, 60, 0.8);
}

.snackbar-background-green {
  --mdc-snackbar-container-color: $gd-response-green !important;
  --mdc-snackbar-supporting-text-color: #fff !important;
  --mat-snack-bar-button-color: #fff;
  background-color: rgba(38, 185, 154, 0.8);
}

.mat-simple-snackbar-action {
  color: rgba(255, 255, 255, 0.7);
}

.mat-mdc-snack-bar-container .mdc-snackbar__label {
  font-size: 13px !important;
  font-family: work_sans_regular !important;
  font-weight: 400 !important;
  line-height: 1.471 !important;
}

full-calendar.tinycalendar div.fc-header-toolbar {
  margin-top: 47px;
  margin-bottom: 0px;
}
full-calendar.tinycalendar div.fc-header-toolbar div.fc-left h2 {
  font-family: "work_sans_semibold";
  font-size: 18px;
  font-weight: 600;
}
full-calendar.tinycalendar div.fc-header-toolbar div.fc-right button {
  border: 0px;
  font-size: 10px;
}
full-calendar.tinycalendar .fc-view-container {
  margin-right: 10px;
  background-color: inherit;
  box-shadow: none;
  font-size: 10px;
}
full-calendar.tinycalendar .fc-view-container div table thead tr td {
  border: 0px;
}
full-calendar.tinycalendar .fc-view-container div table thead tr td div table thead tr th.fc-day-header.fc-widget-header {
  vertical-align: middle;
  height: 30px;
  border: 0px;
  text-align: center;
}
full-calendar.tinycalendar .fc-view-container div table thead tr td div table thead tr th.fc-day-header.fc-widget-header.fc-sat {
  background-color: inherit;
}
full-calendar.tinycalendar .fc-view-container div table thead tr td div table thead tr th.fc-day-header.fc-widget-header.fc-sun {
  background-color: inherit;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td {
  border: 0px;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div div table tbody tr td {
  border: 0px;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div div table tbody tr td.fc-sat {
  background-color: inherit;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div div table tbody tr td.fc-sun {
  background-color: inherit;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div.fc-widget-content {
  min-height: 3em;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div.fc-widget-content div.fc-content-skeleton table thead tr td {
  text-align: center;
  height: 46px;
  vertical-align: middle;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div.fc-widget-content div.fc-content-skeleton table thead tr td span.fc-day-number {
  float: none;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div.fc-widget-content div.fc-content-skeleton table thead tr td span.fc-day-number:hover {
  cursor: pointer;
  color: #2800D2;
  font-weight: bold;
}
full-calendar.tinycalendar .fc-view-container div table tbody tr td div div div.fc-widget-content div.fc-content-skeleton table tbody tr td.fc-event-container a.fc-event {
  display: none;
}
full-calendar.tinycalendar .fc-view-container .fc-today {
  background-color: inherit !important;
}
full-calendar.tinycalendar .fc-view-container .fc-bgevent {
  background-color: #d4d5d9 !important;
}

full-calendar .fc-view-harness {
  background-color: white;
}
full-calendar .fc-scroller {
  overflow-y: auto !important;
}
full-calendar .app-calendar {
  margin-top: 40px;
}
full-calendar .fc-view-container {
  background-color: white;
}
full-calendar .fc-view-container .fc-listMonth-view .fc-widget-header {
  font-weight: bold;
  padding: 5px;
}
full-calendar .fc-view-container .fc-listMonth-view .fc-widget-header .fc-list-heading-alt {
  float: left;
  margin-right: 4px;
}
full-calendar .fc-view-container .fc-listMonth-view tr.fc-list-item {
  border: 1px;
}
full-calendar .fc-view-container .fc-listMonth-view tr.fc-list-item td.fc-widget-content {
  padding: 5px;
  border: 0;
  background-color: white !important;
}
full-calendar .fc-view-container .fc-listMonth-view .fc-list-item-time, full-calendar .fc-view-container .fc-listMonth-view .fc-list-item-marker {
  display: none;
}
full-calendar div.fc-view-container div div table tbody tr td a {
  cursor: pointer;
}
full-calendar div.fc-view-container div table thead tr td div table thead tr th.fc-day-header.fc-widget-header {
  height: 50px;
}
full-calendar div.fc-view-container div table thead tr td div table thead tr th.fc-day-header.fc-widget-header span {
  padding-top: 15px;
  vertical-align: middle;
  display: inline-block;
}
full-calendar div.fc-view-container div table thead tr td div table thead tr th.fc-today span {
  background-color: #2800D2 !important;
  width: 32px !important;
  border-radius: 18px !important;
  padding: 2px !important;
  margin-top: 6px !important;
  line-height: 32px !important;
  color: white !important;
}
full-calendar div.fc-view-container div table thead tr td div table thead tr th.fc-week-number.fc-widget-header span {
  padding-top: 15px;
  vertical-align: middle;
  display: inline-block;
}
full-calendar div.fc-view-container div table tbody tr td div div div div.fc-content-skeleton table thead tr td.fc-day-top.fc-today {
  text-align: center;
}
full-calendar div.fc-view-container div table tbody tr td div div div div.fc-content-skeleton table thead tr td.fc-day-top.fc-today span {
  color: #2800D2;
  font-weight: bold;
}
full-calendar div.fc-view-container div table tbody tr td div div div div.fc-content-skeleton table tbody tr td {
  margin: 1px 0 1px 0;
}
full-calendar div.fc-view-container div table tbody tr td div div div div.fc-content-skeleton table tbody tr td a:hover {
  background-color: #717D8B !important;
  border-color: #717D8B !important;
}
full-calendar div.fc-view-container div table tbody tr td div div div div.fc-content-skeleton table tbody tr td a:hover div span {
  cursor: pointer;
}
full-calendar div.fc-view-container .fc-sat {
  background-color: #fafafa;
}
full-calendar div.fc-view-container .fc-sun {
  background-color: #fafafa;
}
full-calendar div.fc-view-container .fc-today {
  background-color: white;
}
full-calendar div.fc-view-container .fc-today .fc-day-number {
  background-color: #2800D2 !important;
  width: 18px !important;
  height: 18px !important;
  border-radius: 18px !important;
  padding: 2px !important;
  color: white !important;
}
full-calendar div.fc-view-container a.event-middel-past {
  background-color: #E0E1E3;
  border-color: #E0E1E3;
  color: #000000;
}
full-calendar div.fc-view-container a.event-middel-future, full-calendar div.fc-view-container a.event-middel-present {
  background-color: #E0E1E3;
  border-color: #E0E1E3;
  color: #000000;
}
full-calendar div.fc-view-container a.event-activity-past {
  background-color: #2800D2;
  color: #000000;
}
full-calendar div.fc-view-container a.event-activity-present, full-calendar div.fc-view-container a.event-activity-future {
  background-color: #2800D2;
  color: #000000;
}

@media screen and (min-width: 360px) and (max-width: 760px) {
  full-calendar div.fc-toolbar.fc-header-toolbar div.fc-center {
    display: none;
  }
}
.gd-switch-event {
  min-height: 90px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 760px) {
  .gd-switch-event {
    min-height: 170px;
  }
}
.gd-create-event-switch {
  padding: 10px 15px 0 15px;
  color: #949494;
}

.gd-create-event-switch:hover {
  color: #2800D2 !important;
}

.location-bouwapp {
  background: url(../../../png/integrations/bouwappchat.png) left no-repeat, url(../../../png/integrations/home.png) 15px no-repeat, url(../../../png/integrations/email.png) 30px no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 47px;
  display: block;
}

.bouwapp {
  background: url(../../../png/integrations/bouwappchat.png);
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 20px;
  display: block;
}

.location {
  background: url(../../../png/integrations/home.png) left no-repeat, url(../../../png/integrations/email.png) 17px no-repeat;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 35px;
  display: block;
}

.tinycalendar .fc-view-harness {
  background-color: transparent !important;
}

full-calendar a {
  color: #000000 !important;
}

full-calendar.tinycalendar a {
  font-size: 10px !important;
}

full-calendar .fc-listMonth-view td {
  border: 0;
}
full-calendar .fc-listMonth-view .location-bouwapp {
  background: url(../../../png/integrations/bouwappchat.png) left no-repeat, url(../../../png/integrations/home.png) 30px no-repeat, url(../../../png/integrations/email.png) 60px no-repeat !important;
  padding-left: 90px !important;
  display: block ruby !important;
  background-size: 24px !important;
}
full-calendar .fc-listMonth-view .bouwapp {
  background: url(../../../png/integrations/bouwappchat.png) left no-repeat !important;
  padding-left: 30px !important;
  display: block ruby !important;
  background-size: 24px !important;
}
full-calendar .fc-listMonth-view .location {
  background: url(../../../png/integrations/home.png) left no-repeat, url(../../../png/integrations/email.png) 30px no-repeat !important;
  padding-left: 68px !important;
  display: block ruby !important;
  background-size: 24px !important;
}
full-calendar .fc-dayGridWeek-view .fc-daygrid-day-top a {
  display: none;
}
full-calendar .fc-button-group button.fc-listMonth-button {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
full-calendar .fc-button-group button.fc-dayGridWeek-button {
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}
full-calendar .fc-button-group button.fc--button {
  display: none;
}
full-calendar .fc-day-today {
  background: transparent !important;
}
full-calendar .fc-day-today .fc-daygrid-day-number {
  background-color: #2800D2 !important;
  width: 25px !important;
  height: 25px !important;
  border-radius: 18px !important;
  padding: 3px 4px !important;
  color: #fff !important;
  text-align: center;
}

full-calendar.tinycalendar .fc-day-today {
  background: transparent !important;
}
full-calendar.tinycalendar .fc-day-today .fc-daygrid-day-number {
  background-color: #2800D2 !important;
  width: 21px !important;
  height: 21px !important;
  border-radius: 18px !important;
  padding: 3px 5px !important;
  color: #fff !important;
  text-align: center;
}

full-calendar.tinycalendar button.fc-next-button {
  border: 0;
}
full-calendar.tinycalendar .fc-button-group button {
  border: 0;
}
full-calendar.tinycalendar .fc-button-group button.fc--button {
  display: none;
}
full-calendar.tinycalendar th {
  border: 0;
}
full-calendar.tinycalendar td {
  border: 0;
}
full-calendar.tinycalendar table {
  border: 0 !important;
}
full-calendar.tinycalendar table tbody table tbody td .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
}
full-calendar.tinycalendar table tbody table tbody td .fc-daygrid-day-bg .fc-event-title {
  display: none;
}
full-calendar.tinycalendar table tbody table tbody td .fc-daygrid-day-top {
  display: flex;
  position: absolute;
  top: 28%;
}
full-calendar.tinycalendar table tbody table tbody td .fc-daygrid-day-top a.fc-daygrid-day-number {
  cursor: pointer;
}
full-calendar.tinycalendar table tbody table tbody td .fc-daygrid-event-harness a.fc-event {
  display: none;
}

full-calendar.dashboard-calender-set table {
  border-collapse: separate;
  border-spacing: 5px;
}
full-calendar.dashboard-calender-set table .fc-daygrid-day {
  cursor: pointer;
  border-style: none solid solid none;
  border-radius: 10px;
  background-color: #F6F6F6 !important;
}
full-calendar.dashboard-calender-set table .fc-daygrid-day .fc-daygrid-day-bg .fc-event-title {
  display: none;
}
full-calendar.dashboard-calender-set table .fc-day-today {
  background-color: #2800D2 !important;
}

full-calendar.dashboard-calender-set .fc-header-toolbar .fc-toolbar-chunk .fc-toolbar-title {
  text-transform: capitalize;
  font-size: 14px;
  position: absolute;
  top: 22px;
  left: 25px;
}
full-calendar.dashboard-calender-set .fc-header-toolbar .fc-toolbar-chunk .fc-button-group button {
  border: 0;
  top: 16px;
  right: 50px;
}
full-calendar.dashboard-calender-set .fc-header-toolbar .fc-toolbar-chunk .fc-next-button {
  border: 0;
  top: 16px;
  right: 20px;
  position: absolute;
}
full-calendar.dashboard-calender-set table {
  border: 0 !important;
}
full-calendar.dashboard-calender-set table tbody th {
  border: 0;
}
full-calendar.dashboard-calender-set table tbody td {
  border: 0;
}
full-calendar.dashboard-calender-set table tbody table thead .fc-col-header-cell-cushion {
  text-transform: capitalize;
  font-size: 14px;
}
full-calendar.dashboard-calender-set table tbody table thead .fc-col-header-cell {
  border: 0;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-top {
  display: flex;
  position: absolute;
  top: 22%;
  font-weight: bold !important;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .location-bouwapp {
  padding-left: 0px;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .bouwapp {
  padding-left: 0px;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .location {
  padding-left: 0px;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .fc-bg-event {
  border-radius: 10px;
  opacity: 1 !important;
  cursor: pointer;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-middel-past {
  background-color: #D7D7D7 !important;
  border-color: #D7D7D7;
  color: #19283b !important;
  font-weight: bold !important;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-middel-future, full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-middel-present {
  background-color: #D7D7D7 !important;
  border-color: #D7D7D7;
  color: #19283b !important;
  font-weight: bold !important;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-activity-past {
  background-color: #D7D7D7 !important;
  border-color: #D7D7D7 !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
}
full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-activity-present, full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-bg .fc-daygrid-bg-harness .event-activity-future {
  background-color: #D7D7D7 !important;
  border-color: #D7D7D7 !important;
  color: #FFFFFF !important;
  font-weight: bold !important;
}

full-calendar.dashboard-calender-set table tbody table tbody td .fc-daygrid-day-frame {
  display: flex;
  justify-content: center;
  min-height: 46px;
}

full-calendar.dashboard-maincalender-set table {
  border: 0 !important;
}
full-calendar.dashboard-maincalender-set table tbody table thead .fc-col-header-cell-cushion {
  text-transform: capitalize;
  font-size: 14px;
}
full-calendar.dashboard-maincalender-set table tbody table thead .fc-col-header-cell {
  border: 0;
}
full-calendar.dashboard-maincalender-set .fc-header-toolbar .fc-toolbar-chunk button {
  padding: 6px 20px !important;
  text-transform: capitalize !important;
}
full-calendar.dashboard-maincalender-set .fc-header-toolbar .fc-toolbar-chunk h2.fc-toolbar-title {
  font-family: "work_sans_semibold";
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize !important;
  position: absolute;
  left: 250px;
  top: 5px;
}

full-calendar.dashboard-maincalender-set .fc-daygrid-event-harness .label-icons {
  background: url(../../../png/integrations/info.png) 5px no-repeat;
  padding-left: 28px;
  display: block ruby;
  background-size: 16px;
}

full-calendar.dashboard-maincalender-set table today .fc-scrollgrid-section th {
  border: 0;
}
full-calendar.dashboard-maincalender-set table today .fc-scrollgrid-section {
  border: 0;
}

full-calendar.dashboard-maincalender-set .fc-scrollgrid-section td {
  border: 0;
}
full-calendar.dashboard-maincalender-set .fc-scrollgrid-section td .fc-scroller-harness td {
  border: 1px solid #ddd;
}
full-calendar.dashboard-maincalender-set .fc-scrollgrid-section th {
  border: 0;
}

.highcharts-container {
  width: 100% !important;
}
.highcharts-container .highcharts-root {
  width: 100% !important;
}

.mt-0 {
  margin-top: 0px;
}

.p-0 {
  padding: 0px;
}

.m-0 {
  margin: 0px;
}

@media screen and (max-width: 767px) and (min-width: 320px) {
  .item-width-style {
    width: 100%;
  }
}
@media screen and (max-width: 1900px) and (min-width: 768px) {
  .item-width-style {
    width: 49.5%;
  }
}
@media (min-width: 1901px) {
  .item-width-style {
    width: 32.9%;
  }
}
.set-expansion-style {
  border-radius: 4px !important;
  margin-bottom: 2px !important;
  border: 2px solid #e9eaef !important;
  box-shadow: none !important;
}
.set-expansion-style mat-expansion-panel-header {
  font-size: 13px !important;
  height: 50px !important;
}
.set-expansion-style mat-panel-description {
  height: 35px;
  margin-top: 16px;
  width: 200px;
  font-size: 12px;
}
.set-expansion-style mat-panel-title {
  height: 35px;
  margin-top: 16px;
  width: 100px;
  font-size: 14px;
}

.text-gray {
  color: #C8C8C8 !important;
}

.empty-state-image {
  width: 125px;
}

.empty-state-image-small {
  width: 75px;
}

.empty-oc-height-set {
  height: 300px;
}

.empty-padding {
  display: flex;
  align-items: center;
  justify-content: center;
}

.empty-oc-height-set h2 {
  font-family: "work_sans_semibold";
  font-size: 18px;
  font-weight: 600;
}
.empty-oc-height-set p {
  font-size: 12px;
}

flx-text-input-highlight {
  display: none !important;
}

.disable-anchor-tag-button {
  pointer-events: none !important;
  cursor: not-allowed !important;
  color: #c4c4c4 !important;
}

.iti__country-list {
  position: fixed !important;
  overflow: auto !important;
}

.w-100 {
  width: 100%;
}

.dragplaceholder-box {
  width: 200px;
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
  border: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: #fff;
  border-radius: 4px;
  position: relative;
  z-index: 1;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.dragplaceholder-box:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.dragplaceholder-handle {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #ccc;
  cursor: move;
  width: 24px;
  height: 24px;
}

.calender-owl-icon-set {
  position: absolute;
  font-size: 13px;
  right: -10px;
  color: #757575;
  bottom: -6px;
}

.ellipsis-2 {
  display: -webkit-box;
  max-height: 60px;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.d-flex-end {
  display: flex;
  justify-content: end;
}

.wp-350 {
  width: 350px;
}

.table-pagination-set {
  margin: -12px 0px !important;
  background-color: white !important;
}

.table-scrolling-set {
  overflow: auto !important;
  height: 70vh !important;
  border: 1px solid #DDDDDD;
  border-radius: 20px;
}

.p-relative {
  position: relative;
}

.badge.status {
  position: absolute;
  top: -7px;
  padding: 5px !important;
}

.l-20 {
  left: 20px !important;
}

.r-0 {
  right: 0px !important;
}

.badge.status.on {
  background-color: #FF7669 !important;
}

.badge.status.off {
  background-color: #D3D3D3 !important;
}

.text-black {
  color: #000 !important;
}

.vertical-line-intrest {
  position: absolute;
  font-size: 10px;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ada9a9;
  height: 100%;
}

.vertical-line-intrest-header {
  border: 1px solid #ada9a9;
  height: 100%;
}

.fc-button-primary {
  background-color: transparent !important;
  border-color: #D9DEE4 !important;
  color: #515356 !important;
}

.fc-button-primary:hover {
  background-color: #D9DEE4 !important;
}

.fc-button-primary.fc-button-active {
  background-color: transparent !important;
  border-color: #2800D2 !important;
  color: #2800D2 !important;
}

.fc-button-primary:focus {
  outline: none !important;
  border-color: #2800D2 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.gd-button-create {
  width: 100%;
  font-size: 13px;
}

.gd-activity {
  background-color: #2800D2;
  border-color: #2800D2;
  color: #FFFFFF;
}

.gd-middel {
  background-color: #2C3E50;
  border-color: #2C3E50;
  color: #FFFFFF;
}

.gd-close-modal {
  right: 10px;
  position: fixed;
  z-index: 999;
  cursor: pointer;
  color: #C7C7C7;
}

.gd-close-modal:hover {
  color: #2800D2;
}

.mdl-button--primary.mdl-button--primary {
  background-color: transparent;
  color: #2800D2;
}

.mdl-button--primary.mdl-button--primary:hover {
  color: #2800D2;
  background-color: #e7e8e8;
}

.mdl-button--raised:hover {
  color: #ffffff;
}

/* Override mat-button styling */
.mat-button {
  font-family: "work_sans_regular";
  color: #2800D2;
}

.mdc-button--outlined {
  font-family: "work_sans_regular" !important;
  border-radius: 30px !important;
  color: #2800D2;
  border-color: #2800D2 !important;
  color: #2800D2 !important;
}

.mat-mdc-stroked-button {
  font-family: "work_sans_regular";
  border-radius: 30px !important;
  color: #2800D2 !important;
  border-color: #2800D2 !important;
}

.mat-mdc-stroked-button:disabled {
  cursor: not-allowed !important;
}

.mat-mdc-unelevated-button {
  font-family: "work_sans_regular" !important;
}

.mat-mdc-unelevated-button, .mat-mdc-unelevated-button:hover, .mat-mdc-unelevated-button:focus {
  border-radius: 30px !important;
  color: white !important;
  background-color: #2800D2 !important;
}

.mat-mdc-unelevated-button:visited {
  color: #FFFFFF !important;
  background-color: #2800D2 !important;
}

.mat-mdc-unelevated-button:disabled {
  background-color: #c4c4c4 !important;
  cursor: not-allowed !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
}

.mat-flat-button, .mat-flat-button:hover, .mat-flat-button:focus {
  border-radius: 30px !important;
  color: white !important;
  background-color: #2800D2 !important;
  pointer: cursor !important;
  padding: 12px 16px;
}

.dialog-button-row button {
  margin-left: 8px !important;
}
.dialog-button-row a {
  margin-left: 8px !important;
  margin-bottom: 3px !important;
}

.mat-mdc-unelevated-button-icon-margin {
  margin-right: 5px;
}

.mat-mdc-unelevated-button-margin-bottom {
  margin-bottom: 5px !important;
}

delete-button mat-icon {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s;
}

tr:hover .delete-button mat-icon, mat-row:hover .delete-button mat-icon {
  opacity: 1;
  transition: opacity 0.5s;
  color: #2800D2;
}

.delete-button-hover:hover .delete-button .unicon-icon {
  opacity: 1;
  transition: opacity 0.5s;
  font-size: 24px;
  color: #2800D2;
}

.delete-button-hover:hover .delete-button mat-icon {
  opacity: 1;
  transition: opacity 0.5s;
  color: #2800D2;
}

.map-toggles-set-bookmark .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
.map-toggles-set-bookmark-add .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
.map-toggles-set .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
.polygon-edit-toggle .mat-button-toggle-appearance-standard .mat-button-toggle-label-content,
.map-toggles-set-klanteisen .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  padding: 5px !important;
}

@media only screen and (max-width: 450px) {
  .fc-button {
    padding: 0.2rem 0.2rem !important;
  }
}
.mdc-button {
  padding: 0 16px 0 16px !important;
  cursor: pointer !important;
  white-space: nowrap !important;
  text-align: center !important;
  line-height: 36px !important;
  letter-spacing: 0px !important;
}

.mat-mdc-outlined-button .mat-icon {
  font-size: 20px !important;
}

.mat-stroked-button {
  border-radius: 30px !important;
  border: 1px solid #2800d2 !important;
  color: #2800d2 !important;
  font-size: 14px;
  font-weight: 500;
  background: inherit;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  padding: 11px 15px;
}

.mat-mdc-outlined-button[disabled] {
  color: var(--mdc-outlined-button-disabled-label-text-color, rgba(0, 0, 0, 0.38)) !important;
  cursor: not-allowed !important;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
  --mat-mdc-button-ripple-color: transparent !important;
  --mat-mdc-button-persistent-ripple-color: transparent !important;
  --mat-mdc-button-ripple-color: transparent !important;
}

.custom-form-field .mat-form-field-wrapper {
  display: flex;
  align-items: flex-end;
}

.custom-form-field .mat-form-field-label {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: none;
  transform-origin: left bottom;
  transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out;
}

.custom-form-field.mat-form-field-should-float .mat-form-field-label {
  transform: scale(0.75) translateY(-100%);
  bottom: 100%;
}

.custom-form-field .mat-form-field-label-wrapper {
  width: 100%;
}

.mat-datepicker-toggle .mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 15px !important;
  padding: 0px !important;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: #fff !important;
  border: 1px solid gray;
}

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label {
  color: #2800D2 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: #2800D2 !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled + .mdc-radio__background .mdc-radio__inner-circle {
  border-color: #2800D2 !important;
  background-color: #2800D2 !important;
}

.mat-fab.mat-primary, .mat-flat-button.mat-primary, .mat-mini-fab.mat-primary, .mat-raised-button.mat-primary {
  background-color: #3C8CAA;
}

.mat-row {
  color: #36373A;
  font-family: "work_sans_regular" !important;
  font-variant-numeric: lining-nums;
  font-size: 13px;
  font-weight: 400;
  height: auto;
  position: relative;
}

.mat-mdc-cell {
  padding: 12px !important;
}

.mat-mdc-header-cell {
  padding: 12px !important;
}

mat-row:hover {
  background-color: #f5f5f5;
}

.mat-mdc-row-link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.mat-mdc-row {
  font-family: "work_sans_regular" !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: rgba(30, 159, 242, 0.5);
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #2800D2;
}

.mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
  background-color: #2800D2;
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #2800D2 !important;
}

.mat-radio-label-content {
  color: #36373A;
  font-family: "work_sans_regular";
  font-variant-numeric: lining-nums;
  font-size: 13px;
  font-weight: 400;
}

.mat-primary .mat-pseudo-checkbox-checked, .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #2800D2;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #2800D2;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-ripple {
  background-color: #2800D2;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-label {
  color: #2800D2;
}

.mat-primary .mat-mdc-option.mat-selected:not(.mat-mdc-option-disabled) {
  color: #2800D2;
}

.mat-tab-group.mat-primary, .mat-mdc-tab-nav-bar.mat-primary .mat-mdc-tab-link-container .mat-mdc-tab-list .mat-mdc-tab-links .mat-mdc-tab-link .mdc-tab-indicator .mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  width: 80% !important;
  border-top-width: thin !important;
}

.mat-mdc-table {
  width: 100%;
}

.mat-table tbody tr:hover {
  cursor: pointer;
  background: #f4f4f4;
}

.mat-mdc-table, .mat-mdc-paginator {
  font-family: "work_sans_regular" !important;
}

.mat-mdc-header-row {
  font-family: "work_sans_regular" !important;
}

app-interests-matrix .mat-mdc-paginator {
  margin-right: 30px !important;
}

.mat-mdc-header-cell {
  font-weight: 700 !important;
  font-size: 14px;
  padding: 12px;
  color: black;
  line-height: 1.2 !important;
  overflow: visible !important;
}

.mat-sort-header-stem, .mat-sort-header-pointer-middle {
  background: none;
  display: none !important;
}

.mat-sort-header-indicator {
  align-items: center;
}

.mat-mdc-paginator {
  background-color: #f2f3f8;
}

.search-holder {
  height: 40px;
  display: flex;
  align-items: center;
  padding-inline-start: 12px;
}

.search-box {
  width: 100%;
  border: none;
  vertical-align: middle;
  display: table-cell;
  padding: 6px 6px 6px 12px;
  font-size: 13px;
}

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit {
  background-color: #2800D2;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  font-family: "work_sans_regular";
  font-size: 14px;
}

.mat-horizontal-content-container {
  padding: 0 !important;
}

.mat-icon {
  height: 24px !important;
  width: 24px !important;
  overflow: visible !important;
}

.arrow_back {
  display: inline-flex !important;
  align-items: center !important;
}

.mat-icon-disabled {
  opacity: 0.28;
}

.mat-pseudo-checkbox-checked {
  background: #2800D2 !important;
}

.mat-mdc-checkbox-checked.mat-accent .mat-mdc-checkbox-background, .mat-mdc-checkbox-indeterminate.mat-accent .mat-mdc-checkbox-background {
  background: #2800D2 !important;
}

.mat-mdc-checkbox:not(.mat-mdc-checkbox-disabled).mat-accent .mat-mdc-checkbox-ripple .mat-ripple-element {
  background: #2800D2 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background {
  background: #2800D2 !important;
  border-color: #2800D2 !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  color: #fff !important;
}

.mat-badge-content {
  background-color: #28ABE3;
}

.cdk-global-scrollblock {
  overflow-y: inherit;
}

.cdk-overlay-container {
  z-index: 2000;
}

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  color: #f44336;
}

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-suffix mat-datepicker-toggle {
  color: #f44336;
}

mat-mdc-form-field.ng-invalid.ng-touched.ng-dirty .mat-mdc-form-field-wrapper .mat-mdc-form-field-underline {
  background-color: #f44336;
}

.mat-mdc-form-field-underline {
  height: 1px;
  background-color: #D6D6D6 !important;
}

.mat-horizontal-stepper-header {
  pointer-events: none;
}

app-issue-new .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 28px !important;
  padding: 0 10px !important;
  font-size: 12px !important;
}

app-project-config-instellingen .mat-button-toggle-appearance-standard .mat-button-toggle-label-content, app-add-open-data-layers .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px !important;
  font-size: 13px !important;
}

app-issue-new .mat-button-toggle-group {
  width: 100%;
  margin-bottom: 10px;
}

app-issue-new .map-toggles-set.mat-button-toggle-group {
  width: auto;
  bottom: 35px;
}

app-issue-new .mat-button-toggle {
  width: 100%;
}

.custom-dialog-container .mat-mdc-dialog-container {
  padding: 15px;
}

.mdc-dialog__title::before {
  display: block !important;
  height: 0px !important;
}

.custom-tabs .mat-ink-bar {
  border: 1px solid black;
}

.mat-mdc-form-field {
  font-family: "work_sans_regular";
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: normal;
}

.custom-expand .mat-expansion-panel-body, .custom-expand-map .mat-expansion-panel-body {
  padding: 0 16px 16px;
  font-family: "work_sans_regular";
}

app-stakeholders-tabs .mat-mdc-tab-nav-bar, app-issues-detail .mat-mdc-tab-nav-bar, app-actions .mat-mdc-tab-nav-bar, app-analysis .mat-mdc-tab-nav-bar, app-new-workflow .mat-mdc-tab-nav-bar {
  max-width: 422px;
}

app-stakeholders-tabs .overview-tabs, app-issues-detail .overview-tabs, app-actions .overview-tabs, app-analysis .overview-tabs, app-new-workflow .overview-tabs {
  max-width: 422px;
}

app-stakeholders-tabs .mat-mdc-tab-list, app-issues-detail .mat-mdc-tab-list, app-actions .mat-mdc-tab-list, app-analysis .mat-mdc-tab-list, app-new-workflows .mat-mdc-tab-list {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: visible;
}

app-stakeholders-tabs .mat-mdc-tab-link, app-issues-detail .mat-mdc-tab-link, app-actions .mat-mdc-tab-link, app-analysis .mat-mdc-tab-link, app-new-workflow .mat-mdc-tab-link {
  height: 37px !important;
  min-width: 115px;
}

app-stakeholders-tabs .mat-mdc-tab-nav-bar, app-issues-detail .mat-mdc-tab-nav-bar, app-actions .mat-mdc-tab-nav-bar, app-analysis .mat-mdc-tab-nav-bar, app-new-workflow .mat-mdc-tab-nav-bar {
  border-bottom: 0px;
}

app-stakeholder .mat-paginator-container, app-issues-detail .mat-paginator-container, app-klanwensten-list .mat-paginator-container, app-logs-overview .mat-paginator-container, app-complaints-list .mat-paginator-container, app-stakeholders-employees .mat-paginator-container, app-documents .mat-paginator-container, app-import-email-modal .mat-paginator-container, app-project-config-logs .mat-paginator-container {
  padding: 0px;
  width: auto !important;
  position: sticky !important;
  left: 0;
}

app-stakeholder .mat-paginator-outer-container, app-issues-detail .mat-paginator-outer-container, app-klanwensten-list .mat-paginator-outer-container, app-logs-overview .mat-paginator-outer-container, app-complaints-list .mat-paginator-outer-container, app-stakeholders-employees .mat-paginator-outer-container, app-documents .mat-paginator-outer-container, app-import-email-modal .mat-paginator-outer-container, app-project-config-logs .mat-paginator-outer-container {
  padding-top: -3px;
}

app-stakeholder .mat-paginator-page-size-select, app-issues-detail .mat-paginator-page-size-select, app-klanwensten-list .mat-paginator-page-size-select, app-logs-overview .mat-paginator-page-size-select, app-complaints-list .mat-paginator-page-size-select, app-stakeholders-employees .mat-paginator-page-size-select, app-documents .mat-paginator-page-size-select, app-import-email-modal .mat-paginator-page-size-select, app-project-config-logs .mat-paginator-page-size-select {
  margin: 12px 4px 0 4px;
}

app-stakeholder .mat-paginator-range-actions, app-issues-detail .mat-paginator-range-actions, app-klanwensten-list .mat-paginator-range-actions, app-logs-overview .mat-paginator-range-actions, app-complaints-list .mat-paginator-range-actions, app-stakeholders-employees .mat-paginator-range-actions, app-documents .mat-paginator-range-actions, app-import-email-modal .mat-paginator-range-actions, app-project-config-logs .mat-paginator-range-actions {
  padding-bottom: 3px;
}

app-stakeholder .mat-paginator-navigation-next, app-issues-detail .mat-paginator-navigation-next, app-klanwensten-list .mat-paginator-navigation-next, app-logs-overview .mat-paginator-navigation-next, app-complaints-list .mat-paginator-navigation-next, app-stakeholders-employees .mat-paginator-navigation-next, app-documents .mat-paginator-navigation-next, app-import-email-modal .mat-paginator-navigation-next, app-project-config-logs .mat-paginator-navigation-next {
  height: 37px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: visible;
}

app-stakeholder .mat-paginator-navigation-next, app-issues-detail .mat-paginator-navigation-next, app-klanwensten-list .mat-paginator-navigation-next, app-logs-overview .mat-paginator-navigation-next, app-complaints-list .mat-paginator-navigation-next, app-stakeholders-employees .mat-paginator-navigation-next, app-documents .mat-paginator-navigation-next, app-import-email-modal .mat-paginator-navigation-next, app-project-config-logs .mat-paginator-navigation-next {
  margin-left: 4px;
  color: #2800D2;
}

app-stakeholder .mat-paginator-navigation-previous, app-issues-detail .mat-paginator-navigation-previous, app-klanwensten-list .mat-paginator-navigation-previous, app-logs-overview .mat-paginator-navigation-previous, app-complaints-list .mat-paginator-navigation-previous, app-stakeholders-employees .mat-paginator-navigation-previous, app-documents .mat-paginator-navigation-previous, app-import-email-modal .mat-paginator-navigation-previous, app-project-config-logs .mat-paginator-navigation-previous {
  margin-left: 8px;
  color: #2800D2;
}

app-stakeholder .mat-paginator-icon, app-issues-detail .mat-paginator-icon, app-klanwensten-list .mat-paginator-icon, app-logs-overview .mat-paginator-icon, app-complaints-list .mat-paginator-icon, app-stakeholders-employees .mat-paginator-icon, app-documents .mat-paginator-icon, app-import-email-modal .mat-paginator-icon, app-project-config-logs .mat-paginator-icon {
  padding-bottom: 5px;
}

app-dashboard-pie-chart .mat-button-toggle-label-content {
  line-height: 38px !important;
}

.custome-padding .mat-mdc-form-field-infix {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}

.custome-padding .mat-mdc-form-field-label {
  top: 20px !important;
}

.custome-padding-select .mat-mdc-form-field-flex {
  padding-bottom: 2px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

@media all {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: #000;
  }
}
.mat-mdc-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, Helvetica Neue, sans-serif !important;
  letter-spacing: normal;
}

.mat-mdc-form-field-wrapper {
  position: relative !important;
}

.mat-mdc-form-field-infix {
  display: block !important;
  position: relative !important;
  flex: auto !important;
  min-width: 0 !important;
  width: 180px !important;
}

.mdc-text-field {
  padding: 0px !important;
}

.mat-mdc-input-element {
  caret-color: #673ab7 !important;
}

.mat-mdc-form-field .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  color: #3f51b5 !important;
  font-size: 16px !important;
  transform-origin: top left !important;
  transition: transform 0.2s, color 0.2s !important;
}

.mat-mdc-form-field .mat-mdc-input-element:focus + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label,
.mat-mdc-form-field .mat-mdc-input-element.ng-not-empty + .mat-mdc-form-field-label-wrapper .mat-mdc-form-field-label {
  transform: translateY(-1.25em) scale(0.75) !important;
  color: #3f51b5 !important;
}

.mdc-text-field--filled.mdc-text-field--disabled {
  background-color: #fff !important;
}

.mat-mdc-radio-button .mdc-radio {
  padding: 0px !important;
}

.mat-mdc-select {
  font-size: inherit !important;
}

.mat-mdc-select-value-text {
  font-size: 13px !important;
  font-family: "work_sans_regular" !important;
}

app-stakeholder .mat-mdc-paginator-container, app-issues-detail .mat-mdc-paginator-container, app-klanwensten-list .mat-mdc-paginator-container, app-logs-overview .mat-mdc-paginator-container, app-permit-overview .mat-mdc-paginator-container, app-complaints-list .mat-mdc-paginator-container, app-stakeholders-employees .mat-mdc-paginator-container, app-documents .mat-mdc-paginator-container, app-import-email-modal .mat-mdc-paginator-container, app-interests-matrix .mat-mdc-paginator-container, app-recycle-bin .mat-mdc-paginator-container, app-project-config-logs .mat-mdc-paginator-container {
  padding: 0px;
  width: auto !important;
  position: sticky !important;
  left: 0;
}
app-stakeholder .mat-mdc-paginator-outer-container, app-issues-detail .mat-mdc-paginator-outer-container, app-klanwensten-list .mat-mdc-paginator-outer-container, app-logs-overview .mat-mdc-paginator-outer-container, app-permit-overview .mat-mdc-paginator-outer-container, app-complaints-list .mat-mdc-paginator-outer-container, app-stakeholders-employees .mat-mdc-paginator-outer-container, app-documents .mat-mdc-paginator-outer-container, app-import-email-modal .mat-mdc-paginator-outer-container, app-interests-matrix .mat-mdc-paginator-outer-container, app-recycle-bin .mat-mdc-paginator-outer-container, app-project-config-logs .mat-mdc-paginator-outer-container {
  padding-top: -3px;
}
app-stakeholder .mat-mdc-paginator-page-size-label, app-issues-detail .mat-mdc-paginator-page-size-label, app-klanwensten-list .mat-mdc-paginator-page-size-label, app-logs-overview .mat-mdc-paginator-page-size-label, app-permit-overview .mat-mdc-paginator-page-size-label, app-complaints-list .mat-mdc-paginator-page-size-label, app-stakeholders-employees .mat-mdc-paginator-page-size-label, app-documents .mat-mdc-paginator-page-size-label, app-import-email-modal .mat-mdc-paginator-page-size-label, app-interests-matrix .mat-mdc-paginator-page-size-label, app-recycle-bin .mat-mdc-paginator-page-size-label, app-project-config-logs .mat-mdc-paginator-page-size-label {
  color: rgba(0, 0, 0, 0.54);
}
app-stakeholder .mat-mdc-paginator-page-size-select, app-issues-detail .mat-mdc-paginator-page-size-select, app-klanwensten-list .mat-mdc-paginator-page-size-select, app-logs-overview .mat-mdc-paginator-page-size-select, app-permit-overview .mat-mdc-paginator-page-size-select, app-complaints-list .mat-mdc-paginator-page-size-select, app-stakeholders-employees .mat-mdc-paginator-page-size-select, app-documents .mat-mdc-paginator-page-size-select, app-import-email-modal .mat-mdc-paginator-page-size-select, app-interests-matrix .mat-mdc-paginator-page-size-select, app-recycle-bin .mat-mdc-paginator-page-size-select, app-project-config-logs .mat-mdc-paginator-page-size-select {
  margin: 0px 4px 0 4px;
  width: 56px !important;
}
app-stakeholder .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-issues-detail .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-klanwensten-list .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-logs-overview .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-permit-overview .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-complaints-list .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-stakeholders-employees .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-documents .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-import-email-modal .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-interests-matrix .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-recycle-bin .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper, app-project-config-logs .mat-mdc-paginator-page-size-select .mat-mdc-text-field-wrapper {
  width: 100% !important;
}
app-stakeholder .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-issues-detail .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-klanwensten-list .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-logs-overview .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-permit-overview .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-complaints-list .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-stakeholders-employees .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-documents .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-import-email-modal .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-interests-matrix .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-recycle-bin .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix, app-project-config-logs .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix {
  height: 37px;
  padding: 3px 10px 10px 10px !important;
  border: 1px solid;
  border-color: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  overflow: visible;
}
app-stakeholder .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-issues-detail .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-klanwensten-list .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-logs-overview .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-permit-overview .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-complaints-list .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-stakeholders-employees .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-documents .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-import-email-modal .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-interests-matrix .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-recycle-bin .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow, app-project-config-logs .mat-mdc-paginator-page-size-select .mat-mdc-form-field-infix .mat-mdc-select-arrow {
  display: block !important;
}
app-stakeholder .mat-form-field-underline, app-issues-detail .mat-form-field-underline, app-klanwensten-list .mat-form-field-underline, app-logs-overview .mat-form-field-underline, app-permit-overview .mat-form-field-underline, app-complaints-list .mat-form-field-underline, app-stakeholders-employees .mat-form-field-underline, app-documents .mat-form-field-underline, app-import-email-modal .mat-form-field-underline, app-interests-matrix .mat-form-field-underline, app-recycle-bin .mat-form-field-underline, app-project-config-logs .mat-form-field-underline {
  height: 0px !important;
}
app-stakeholder .mat-mdc-paginator-range-actions, app-issues-detail .mat-mdc-paginator-range-actions, app-klanwensten-list .mat-mdc-paginator-range-actions, app-logs-overview .mat-mdc-paginator-range-actions, app-permit-overview .mat-mdc-paginator-range-actions, app-complaints-list .mat-mdc-paginator-range-actions, app-stakeholders-employees .mat-mdc-paginator-range-actions, app-documents .mat-mdc-paginator-range-actions, app-import-email-modal .mat-mdc-paginator-range-actions, app-interests-matrix .mat-mdc-paginator-range-actions, app-recycle-bin .mat-mdc-paginator-range-actions, app-project-config-logs .mat-mdc-paginator-range-actions {
  padding-bottom: 0px;
}
app-stakeholder .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-issues-detail .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-klanwensten-list .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-logs-overview .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-permit-overview .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-complaints-list .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-stakeholders-employees .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-documents .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-import-email-modal .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-interests-matrix .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-recycle-bin .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label, app-project-config-logs .mat-mdc-paginator-range-actions .mat-mdc-paginator-range-label {
  color: rgba(0, 0, 0, 0.54);
}
app-stakeholder .mat-mdc-paginator-navigation-previous, app-stakeholder .mat-mdc-paginator-navigation-next, app-issues-detail .mat-mdc-paginator-navigation-previous, app-issues-detail .mat-mdc-paginator-navigation-next, app-klanwensten-list .mat-mdc-paginator-navigation-previous, app-klanwensten-list .mat-mdc-paginator-navigation-next, app-logs-overview .mat-mdc-paginator-navigation-previous, app-logs-overview .mat-mdc-paginator-navigation-next, app-permit-overview .mat-mdc-paginator-navigation-previous, app-permit-overview .mat-mdc-paginator-navigation-next, app-complaints-list .mat-mdc-paginator-navigation-previous, app-complaints-list .mat-mdc-paginator-navigation-next, app-stakeholders-employees .mat-mdc-paginator-navigation-previous, app-stakeholders-employees .mat-mdc-paginator-navigation-next, app-documents .mat-mdc-paginator-navigation-previous, app-documents .mat-mdc-paginator-navigation-next, app-import-email-modal .mat-mdc-paginator-navigation-previous, app-import-email-modal .mat-mdc-paginator-navigation-next, app-interests-matrix .mat-mdc-paginator-navigation-previous, app-interests-matrix .mat-mdc-paginator-navigation-next, app-recycle-bin .mat-mdc-paginator-navigation-previous, app-recycle-bin .mat-mdc-paginator-navigation-next, app-project-config-logs .mat-mdc-paginator-navigation-previous, app-project-config-logs .mat-mdc-paginator-navigation-next {
  height: 37px !important;
  padding: 9px !important;
  border: 1px solid !important;
  border-color: rgba(0, 0, 0, 0.12) !important;
  border-radius: 4px;
  overflow: visible;
}
app-stakeholder .mat-mdc-paginator-navigation-next, app-issues-detail .mat-mdc-paginator-navigation-next, app-klanwensten-list .mat-mdc-paginator-navigation-next, app-logs-overview .mat-mdc-paginator-navigation-next, app-permit-overview .mat-mdc-paginator-navigation-next, app-complaints-list .mat-mdc-paginator-navigation-next, app-stakeholders-employees .mat-mdc-paginator-navigation-next, app-documents .mat-mdc-paginator-navigation-next, app-import-email-modal .mat-mdc-paginator-navigation-next, app-interests-matrix .mat-mdc-paginator-navigation-next, app-recycle-bin .mat-mdc-paginator-navigation-next, app-project-config-logs .mat-mdc-paginator-navigation-next {
  margin-left: 2px;
  color: #2800D2 !important;
  fill: #2800D2 !important;
}
app-stakeholder .mat-mdc-paginator-navigation-previous, app-issues-detail .mat-mdc-paginator-navigation-previous, app-klanwensten-list .mat-mdc-paginator-navigation-previous, app-logs-overview .mat-mdc-paginator-navigation-previous, app-permit-overview .mat-mdc-paginator-navigation-previous, app-complaints-list .mat-mdc-paginator-navigation-previous, app-stakeholders-employees .mat-mdc-paginator-navigation-previous, app-documents .mat-mdc-paginator-navigation-previous, app-import-email-modal .mat-mdc-paginator-navigation-previous, app-interests-matrix .mat-mdc-paginator-navigation-previous, app-recycle-bin .mat-mdc-paginator-navigation-previous, app-project-config-logs .mat-mdc-paginator-navigation-previous {
  margin-left: 8px;
  color: #2800D2 !important;
  fill: #2800D2 !important;
}
app-stakeholder .mat-mdc-paginator-icon, app-issues-detail .mat-mdc-paginator-icon, app-klanwensten-list .mat-mdc-paginator-icon, app-logs-overview .mat-mdc-paginator-icon, app-permit-overview .mat-mdc-paginator-icon, app-complaints-list .mat-mdc-paginator-icon, app-stakeholders-employees .mat-mdc-paginator-icon, app-documents .mat-mdc-paginator-icon, app-import-email-modal .mat-mdc-paginator-icon, app-interests-matrix .mat-mdc-paginator-icon, app-recycle-bin .mat-mdc-paginator-icon, app-project-config-logs .mat-mdc-paginator-icon {
  padding-bottom: 5px !important;
}

.mdc-data-table__cell {
  overflow: visible !important;
}

app-dashboard-pie-chart .mat-button-toggle-label-content {
  line-height: 38px !important;
}

.custome-padding .mat-mdc-form-field-infix {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}
.custome-padding .mat-form-field-label {
  top: 20px !important;
}

.custome-padding-select .mat-form-field-flex {
  padding-bottom: 2px !important;
}

.mdc-text-field--filled {
  height: auto !important;
}

.mat-mdc-form-field-infix {
  padding: 0.4375em 0 !important;
  min-height: 26px !important;
}
.mat-mdc-form-field-infix input {
  top: 7px !important;
  position: inherit !important;
}

.mat-mdc-form-field-infix .mat-date-range-input-wrapper input {
  top: -1px !important;
  position: absolute !important;
}

.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
  font-size: 13px !important;
}

.mdc-line-ripple::before {
  border-bottom-color: #d6d6d6 !important;
}

.mat-mdc-form-field .mat-form-field-should-float {
  padding: 10px !important;
  box-sizing: border-box !important;
}

.mdc-floating-label {
  font-size: 13px !important;
  font-family: work_sans_regular !important;
  letter-spacing: normal !important;
  padding: 0 !important;
  top: 23px !important;
}

.mdc-floating-label.mdc-floating-label--float-above {
  top: 18px !important;
}

.mdc-radio {
  margin-right: 10px !important;
}

.mat-mdc-radio-button .mdc-radio--disabled + label {
  color: rgba(0, 0, 0, 0.38) !important;
}

/* Style for mat-label */
.mat-mdc-form-field-label {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
  font-family: Roboto, Helvetica Neue, sans-serif !important;
}

.mat-mdc-form-field:not(.mat-form-field-disabled) .mat-mdc-floating-label.mdc-floating-label {
  cursor: inherit;
  font-size: 13px !important;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
  background-color: #2800d2;
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track::after {
  background-color: rgba(30, 159, 242, 0.5) !important;
}

.mat-mdc-slide-toggle:not(.mat-mdc-slide-toggle-checked):not(.mat-disabled) .mdc-switch__shadow {
  background-color: #ffffff;
}

.mdc-switch__icons {
  color: #2800d2 !important;
}

.mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: #2800d2 !important;
}

.mdc-switch.mdc-switch--selected .mdc-switch__icon {
  width: 0px !important;
  height: 0px !important;
}

.mdc-label {
  user-select: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0px !important;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.47;
}

.mat-mdc-dialog-container .mdc-dialog__content {
  color: var(--mdc-dialog-supporting-text-color, rgba(0, 0, 0, 0)) !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #000 !important;
}

.mat-mdc-unelevated-button > .mat-icon {
  font-size: 24px !important;
  margin-left: 0px !important;
  margin-right: 5px !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::after {
  border-bottom-color: #2800d2 !important;
}

.country-selector {
  opacity: 1 !important;
  bottom: 8px !important;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #ccc;
  background-color: #f5f5f5;
  border-radius: 4px 0 0 4px;
  height: 100%;
  position: absolute !important;
  top: 30px !important;
  left: -18px !important;
  cursor: pointer;
}

.mat-mdc-tooltip {
  --mdc-plain-tooltip-supporting-text-size: 10px !important;
  --mdc-plain-tooltip-supporting-text-weight: 400 !important;
  --mdc-tooltip-word-break: normal !important;
  background-color: #ccc !important;
  color: #fff !important;
  font-family: work_sans_regular !important;
}

.mat-mdc-tooltip-text {
  font-size: var(--mdc-plain-tooltip-supporting-text-size);
  font-weight: var(--mdc-plain-tooltip-supporting-text-weight);
  word-break: var(--mdc-tooltip-word-break);
  line-height: 1.4 !important;
}

.mdc-tooltip__surface {
  background: rgba(0, 0, 0, 0.9) !important;
  border-radius: 4px !important;
  color: white !important;
  padding: 8px 8px !important;
  opacity: 0.7 !important;
  white-space: nowrap !important;
}

.mat-mdc-select-arrow {
  display: none !important;
}

label {
  font-size: 13px !important;
  line-height: 19px !important;
  font-family: work_sans_regular !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.mdc-line-ripple::before, .mdc-line-ripple::after {
  border-bottom-style: solid !important;
}

.highcharts-menu-item {
  font-size: 11px !important;
  font-family: work_sans_regular !important;
  padding: 0.5em 1em !important;
  color: #303030 !important;
}

.highcharts-menu-item:hover {
  background-color: rgb(51, 92, 173) !important;
  color: rgb(255, 255, 255) !important;
}

.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple {
  opacity: 0.9 !important;
  background-color: #fff !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
  background-color: transparent !important;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid #ccc !important;
}

.mat-mdc-cell {
  line-height: initial !important;
}

.mat-mdc-tooltip .mdc-tooltip__surface {
  max-width: unset !important;
}

.mdc-dialog__surface {
  overflow-y: unset !important;
}

/* Custom Scrollbar */
::-webkit-scrollbar {
  width: 3px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 3px !important;
}

::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

.mat-button-toggle-group-appearance-standard .mat-pseudo-checkbox {
  display: none !important;
}

.tox-silver-sink {
  z-index: 2000 !important;
}

editor .tox .tox-toolbar, editor .tox .tox-toolbar__overflow, editor .tox .tox-toolbar__primary {
  border-top: 0 !important;
}
editor .tox .tox-edit-area {
  border-top: 0 !important;
}

.mat-mdc-checkbox-layout {
  font-weight: normal;
  margin-bottom: 0px;
}

.generic-multiselect-checkbox--single div.mat-mdc-checkbox-inner-container {
  display: none;
}
.generic-multiselect-checkbox--single mat-mdc-checkbox.mat-mdc-checkbox-checked span.mat-mdc-checkbox-label {
  font-weight: bold;
}

app-generic-multiselect-checkbox .avatar-content {
  font: bold 10px/20px Helvetica, Arial, sans-serif !important;
  margin-left: -5px;
}

.subprojects .mat-chip {
  background-color: white !important;
  border: gray 1px solid !important;
}

international-phone-number .country-name {
  position: absolute;
  right: 17px;
}
international-phone-number .dial-code {
  margin-left: 11px;
}
international-phone-number div span div button.dropbtn {
  margin: 0px;
}
international-phone-number div span div div.dropdown-content {
  min-width: 0px !important;
}
international-phone-number div span div div.dropdown-content div.scrollable-menu {
  width: 52px;
}

international-phone-number.ng-invalid div input {
  border-color: #f44336;
}

/*notifications*/
.d-notifications-time {
  float: right;
  font-size: 11px;
  color: grey;
  margin-right: 5px;
}

.d-notifications > ul {
  margin-top: 30px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-left: -35px;
}

ul.msg_list {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px !important;
  max-height: calc(100vh - 50px);
  overflow: auto;
}

ul.msg_list li {
  padding: 5px;
  background-color: #FFFFFF;
}

ul.msg_list li a .message {
  display: inline-block !important;
}

ul.msg_list li.d-notifications-header {
  background: white;
  padding: 5px;
}

ul.msg_list a {
  color: #2800D2;
}

ul.msg_list i {
  color: #515356;
}

/*ul li fix by gentelella*/
ul.msg_list li,
ul.msg_list li:last-child,
ul.msg_list span,
ul.msg_list span + label {
  padding-left: 5px;
}

ul.msg_list span + label {
  top: 4px;
}

.inline-block {
  display: inline-block;
}

.dropzone-small .dropzone-container {
  width: 100% !important;
}

.dropzone-small .dz-message {
  margin: 0px !important;
  min-height: 25px !important;
  border-radius: 5px !important;
}

.dropzone-small .dropzone.dz-wrapper .dz-message .dz-text {
  padding: 8px 5px;
  text-align: left;
}

.dropzone-small .dz-message .mat-icon {
  position: absolute;
  padding-left: 5px;
  right: 10px;
}

.map-container .md-drppicker {
  top: 53px !important;
  font-family: "work_sans_regular" !important;
}

.map-container .md-drppicker .btn {
  background: #2800D2 !important;
}

.md-drppicker td.active {
  background: #2800D2 !important;
}
.md-drppicker td.in-range {
  background: #2800D2 !important;
}

.map-container .md-drppicker .btn-default {
  background: transparent !important;
  box-shadow: none;
}

.map-container .md-drppicker .calendar.right {
  display: none;
}

.map-container .popover {
  width: 200px;
}

.map-container .popover-content {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  text-align: center;
}

.custom-expand .mat-expansion-indicator::after {
  padding: 5px !important;
}

.label-color-DD758F {
  color: #000000;
}

.label-color-4A9ED6 {
  color: #000000;
}

.label-color-FDB6C8 {
  color: #000000;
}

.label-color-FF9629 {
  color: #000000;
}

.label-color-F1C40F {
  color: #000000;
}

.label-color-4CBDB3 {
  color: #000000;
}

.label-color-753EA2 {
  color: #FFFFFF !important;
}

.label-color-003367 {
  color: #FFFFFF !important;
}

.label-color-1A5C8F {
  color: #FFFFFF !important;
}

.label-color-99382D {
  color: #FFFFFF !important;
}

.label-color-136175 {
  color: #FFFFFF !important;
}

.label-color-525252 {
  color: #FFFFFF !important;
}

MapComponent .ol-touch {
  height: calc(100vh - 58px) !important;
}

MapComponent .ol-viewport {
  height: calc(100vh - 58px) !important;
}

.ol-zoom {
  bottom: 0.5em;
  right: 0.4em;
  left: auto;
  top: auto;
  transition: right 0.5s;
}

.ol-zoom.open {
  right: 23.9em;
  transition: right 0.5s;
}

.ol-custom-control_zoom {
  bottom: 0.5em;
  right: 0.4em;
}

.ol-custom-control_zoom.open {
  right: 23.9em;
}

.ol-control {
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px;
  padding: 0;
}
.ol-control button {
  background-color: #f7f7f7;
  color: gray;
  height: 29px;
  width: 29px;
  font-size: 23px;
}

.ol-control button:hover {
  background-color: lightblue;
  color: gray;
}

.ol-full-screen img {
  width: 20px;
}

@media screen and (max-width: 479px) {
  .ol-viewport {
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 760px) {
  .ol-custom-control_zoom {
    bottom: 21px;
    right: 6px;
  }
  .ol-touch {
    margin-top: 15px !important;
  }
  .ol-zoom {
    bottom: 21px;
    right: 6px;
  }
}
app-subproject-selector .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  background-color: #ffffff;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding-left: 10px;
}
app-subproject-selector .mat-mdc-form-field-wrapper .mat-form-field-underline {
  display: none;
}

.sub-project-avatar .avatar-content {
  border-width: 3px !important;
}

/* override datatables bootstrap icon */
table.dataTable thead .sorting:after {
  opacity: 0.2 !important;
  content: "▼▲" !important;
  margin-bottom: 3px;
}

table.dataTable thead th.sorting:empty:after {
  content: none !important;
}

table.dataTable thead .sorting_asc:after {
  content: "▲";
  margin-bottom: 3px;
}

table.dataTable thead .sorting_desc:after {
  content: "▼";
  margin-bottom: 3px;
}

table.dataTable thead > tr > th.sorting_asc, table.dataTable thead > tr > th.sorting_desc, table.dataTable thead > tr > th.sorting, table.dataTable thead > tr > td.sorting_asc, table.dataTable thead > tr > td.sorting_desc, table.dataTable thead > tr > td.sorting {
  padding-right: 45px !important;
}

/* Only show delete button on hover, or on mobile */
/* Set height of tr to same when icon is shown */
tr.delete-on-hover-tr {
  height: 58px;
}

/* Set width of table cell to same when icon is shown */
.delete-on-hover-th {
  min-width: 70px;
}

.delete-on-hover {
  visibility: hidden;
}

/* No hover effect on mobile, so just show delete button on none active list item */
@media only screen and (max-width: 991px) {
  .delete-on-hover-tr .delete-on-hover {
    visibility: visible;
  }
}
/* Show icon on hovered list items */
.delete-on-hover-tr:hover .delete-on-hover {
  visibility: visible;
}

app-dashboard-my-issues tr.mat-mdc-row:last-child td.mat-mdc-cell {
  border-bottom: 0px;
}

app-analysis td.mat-mdc-cell {
  border-right-width: 1px !important;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.12);
}
app-analysis th.mat-mdc-header-cell {
  border-right-width: 1px !important;
  border-right-style: solid;
  border-right-color: rgba(0, 0, 0, 0.12);
}
app-analysis td.mat-mdc-cell.action, app-analysis th.mat-mdc-header-cell.action {
  border-left-width: 1px !important;
  border-left-style: solid;
  border-left-color: rgba(0, 0, 0, 0.12);
}

app-dashboard-my-actions tr:last-child td {
  border-bottom: 0px;
}

.top-btns-overview {
  margin-top: -59px;
  font-family: work_sans_600;
}

.overview-tabs {
  max-width: 420px;
  z-index: 1;
  margin-bottom: -41px;
}
.overview-tabs .mat-mdc-tab-link {
  font-family: work_sans_regular;
  padding: 0px;
  font-size: 13px;
  min-width: 115px;
  letter-spacing: normal;
}

.list-view-paginator {
  margin-top: -12px;
}
.list-view-paginator .mat-mdc-paginator-range-label {
  margin: 0px;
}

.search-overview {
  margin-left: 40%;
  width: 24%;
  display: flex;
  height: 40px;
  float: left;
  padding: 0;
}
.search-overview .search-holder {
  width: 100%;
  border: solid 1px #E0E1E3;
  border-radius: 15px;
  margin-top: -2px;
}
.search-overview .search-holder mat-icon {
  color: gray;
  cursor: pointer;
}
.search-overview .search-holder .uil-icon {
  font-size: 19px;
}

.filter-btn-mobile {
  display: none !important;
  height: 35px;
  padding: 0;
}

.search-create-item-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: #fff;
  border: 1px solid #E0E1E3;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  text-align: center;
  font-size: 14px;
  z-index: 999;
  width: 24%;
  margin-left: 40%;
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .top-btns-overview {
    margin-top: -100px;
    font-family: work_sans_600;
  }
  .overview-tabs {
    width: auto;
    margin-bottom: 0px;
  }
  .search-overview {
    width: 80%;
    float: none !important;
    margin-left: 0;
    margin-top: 5px;
  }
  .filter-btn-mobile {
    display: block !important;
  }
}
.app-filter-sort.seting-left .filter-dropdown {
  left: -40px;
}

.app-filter-sort.logssetting .filter-dropdown {
  left: -20px;
}

.app-filter-sort.Klanteis .filter-dropdown {
  left: -45px;
}

.app-filter-sort.typeset .filter-dropdown {
  left: -200px;
}

.app-filter-sort.right .filter-dropdown {
  left: -80px;
}

.app-filter-sort.analysis .filter-dropdown {
  left: -230px;
  top: 55px;
}

.app-filter-sort.permit .filter-dropdown {
  left: -52px;
  top: 55px;
}

.th.active {
  color: #F7F050;
}

mat-icon.relation-count {
  font-size: 16px;
  height: 25px;
  width: 25px;
  color: gray;
  border: solid 2px;
  padding-top: 2px;
  padding-left: 3px;
  border-radius: 50%;
}
mat-icon.relation-count .mat-badge-content {
  background-color: #FF7669;
}

.text-center {
  text-align: center !important;
}

.th-border {
  width: 100%;
  height: 1px;
  display: block;
  top: 60px;
  background: lightgray;
  position: sticky;
}

.mat-mdc-table {
  overflow: hidden;
  border-radius: 4px;
}

.th-border {
  height: 0px !important;
}

.pagination-top-border {
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #e0e0e0;
  position: sticky !important;
  left: 0;
}

.overview-panel tbody > tr:last-child > td {
  border-bottom-width: 0px !important;
  border-bottom-style: solid;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}

app-all-search-select, .app-all-search-select {
  position: relative;
  display: inline-block;
}
app-all-search-select .fast-right-spinner, .app-all-search-select .fast-right-spinner {
  -webkit-animation: glyphicon-spin-r 1s infinite linear;
  animation: glyphicon-spin-r 1s infinite linear;
  color: rgb(30, 159, 242);
  padding-top: 1px !important;
}
@-webkit-keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes glyphicon-spin-r {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
app-all-search-select input, .app-all-search-select input {
  border: 1px solid #f2f2f2;
  border-radius: 5px;
}
app-all-search-select span, .app-all-search-select span {
  padding-top: 0px !important;
}
app-all-search-select .gd-not-found, .app-all-search-select .gd-not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
}
app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
  display: none;
  max-height: 90vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}
app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
  display: block;
  border-bottom: 1px solid #e6e6e6;
}
app-all-search-select .gd-search-content .gd-search-panel, .app-all-search-select .gd-search-content .gd-search-panel {
  border: 1px solid #e6e6e6;
  background-color: #ffffff;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-header, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header {
  border-top: 1px solid #e6e6e6;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-header div, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header div {
  padding: 0 5px 0 5px;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a {
  color: #1e2223;
  display: inline-block !important;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a a:hover, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a a:hover {
  color: rgb(30, 159, 242);
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a span, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header a span {
  padding-top: 3px;
  display: inline-block !important;
  vertical-align: middle !important;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section div, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section div {
  padding: 0 10px 0 5px;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section i,
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section p, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section i,
.app-all-search-select .gd-search-content .gd-search-panel .gd-search-section p {
  color: #1e2223;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover {
  background-color: #f2f2f2;
}
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover i,
app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover p, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover i,
.app-all-search-select .gd-search-content .gd-search-panel .gd-search-section:hover p {
  color: rgb(30, 159, 242);
}
app-all-search-select .gd-search-content .gd-search-panel .gd-title, .app-all-search-select .gd-search-content .gd-search-panel .gd-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  font-weight: bold;
}
app-all-search-select i, .app-all-search-select i {
  background: #e5e5e5;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  width: 40px;
  padding-left: 8px;
  padding-top: 8px;
}
app-all-search-select .gd-flex, .app-all-search-select .gd-flex {
  min-height: 2vh;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
app-all-search-select .panel, .app-all-search-select .panel {
  margin: 0 !important;
  padding: 0 !important;
}
app-all-search-select .panel i:active, .app-all-search-select .panel i:active {
  transform: rotate(180deg);
}
app-all-search-select .panel ul, .app-all-search-select .panel ul {
  list-style-type: none;
}
app-all-search-select .panel ul li:hover, .app-all-search-select .panel ul li:hover {
  background-color: #e5e5e5;
}
app-all-search-select .gd-title-container, .app-all-search-select .gd-title-container {
  color: #d9d9d9;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
app-all-search-select p, .app-all-search-select p {
  font-size: 1em;
  padding: 0 !important;
  margin: 0 !important;
  color: #353d3e;
}
app-all-search-select .mat-mdc-form-field input.mat-mdc-input-element, .app-all-search-select .mat-mdc-form-field input.mat-mdc-input-element {
  padding-bottom: 3px;
}

@media (min-width: 200px) and (max-width: 767px) {
  app-all-search-select, .app-all-search-select {
    position: relative;
    display: inline-block;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: none;
    max-height: 90vh !important;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: block;
  }
  app-all-search-select .gd-search-content .gd-search-panel, .app-all-search-select .gd-search-content .gd-search-panel {
    width: 60vw !important;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header {
    width: 60vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section {
    width: 60vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  app-all-search-select, .app-all-search-select {
    position: relative;
    display: inline-block;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: none;
    max-height: 90vh !important;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: block;
  }
  app-all-search-select .gd-search-content .gd-search-panel, .app-all-search-select .gd-search-content .gd-search-panel {
    width: 35vw !important;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header {
    width: 35vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section {
    width: 35vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  app-all-search-select, .app-all-search-select {
    position: relative;
    display: inline-block;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: none;
    max-height: 90vh !important;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: block;
  }
  app-all-search-select .gd-search-content .gd-search-panel, .app-all-search-select .gd-search-content .gd-search-panel {
    width: 40vw !important;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header {
    width: 40vw !important;
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section {
    width: 40vw !important;
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  app-all-search-select, .app-all-search-select {
    position: relative;
    display: inline-block;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: none;
  }
  app-all-search-select .gd-search-content, .app-all-search-select .gd-search-content {
    display: block;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header {
    min-height: 5vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-header .gd-header-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section {
    min-height: 12vh;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content, .app-all-search-select .gd-search-content .gd-search-panel .gd-search-section .gd-section-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}
.sidenav-container .mat-drawer-inner-container {
  background-color: #f2f3f8;
}
.sidenav-container .nav.side-menu > li > a {
  margin-bottom: 0px !important;
  padding: 10px 15px !important;
  color: #434344;
}
.sidenav-container .nav.side-menu > li > a:hover {
  color: #2800D2 !important;
}
.sidenav-container .nav.side-menu > li > a.active {
  color: #2800D2;
  background: rgba(0, 0, 0, 0.0352941176) !important;
}

.mdc-list {
  padding-top: 0px !important;
}

.mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

.mat-drawer-inner-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
  min-width: 200px;
}
.mat-drawer-inner-container .site_title {
  background-color: #518ba7;
  line-height: 55px !important;
  height: 59px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 200px;
  color: #FFFFFF !important;
}
.mat-drawer-inner-container .side_menu {
  padding-top: 67px;
}

.customized-dialog-container .mat-mdc-dialog-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  --mdc-dialog-container-shape: 20px;
}
.customized-dialog-container .mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
  border: 1px solid #DDDDDD;
}

.mat-mdc-dialog-container {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  --mdc-dialog-container-shape: 20px !important;
}
.mat-mdc-dialog-container .mdc-dialog__surface {
  padding: 24px;
  border: 1px solid #DDDDDD;
}

.mdc-dialog__actions {
  margin-bottom: -24px !important;
}

.mat-mdc-dialog-container .mdc-dialog__title {
  padding: 0 0px 9px;
}

.widget-panel {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #DDDDDD;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.unicon-icon {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 7px;
  vertical-align: middle;
  padding-right: 5px;
}

.main_menu_side .unicon-icon {
  font-size: 25px !important;
  text-align: center;
  margin-bottom: 0px;
  vertical-align: middle;
  padding-right: 5px;
}

.contactmoment-notes-container .tox-tinymce {
  border-radius: 25px !important;
  border: 1px solid #D6D6D6 !important;
  padding: 10px;
  width: 100% !important;
}

.highcharts-axis-labels text {
  font-family: "work_sans_regular" !important;
}

.highcharts-axis-title {
  font-family: "work_sans_regular" !important;
  font-size: 12px !important;
}

.highcharts-plot-line-label {
  font-size: 12px !important;
}

.highcharts-label text {
  font-family: "work_sans_regular" !important;
  font-size: 12px !important;
}

.highcharts-root text {
  font-family: "work_sans_regular" !important;
  font-size: 12px !important;
}

.highcharts-legend-item text {
  font-family: "work_sans_semibold" !important;
  font-size: 12px !important;
}

.mat-datepicker-content {
  border-radius: 25px !important;
}

.mat-calendar-header .mat-calendar-controls .mat-button:hover {
  border-radius: 30px !important;
}

.mat-mdc-autocomplete-panel, .mat-mdc-select-panel {
  border-radius: 20px !important;
  min-width: 200px !important;
  padding: 0 !important;
}

.mat-pseudo-checkbox {
  opacity: 0;
}

.mat-mdc-option .mdc-list-item__primary-text {
  font-size: 14px !important;
  letter-spacing: normal !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #000 !important;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  border-radius: 20px;
  color: #2800D2;
}

.mat-mdc-option {
  font-family: "work_sans_regular" !important;
}

.mdc-label {
  font-weight: 400;
  font-family: "work_sans_regular";
  font-size: 15px;
  padding-top: 5px;
}

.mat-mdc-menu-panel {
  border-radius: 25px !important;
}

button, input, select, textarea {
  font-family: "work_sans_regular" !important;
}

input.mat-mdc-chip-input {
  margin-left: 0px !important;
}

.mdc-evolution-chip__text-label {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  font-family: "work_sans_regular" !important;
}

.mdc-form-field > label {
  padding-left: 0px !important;
  padding-bottom: 5px !important;
}

.mat-mdc-option {
  padding-left: 12px !important;
}

.mdc-list-item--with-leading-checkbox .mdc-list-item__start {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.mdc-list-item__primary-text {
  font-size: 13px !important;
  font-family: "work_sans_regular" !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.mat-step-label {
  font-size: 14px !important;
  font-family: "work_sans_regular";
}

.mat-mdc-menu-content .mat-mdc-option .mdc-list-item__primary-text {
  display: flex;
  margin-top: 5px !important;
}

@media only screen and (max-width: 991px) {
  .unicon-icon {
    padding-right: 0px;
  }
}
.gd-resident-content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.gd-resident-content mat-button,
.gd-resident-content .gd-button-migrate {
  height: 10vh;
  width: 100%;
  left: 0;
  right: 0;
  color: rgb(255, 255, 255);
  background-color: rgb(26, 115, 232);
  letter-spacing: 2px;
  text-transform: uppercase;
}

.gd-modal {
  width: 40vw !important;
  font-family: "work_sans_regular" !important;
}
.gd-modal h1 {
  font-family: "work_sans_light" !important;
  font-weight: bold;
}
.gd-modal .primary-color {
  color: #3F51B5 !important;
}
.gd-modal .background-primary-color {
  color: #3F51B5 !important;
}
.gd-modal .mat-dialog-content {
  min-height: 323px !important;
}
.gd-modal button {
  background-color: #1c7cd5;
  color: #ffffff !important;
  padding: 5px;
  border-radius: 5px;
}

.gd-notification {
  min-height: 50px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.gd-migrate-form {
  min-height: 20px;
  width: auto;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 5px;
  border: 1px solid #4d575a;
  border-radius: 3px;
}

.resident-phone button {
  margin-bottom: 0px !important;
}

.pac-container {
  z-index: 100000;
}

.gd-verantwoordelijken ngx-select .ngx-select__selected-plural {
  width: 100% !important;
  background-color: #f2f2f2;
  height: 40px;
}
.gd-verantwoordelijken ngx-select .ngx-select__selected-plural span {
  width: 100% !important;
  text-align: left;
  height: 40px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.gd-verantwoordelijken ngx-select .ngx-select__selected-plural .ngx-select__clear {
  text-align: right;
  padding: 10px;
}
.gd-verantwoordelijken ngx-select .ngx-select__selected-plural .ngx-select__clear i {
  margin-bottom: 7px;
}

.stakeholder-interest-table thead {
  display: none;
}

.Samenwerken {
  background-color: #FF7669;
  color: #FFFFFF;
}

.Uncategorised {
  color: #000000 !important;
}

.Informeren {
  background-color: #F7F050;
  color: #000000;
}

.Informeren-strategie {
  background-color: #E0E1E3;
  color: #000000;
}

.Monitoren {
  background-color: #E0E1E3;
  color: #000000;
}

.Tevreden.houden {
  background-color: #FF9F95;
  color: #000000;
}

.Meebeslissen {
  background-color: #FF7669;
  color: #000000;
}

.Coproduceren {
  background-color: #FF9f95;
  color: #000000;
}

.Adviseren {
  background-color: #F7B050;
  color: #000000;
}

.Raadplegen {
  background-color: #F7F050;
  color: #000000;
}

.Reactief {
  background-color: #FFFFFF;
  color: #000000;
  border: 1px solid #E0E1E3;
}

app-interests-powerfield-matrix form, app-powerfield-matrix form {
  margin-top: 40px;
}

app-logs .mat-expansion-panel {
  min-width: 100% !important;
  border-radius: 0px !important;
}
app-logs .mat-expansion-panel-header-description, app-logs .mat-expansion-panel-header-title {
  display: block;
}
app-logs .mat-expansion-panel-header {
  font-family: inherit !important;
  font-size: inherit !important;
}
app-logs .popper-dialog {
  min-width: 300px;
}

app-logs-overview .dropzone {
  text-align: center;
  padding-top: 10px;
  color: #999;
}
app-logs-overview .dropzone.dz-drag-hover {
  background: aliceblue;
  border: dashed 1px;
}
app-logs-overview .dropzone i {
  font-size: 24px;
  color: #28ABE3;
}
app-logs-overview .dz-wrapper {
  width: 100% !important;
}
app-logs-overview .dz-message {
  min-height: 37px !important;
  max-height: 37px !important;
  border-radius: 3px;
  margin: 0px !important;
  border: 1px dashed #aaa !important;
}
app-logs-overview .button-container {
  margin-top: 0px !important;
}
app-logs-overview .mat-mdc-form-field {
  font-family: inherit !important;
}
app-logs-overview .mat-mdc-form-field-underline {
  display: none !important;
}
app-logs-overview .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-flex {
  background-color: #fff !important;
  border-radius: 15px !important;
  padding-top: 3px !important;
}
app-logs-overview .mat-mdc-form-field-appearance-fill .mat-mdc-form-field-infix {
  padding-bottom: 3px !important;
}
app-logs-overview app-generic-multiselect-checkbox .mat-mdc-form-field-infix {
  border-radius: 3px;
  border: solid #ffffff;
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
}

.log-edit .mat-mdc-tab-label {
  min-width: 30px !important;
  padding: 0px !important;
  min-height: 68px !important;
}
.log-edit .mat-mdc-tab-label-content {
  display: block !important;
}
.log-edit .ql-editor {
  min-height: calc(100vh - 235px) !important;
  max-height: calc(100vh - 235px) !important;
  overflow-y: scroll;
}

app-complaints-detail .log-relations-div {
  width: 100% !important;
  display: block !important;
}
app-complaints-detail .log-edit-buttons-div {
  width: 100% !important;
}

app-employee-edit .log-relations-div {
  width: 100% !important;
  display: block !important;
}
app-employee-edit .log-edit-buttons-div {
  width: 100% !important;
}

app-email-log-dropzone .dz-text {
  position: absolute !important;
  top: 50% !important;
}

app-complaints-list popper-content div {
  min-width: 40vw;
}
app-complaints-list popper-content div div.ngxp__inner.ng-star-inserted mat-card {
  border: 1px solid #E0E0E0;
}
app-complaints-list popper-content div div.ngxp__inner.ng-star-inserted mat-card div mat-icon {
  padding-left: 5px !important;
  padding-right: 30px !important;
  vertical-align: middle;
  font-size: 20px;
}
app-complaints-list popper-content div div.ngxp__inner.ng-star-inserted mat-card div .gd-span {
  padding-left: 5px;
  padding-right: 5px;
}

app-complaints-detail .sidenav-map {
  width: 100% !important;
}
app-complaints-detail .mat-slide-toggle-content {
  font-family: inherit;
  font-weight: normal;
}

app-complaint-new .mat-mdc-tab-header, app-complaint-new .mat-mdc-tab-nav-bar, app-complaints-detail .mat-mdc-tab-header, app-complaints-detail .mat-mdc-tab-nav-bar {
  border-bottom: 0px;
}

app-tab-issue-detail-general app-urgency-status {
  position: relative;
  top: 6px;
}
app-tab-issue-detail-general app-urgency-status > p {
  float: right;
  text-transform: capitalize;
}
app-tab-issue-detail-general .interests .mat-list-item-content {
  height: 50px !important;
  padding-left: 0 !important;
}
app-tab-issue-detail-general .interests mat-list-option {
  height: 50px !important;
}

app-interests-matrix .popper-dialog, app-tab-issue-detail-general .popper-dialog, app-tab-stakeholder-detail-houding .popper-dialog {
  background-color: #EFF0F6;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  z-index: 1500;
  border-radius: 25px;
}
app-interests-matrix .issue-interest div, app-tab-issue-detail-general .issue-interest div, app-tab-stakeholder-detail-houding .issue-interest div {
  display: inline-block;
  width: 30px;
  height: 30px;
  margin: 3px;
  cursor: pointer;
  border: 1px solid #dadada;
  line-height: 30px;
  position: relative;
}
app-interests-matrix .issue-interest .color-x, app-tab-issue-detail-general .issue-interest .color-x, app-tab-stakeholder-detail-houding .issue-interest .color-x {
  background-color: #EFF0F6;
}
app-interests-matrix .issue-interest .color-0, app-tab-issue-detail-general .issue-interest .color-0, app-tab-stakeholder-detail-houding .issue-interest .color-0 {
  background-color: #EFF0F6;
}
app-interests-matrix .issue-interest .color-1, app-tab-issue-detail-general .issue-interest .color-1, app-tab-stakeholder-detail-houding .issue-interest .color-1 {
  background-color: #FFFFFF;
}
app-interests-matrix .issue-interest .color-2, app-tab-issue-detail-general .issue-interest .color-2, app-tab-stakeholder-detail-houding .issue-interest .color-2 {
  background-color: #F7F050;
}
app-interests-matrix .issue-interest .color-3, app-tab-issue-detail-general .issue-interest .color-3, app-tab-stakeholder-detail-houding .issue-interest .color-3 {
  background-color: #FF9F95;
}
app-interests-matrix .issue-interest .color-4, app-tab-issue-detail-general .issue-interest .color-4, app-tab-stakeholder-detail-houding .issue-interest .color-4 {
  background-color: #FF7669;
}
app-interests-matrix .popper .issue-interest, app-tab-issue-detail-general .popper .issue-interest, app-tab-stakeholder-detail-houding .popper .issue-interest {
  padding-left: 8px;
  cursor: pointer !important;
}
app-interests-matrix .popper .issue-interest:hover, app-tab-issue-detail-general .popper .issue-interest:hover, app-tab-stakeholder-detail-houding .popper .issue-interest:hover {
  background-color: #C8C9CF;
}
app-interests-matrix .matrix-popper-dialog, app-tab-issue-detail-general .matrix-popper-dialog, app-tab-stakeholder-detail-houding .matrix-popper-dialog {
  background-color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 8px 3px rgba(0, 0, 0, 0.3);
  z-index: 1500;
  border-radius: 25px;
  position: absolute;
  border: none;
  padding: 0px !important;
}
app-interests-matrix .ngxp__container > .ngxp__arrow, app-tab-issue-detail-general .ngxp__container > .ngxp__arrow, app-tab-stakeholder-detail-houding .ngxp__container > .ngxp__arrow {
  opacity: 0;
}
app-interests-matrix .issue-interest-new div, app-tab-issue-detail-general .issue-interest-new div, app-tab-stakeholder-detail-houding .issue-interest-new div {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  padding-right: 10px;
  line-height: 30px;
  position: relative;
}
app-interests-matrix .issue-interest-new .color-x, app-tab-issue-detail-general .issue-interest-new .color-x, app-tab-stakeholder-detail-houding .issue-interest-new .color-x {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorInterest-x, app-tab-issue-detail-general .issue-interest-new .colorInterest-x, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-x {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorInterest-null, app-tab-issue-detail-general .issue-interest-new .colorInterest-null, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-null {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorInterest-0, app-tab-issue-detail-general .issue-interest-new .colorInterest-0, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-0 {
  background-color: #e0e0e0;
}
app-interests-matrix .issue-interest-new .colorInterest-1, app-tab-issue-detail-general .issue-interest-new .colorInterest-1, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-1 {
  background-color: #98dbea;
}
app-interests-matrix .issue-interest-new .colorInterest-2, app-tab-issue-detail-general .issue-interest-new .colorInterest-2, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-2 {
  background-color: #58b5db;
}
app-interests-matrix .issue-interest-new .colorInterest-3, app-tab-issue-detail-general .issue-interest-new .colorInterest-3, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-3 {
  background-color: #178ecc;
}
app-interests-matrix .issue-interest-new .colorInterest-4, app-tab-issue-detail-general .issue-interest-new .colorInterest-4, app-tab-stakeholder-detail-houding .issue-interest-new .colorInterest-4 {
  background-color: #004f90;
}
app-interests-matrix .issue-interest-new .colorTension-x, app-tab-issue-detail-general .issue-interest-new .colorTension-x, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-x {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorTension--1, app-tab-issue-detail-general .issue-interest-new .colorTension--1, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension--1 {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorTension-null, app-tab-issue-detail-general .issue-interest-new .colorTension-null, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-null {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorTension-0, app-tab-issue-detail-general .issue-interest-new .colorTension-0, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-0 {
  background-color: #92e8A9;
}
app-interests-matrix .issue-interest-new .colorTension-1, app-tab-issue-detail-general .issue-interest-new .colorTension-1, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-1 {
  background-color: #ffdad3;
}
app-interests-matrix .issue-interest-new .colorTension-2, app-tab-issue-detail-general .issue-interest-new .colorTension-2, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-2 {
  background-color: #ffb8aa;
}
app-interests-matrix .issue-interest-new .colorTension-3, app-tab-issue-detail-general .issue-interest-new .colorTension-3, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-3 {
  background-color: #fe8f80;
}
app-interests-matrix .issue-interest-new .colorTension-4, app-tab-issue-detail-general .issue-interest-new .colorTension-4, app-tab-stakeholder-detail-houding .issue-interest-new .colorTension-4 {
  background-color: #ff4331;
}
app-interests-matrix .issue-interest-new .colorInfluence-x, app-tab-issue-detail-general .issue-interest-new .colorInfluence-x, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-x {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorInfluence-null, app-tab-issue-detail-general .issue-interest-new .colorInfluence-null, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-null {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorInfluence-0, app-tab-issue-detail-general .issue-interest-new .colorInfluence-0, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-0 {
  background-color: #e6e6fa;
}
app-interests-matrix .issue-interest-new .colorInfluence-1, app-tab-issue-detail-general .issue-interest-new .colorInfluence-1, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-1 {
  background-color: #c8a2c8;
}
app-interests-matrix .issue-interest-new .colorInfluence-2, app-tab-issue-detail-general .issue-interest-new .colorInfluence-2, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-2 {
  background-color: #9370db;
}
app-interests-matrix .issue-interest-new .colorInfluence-3, app-tab-issue-detail-general .issue-interest-new .colorInfluence-3, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-3 {
  background-color: #6a0dad;
}
app-interests-matrix .issue-interest-new .colorInfluence-4, app-tab-issue-detail-general .issue-interest-new .colorInfluence-4, app-tab-stakeholder-detail-houding .issue-interest-new .colorInfluence-4 {
  background-color: #4b0082;
}
app-interests-matrix .issue-interest-new .colorTrust-x, app-tab-issue-detail-general .issue-interest-new .colorTrust-x, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-x {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorTrust-null, app-tab-issue-detail-general .issue-interest-new .colorTrust-null, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-null {
  border: 1px solid #dadada;
  background-color: #ffffff;
}
app-interests-matrix .issue-interest-new .colorTrust-0, app-tab-issue-detail-general .issue-interest-new .colorTrust-0, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-0 {
  background-color: #feecd3;
}
app-interests-matrix .issue-interest-new .colorTrust-1, app-tab-issue-detail-general .issue-interest-new .colorTrust-1, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-1 {
  background-color: #ffdda6;
}
app-interests-matrix .issue-interest-new .colorTrust-2, app-tab-issue-detail-general .issue-interest-new .colorTrust-2, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-2 {
  background-color: #ffce7a;
}
app-interests-matrix .issue-interest-new .colorTrust-3, app-tab-issue-detail-general .issue-interest-new .colorTrust-3, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-3 {
  background-color: #ffbf4d;
}
app-interests-matrix .issue-interest-new .colorTrust-4, app-tab-issue-detail-general .issue-interest-new .colorTrust-4, app-tab-stakeholder-detail-houding .issue-interest-new .colorTrust-4 {
  background-color: #fdaf00;
}

app-tab-stakeholder-detail-houding .stakeholder-matrix-suggestion-line {
  opacity: 0.7;
}

.d-block .mat-list-item-content {
  display: block !important;
}

owl-date-time-container {
  width: 300px !important;
}

owl-date-time-calendar {
  height: auto !important;
}

owl-date-time-container .owl-dt-calendar-cell-content {
  font-size: 12px !important;
}
owl-date-time-container .owl-dt-calendar-control-content {
  font-size: 12px !important;
}
owl-date-time-container .owl-dt-control-button-content {
  text-transform: capitalize !important;
  font-size: 14px !important;
}

owl-date-time-month-view table thead tr .owl-dt-weekday {
  font-size: 12px !important;
  color: #000000;
  font-weight: bold;
}

owl-date-time-month-view table thead tbody tr td .owl-dt-calendar-cell-selected {
  background-color: #35a9f3 !important;
}

owl-date-time-container .owl-dt-container-inner .owl-dt-container-info {
  font-size: 14px;
  color: #000000;
}
owl-date-time-container .owl-dt-container-inner .owl-dt-container-buttons {
  font-size: 14px;
  color: #000000;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color: #35a9f3 !important;
}

owl-date-time-timer {
  height: auto;
}

.transparent_bg mat-dialog-container {
  background: transparent !important;
}

.custome-tiny-style .tox-tinymce {
  border-bottom: 1px solid #949494 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
  height: 80px !important;
  border-radius: 0px !important;
}
.custome-tiny-style .tox-edit-area::before {
  border-bottom: 1px solid #949494 !important;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.layers-accordion .mat-expansion-panel-header {
  padding: 0px 13px !important;
}
.layers-accordion .mat-expansion-panel-header .mat-content {
  overflow: visible !important;
}
.layers-accordion .mat-expansion-panel-body {
  padding: 0 10px 5px !important;
}
.layers-accordion mat-panel-title {
  font-size: 13px !important;
  font-family: "work_sans_regular" !important;
  font-weight: 700;
}
.layers-accordion .mat-expansion-panel-header-description {
  display: block !important;
}

.items-layers .mat-expansion-panel-header-title {
  margin-right: 10px !important;
}

.small-spinner {
  width: 50px;
}

.mat-menu-content .mat-accent .mat-slider-track-fill {
  background-color: #0189f3 !important;
}
.mat-menu-content .mat-slider-thumb-label {
  background-color: #0189f3 !important;
}
.mat-menu-content .mat-slider-thumb {
  background-color: #0189f3 !important;
}
.mat-menu-content .mat-slider-disabled .mat-slider-thumb {
  background-color: #bfbfbf !important;
}

.load-openlayers mat-spinner {
  width: 40px !important;
  height: 40px !important;
}
.load-openlayers mat-spinner svg {
  width: 40px !important;
  height: 40px !important;
}
.load-openlayers mat-spinner svg circle {
  width: 40px !important;
  height: 40px !important;
}

.custome-padding .mat-mdc-form-field-infix {
  padding-top: 6px !important;
  padding-bottom: 10px !important;
}
.custome-padding .mat-mdc-form-field-label {
  top: 20px !important;
}

.custome-padding-select .mat-mdc-form-field-flex {
  padding-bottom: 2px !important;
}

.radio-button-custome .mat-radio-label {
  display: flex !important;
  justify-content: center !important;
  margin-left: 5px !important;
}

.spinner-animation {
  animation: spin 2s linear infinite;
}

app-batch-overview-setting .mat-mdc-form-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  width: 130px;
}

.d-stats h2 {
  font-family: "work_sans_semibold";
  font-size: 18px;
  font-weight: 600;
  vertical-align: bottom;
}

.d-stats p {
  font-size: 12px;
  color: #080708;
  text-transform: capitalize;
}