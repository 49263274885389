.fc-button-primary
  background-color: transparent !important
  border-color: #D9DEE4 !important
  color: #515356 !important

.fc-button-primary:hover
  background-color: #D9DEE4 !important

.fc-button-primary.fc-button-active
  background-color: transparent !important
  border-color: #2800D2 !important
  color: #2800D2 !important

.fc-button-primary:focus
  outline: none !important
  border-color: #2800D2 !important
  -webkit-box-shadow: none !important
  box-shadow: none !important

.gd-button-create
  width: 100%
  font-size: 13px

.gd-activity
  background-color: #2800D2
  border-color: #2800D2
  color: #FFFFFF

.gd-middel
  background-color: #2C3E50
  border-color: #2C3E50
  color: #FFFFFF

.gd-close-modal
  right: 10px
  position: fixed
  z-index: 999
  cursor: pointer
  color: #C7C7C7

.gd-close-modal:hover
  color: #2800D2

.mdl-button--primary.mdl-button--primary
  background-color: transparent
  color: #2800D2

.mdl-button--primary.mdl-button--primary:hover
  color: #2800D2
  background-color: #e7e8e8

.mdl-button--raised:hover
  color: #ffffff

/* Override mat-button styling
.mat-button
  font-family: 'work_sans_regular'
  color: #2800D2

.mdc-button--outlined
  font-family: 'work_sans_regular' !important
  border-radius: 30px !important
  color: #2800D2
  border-color: #2800D2 !important
  color: #2800D2 !important

.mat-mdc-stroked-button
  font-family: 'work_sans_regular'
  border-radius: 30px !important
  color: #2800D2 !important
  border-color: #2800D2 !important

.mat-mdc-stroked-button:disabled
  cursor: not-allowed !important

.mat-mdc-unelevated-button
  font-family: 'work_sans_regular' !important

.mat-mdc-unelevated-button, .mat-mdc-unelevated-button:hover,.mat-mdc-unelevated-button:focus
  border-radius: 30px !important
  color: white !important
  background-color: #2800D2 !important

.mat-mdc-unelevated-button:visited
  color: #FFFFFF !important
  background-color: #2800D2 !important

.mat-mdc-unelevated-button:disabled
  background-color: #c4c4c4 !important
  cursor: not-allowed !important
  color: rgba(0,0,0,.26) !important

button
  cursor: pointer

a
 cursor: pointer

.mat-flat-button, .mat-flat-button:hover,.mat-flat-button:focus
  border-radius: 30px !important
  color: white !important
  background-color: #2800D2 !important
  pointer: cursor !important
  padding: 12px 16px

.dialog-button-row
  button
    margin-left: 8px !important
  a
    margin-left: 8px !important
    margin-bottom: 3px !important

.mat-mdc-unelevated-button-icon-margin
  margin-right: 5px

.mat-mdc-unelevated-button-margin-bottom
  margin-bottom: 5px !important

delete-button
  mat-icon
    cursor: pointer
    opacity: 0
    transition: opacity 0.5s


tr:hover .delete-button mat-icon, mat-row:hover .delete-button mat-icon
  opacity: 1
  transition: opacity 0.5s
  color: #2800D2

.delete-button-hover:hover
  .delete-button
    .unicon-icon
      opacity: 1
      transition: opacity 0.5s
      font-size: 24px
      color: #2800D2

.delete-button-hover:hover
  .delete-button
    mat-icon
      opacity: 1
      transition: opacity 0.5s
      color: #2800D2

.map-toggles-set-bookmark,
.map-toggles-set-bookmark-add,
.map-toggles-set,
.polygon-edit-toggle,
.map-toggles-set-klanteisen
  .mat-button-toggle-appearance-standard
    .mat-button-toggle-label-content
      padding: 5px !important

@media only screen and (max-width: 450px)
  .fc-button
    padding: 0.2rem 0.2rem !important

.mdc-button
  padding: 0 16px 0 16px !important
  cursor: pointer !important
  white-space: nowrap !important
  text-align: center !important
  line-height: 36px !important
  letter-spacing: 0px !important

.mat-mdc-outlined-button .mat-icon
  font-size: 20px !important

.mat-stroked-button
  border-radius: 30px !important
  border: 1px solid #2800d2 !important
  color: #2800d2 !important
  font-size: 14px
  font-weight: 500
  background: inherit
  text-align: center
  line-height: 36px
  cursor: pointer
  padding: 11px 15px



.mat-mdc-outlined-button[disabled]
  color: var(--mdc-outlined-button-disabled-label-text-color, rgba(0, 0, 0, 0.38)) !important
  cursor: not-allowed !important


.mat-mdc-icon-button
  --mat-mdc-button-persistent-ripple-color: transparent !important
  --mat-mdc-button-ripple-color: transparent !important
  --mat-mdc-button-persistent-ripple-color: transparent !important
  --mat-mdc-button-ripple-color: transparent !important


.custom-form-field .mat-form-field-wrapper
  display: flex
  align-items: flex-end /* Align contents to the bottom */


.custom-form-field .mat-form-field-label
  position: absolute
  bottom: 0 /* Start label from the bottom */
  left: 0
  transform: none /* Remove any transformation */
  transform-origin: left bottom /* Set the origin for scaling */
  transition: transform 0.3s ease-in-out, bottom 0.3s ease-in-out /* Animate the label */


.custom-form-field.mat-form-field-should-float .mat-form-field-label
  transform: scale(0.75) translateY(-100%) /* Move label up and scale it down */
  bottom: 100% /* Position the label at the top */


.custom-form-field .mat-form-field-label-wrapper
  width: 100%

.mat-datepicker-toggle .mat-mdc-icon-button
  --mdc-icon-button-icon-size:  15px !important
  padding: 0px !important

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled)
  background-color: #fff !important
  border: 1px solid gray

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label 
  color: #2800D2 !important