.mat-mdc-checkbox-layout
  font-weight: normal
  margin-bottom: 0px

.generic-multiselect-checkbox--single
  div.mat-mdc-checkbox-inner-container
    display: none
  mat-mdc-checkbox.mat-mdc-checkbox-checked
    span.mat-mdc-checkbox-label
      font-weight: bold
app-generic-multiselect-checkbox
  .avatar-content
    font: bold 10px / 20px Helvetica, Arial, sans-serif !important
    margin-left: -5px
.subprojects
  .mat-chip
    background-color: white !important
    border: gray 1px solid !important
