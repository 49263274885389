MapComponent
  .ol-touch
    height: calc(100vh - 58px) !important

MapComponent
  .ol-viewport
    height: calc(100vh - 58px) !important

.ol-zoom
  bottom: .5em
  right: .4em
  left: auto
  top: auto
  transition: right .5s

.ol-zoom.open
  right: 23.9em
  transition: right .5s

.ol-custom-control_zoom
  bottom: .5em
  right: .4em

.ol-custom-control_zoom.open
  right: 23.9em

.ol-control
  -webkit-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px
  -moz-box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px
  box-shadow: rgba(0, 0, 0, 0.3) 0 1px 3px
  padding: 0

  button
    background-color: #f7f7f7
    color: gray
    height: 29px
    width: 29px
    font-size: 23px

.ol-control button:hover
  background-color: lightblue
  color: gray

.ol-full-screen
  img
    width: 20px

@media screen and (max-width: 479px)
  .ol-viewport
    margin-top: 0px !important

@media screen and (max-width: 760px)
  .ol-custom-control_zoom
    bottom: 21px
    right: 6px

  .ol-touch
    margin-top: 15px !important

  .ol-zoom
    bottom: 21px
    right: 6px

