.flex
  display: flex

.inline-flex
  display: inline-flex

.gd-auto-horizontal
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between

.gd-auto-vertical
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between

.gd-klantwensen-modal-header
  min-height: 80px
  display: flex
  flex-direction: column
  justify-content: center
  justify-content: space-between
  background-color: gray

.gd-klantwensen
  min-height: 500px

  .mat-dialog-content
    min-height: 300px

    .mat-mdc-input-element
      max-width: 100vw

/** width */
.gd-horizontal
  width: 100%
  display: flex
  flex-direction: row
  justify-content: space-between

/** height */
.gd-vertical
  width: 100%
  display: flex
  flex-direction: column
  justify-content: space-between
